import { observer } from 'mobx-react-lite'
import { TranslatableBox, TranslatableBoxProps } from '../../../localization/component/atom/TranslatableBox'
import { NotOverridableComponentProps } from '../../../../model/Component'

export type AppOptionProps = NotOverridableComponentProps<TranslatableBoxProps>

export const AppOption = observer((props: AppOptionProps) => {
  return (
    <TranslatableBox
      component={'option'}
      {...props}
    />
  )
})
