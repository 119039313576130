import { AppProgressLine } from '../../../../../component/common/analog/molecule/loader/AppProgressLine'
import { AppContainer } from '../../../../../component/common/analog/molecule/AppContainer'
import { Logo, LogoType } from '../../../../../component/common/original/organism/Logo'
import { HeaderProps } from '../../../model/HeaderProps'
import { ChildrenProps } from '../../../../../model/React'
import { useMainStore } from '../../../../../store/main.store'
import { AppAnalogBar } from '../../analog/AppAnalogBar'
import { observer } from 'mobx-react-lite'
import { NodeId } from '../../../../../util/node-id'
import { SxHelperStyle } from '../../../../theme/style/sx-helper.style'
import { themeStoreInstance } from 'src/feature/theme/store/theme.store'

type Props = HeaderProps & ChildrenProps

export const BaseAppHeaderBar = observer((props: Props) => {
  const mainStore = useMainStore()

  const { children } = props
  const isLoading = mainStore.isGlobalLoader && mainStore.isLoading

  return (
    <AppAnalogBar
      sx={styles.root}
      id={NodeId.HEADER}
    >
      <AppProgressLine isShowing={isLoading} sx={styles.loading} />

      <AppContainer sx={styles.container}>
        {themeStoreInstance.mode === 'light' ? <Logo type={LogoType.BLACK} /> : <Logo type={LogoType.WHITE} />}
        {children}
      </AppContainer>
    </AppAnalogBar>
  )
})

const styles = {
  root: {
    backgroundColor: 'background.default',
    fontSize: '1rem',
  },
  loading: {
    position: 'absolute',
    top: 0,
    margin: 0,
    borderRadius: '0em',
  },
  container: {
    ...SxHelperStyle.flexCenterX,
    justifyContent: 'space-between',
    flexDirection: 'row',
    margin: '0 auto',
    maxWidth: 'unset',
  },
}
