import { ModalWindow } from '../../../component/common/analog/template/ModalWindow'
import { AskQuestionFormModel, askQuestionFormSchema, getAskQuestionFormInitialValues } from '../model/AskQuestionForm'
import { observer } from 'mobx-react-lite'
import { AppProgressLine } from '../../../component/common/analog/molecule/loader/AppProgressLine'
import { useUserStore } from '../../user/store/user.store'
import { FormikTextField } from '../../form/component/original/FormikTextField'
import { EmailField } from '../../form/component/original/prepared-field/EmailField'
import { QUESTION_MAX_LENGTH } from '../../form/model/FormSchema'
import { AppButton } from '../../../component/common/analog/molecule/button/AppButton'
import { useLocalizationStore } from '../../localization/store/localization.store'
import { NameField } from '../../form/component/original/prepared-field/NameField'
import { BoolProps } from '../../../model/Boolean'
import { AppFormik } from '../../form/component/analog/AppFormik'
import { useLoader } from '../../../hook/bool/useLoader'
import { askQuestion } from '../api/question'
import { toStr } from '../../../model/String'
import { useNotificationStore } from '../../notification/store/notification.store'

export const AskQuestionModal = observer((props: BoolProps) => {
  const userStore = useUserStore()
  const { translation } = useLocalizationStore()
  const loader = useLoader()

  const values = getAskQuestionFormInitialValues(toStr(userStore?.fullName), userStore.userData?.email)
  const notificationStore = useNotificationStore()

  const handleSubmit = async (values: AskQuestionFormModel) => {
    try {
      loader.start()
      await askQuestion(values)
    } catch (e: any) {
      notificationStore.enqueueError(e.message ? e.message : 'ERROR')
    } finally {
      loader.end()
      props.makeFalse()
    }
  }

  return (
    <ModalWindow
      modalProps={{
        open: props.isTrue,
        onClose: props.makeFalse,
      }}
      topProps={{
        mb: 28,
      }}
      titleProps={{
        textValue: translation.components.gotQuestionsModal.title,
      }}
    >
      <AppFormik
        values={values}
        schema={askQuestionFormSchema}
        onSubmit={handleSubmit}
      >
        <NameField />
        <EmailField />
        <FormikTextField
          multiline
          keyword={'question'}
          minRows={5}
          maxRows={Infinity}
          inputProps={{
            maxLength: QUESTION_MAX_LENGTH,
          }}
        />

        <AppButton type="submit" textValue={translation.buttons.send} variant="contained" ml={'auto'} />

        <AppProgressLine isShowing={loader.isShowing} />
      </AppFormik>
    </ModalWindow>
  )
})
