import { axiosInstance } from '../../../util/axios/instance.axios'
import { CommentModel } from '../model/Comment'

export const deleteComment = async (postId: string, commentId: string) => {
  await axiosInstance({
    url: `/posts/${postId}/comments/${commentId}`,
    method: 'delete',
  })
}

export const createComment = async (postId: string, content: string): Promise<CommentModel> => {
  const response = await axiosInstance.request<CommentModel>({
    url: `/posts/${postId}/comments`,
    method: 'post',
    data: { content },
  })

  return response.data
}

export interface GetCommentsRequest {
  postId: string
  queries?: {
    page?: number
    amount?: number
  }
}

export interface GetCommentsResponse {
  paginatedCommentsEntities: CommentModel[]
  hasMore: boolean
  allCommentsIds: string[]
}

export const getComments = async (getCommentsRequest: GetCommentsRequest): Promise<GetCommentsResponse> => {
  const response = await axiosInstance.request<GetCommentsResponse>({
    url: `/posts/${getCommentsRequest.postId}/comments`,
    params: getCommentsRequest.queries,
  })

  return response.data
}
