import { getFirstStr } from '../helper'
import { checkHttpAnyError, HttpStatus, HttpUnknownError } from '../../model/Http'
import { axiosInstance } from './instance.axios'
import { AxiosResponse } from 'axios'
import { authStore } from '../../feature/auth/store/auth.store'
import { authApiUrl } from '../../feature/auth/api/auth'
import { COMPANY_NAME } from '../constant'

export class HelperAxios {
  static getErrorMessage(data: any): string | never {
    return getFirstStr(data?.response?.data?.message) || data?.toJSON?.()?.message || data?.toJSON?.()?.errors[0]?.message || data?.response?.data?.errors[0]?.message || data?.response?.data?.message || 'Unknown axios error message'
  }

  static isError(response: AxiosResponse) {
    const statusCode = this.getStatusCode(response)

    return checkHttpAnyError(statusCode)
  }

  static isYohrsResponse(response: AxiosResponse) {
    return response.headers['x-custom-app-name'] === COMPANY_NAME
  }

  // statusCode field is more pinpoint than status field
  static getStatusCode(response: AxiosResponse) {
    const result = response?.data?.statusCode || response?.data?.status || response?.status

    if (!result) {
      throw new HttpUnknownError('Unknown response status code!')
    }

    return result
  }

  static shouldRepeatRequest(response: AxiosResponse) {
    const skippedUrls = Object.values(authApiUrl)

    if (response?.config?.url) {
      const isAllowedUrl = !skippedUrls.includes(response.config.url)
      const isNotInfinitLoop = response.config.url !== '/app/update-access-token' && authStore.accessToken

      return response?.status === HttpStatus.UNAUTHORIZED && isAllowedUrl && HelperAxios.isYohrsResponse(response) && isNotInfinitLoop
    }
  }

  // request refresh token and repeat request if token is invalid
  static async handleRepeatRequest(response: AxiosResponse) {
    const request = response?.config
    await authStore.handleUpdateAccessToken()

    // update request with new access token
    if (request?.headers) {
      request.headers!['Authorization'] = `Bearer ${authStore.accessToken}`
    }

    return axiosInstance(request)
  }
}
