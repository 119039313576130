import { applyInterceptors } from './util/axios/interceptors.axios'
import { axiosInstance } from './util/axios/instance.axios'
import { renderApp } from './model/React'
import { AppProvider } from './component/unique/provider/AppProvider'
import { App } from './component/unique/organism/App'
import { TourProvider } from '@reactour/tour'
import steps from './steps'
import { useTheme } from '@mui/material'
import { CSSProperties } from 'react'

applyInterceptors(axiosInstance)

function AppWithTour() {
  const theme = useTheme()

  const TourStyles = {
    popover: (base: CSSProperties) => ({
      ...base,
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    }),
    badge: (base: CSSProperties) => ({
      ...base,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.primary,
    }),
    dot: (base: CSSProperties, state: any) => ({
      ...base,
      backgroundColor: state.current ? theme.palette.primary.main : base.backgroundColor,
    }),
  }

  return (
    <TourProvider steps={steps} styles={TourStyles}>
      <App />
    </TourProvider>
  )
}

renderApp(
  <AppProvider>
    <AppWithTour />
  </AppProvider>,
)
