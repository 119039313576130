import { GenericStore } from '../../../util/mobx/generic-store/generic.store'
import { createStore } from '../../../util/mobx/generic-store/store-creator'

export class OverlayStore extends GenericStore {
  showOverlay!: boolean

  constructor() {
    super('OverlayStore')
    this.showOverlay = true
    super.observe(this)
    super.persist({ encrypt: false })
  }

  closeOverlay() {
    this.showOverlay = false
  }
}

export const {
  storeInstance: overlayStoreInstance,
  useStore: useOverlayStore,
  StoreProvider: OverlayStoreProvider,
} = createStore(new OverlayStore())
