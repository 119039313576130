import { CssUnit } from '../model/CssUnit'
import { Theme } from '@mui/material'

export class SxHelperStyle {
  // size
  static normalSize = { minWidth: '0%', maxWidth: 1 }
  static fullSize = { width: 1, height: 1 }
  static fullScreen = { width: '100vw', height: '100vh' }
  static fullCoords = { left: 0, right: 0, bottom: 0, top: 0 }

  static get absoluteFullSize() {
    return { ...this.fullSize, ...this.fullCoords, position: 'absolute' }
  }

  static get absoluteFullScreen() {
    return { ...this.fullScreen, ...this.fullCoords, position: 'absolute' }
  }

  // flexbox
  static flexCenterX = { display: 'flex', alignItems: 'center' }
  static flexCenterY = { display: 'flex', justifyContent: 'center' }
  static flexCenter = { ...this.flexCenterX, ...this.flexCenterY }
  static flexColumn = { display: 'flex', flexDirection: 'column' }
  static flexColumnCenterY = { ...this.flexColumn, alignItems: 'center' }

  // indents
  static noIndents = { padding: 0, margin: 0 }
  static horizontalSpacing = (spacing: CssUnit) => ({ handleCssUnit }: Theme) => ({
    mx: handleCssUnit(spacing),
    '&:first-of-type': {
      ml: 0,
    },
    '&:last-of-type': {
      mr: 0,
    },
  })
}
