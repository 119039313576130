import { axiosInstance } from '../../../util/axios/instance.axios'
import { HubSpotContactProperties } from '../model/HubSpot'

export const hubSpotUpdateOrCreateContact = async <T extends HubSpotContactProperties>(data: T): Promise<T> => {
  const response = await axiosInstance.request<T>({
    url: '/hubspot/update-or-create-contact',
    method: 'post',
    data,
  })

  return response.data
}
