import SwipeableDrawer, { SwipeableDrawerProps } from '@mui/material/SwipeableDrawer'
import { NotOverridableComponentProps } from '../../../../model/Component'
import { AppBox } from '../molecule/AppBox'

export type AppSwipeableDrawerProps = NotOverridableComponentProps<SwipeableDrawerProps>

export function AppSwipeableDrawer(props: AppSwipeableDrawerProps) {
  const { transitionDuration = 200, elevation = 0, ...rest } = props

  const isIOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

  return (
    <AppBox
      disableBackdropTransition={!isIOS}
      disableDiscovery={isIOS}
      transitionDuration={transitionDuration}
      elevation={elevation}
      {...rest}
      component={SwipeableDrawer}
    />
  )
}
