import { ShouldBe } from '../../../../model/Util'
import { ChildrenProps } from '../../../../model/React'

export interface IfProps extends ChildrenProps {
  condition: ShouldBe<boolean>
}

export function If({ condition, children }: IfProps) {
  if (!condition) {
    return null
  }

  return (
    <>
      {children}
    </>
  )
}
