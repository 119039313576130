import { PreparedFieldTemplateProps } from '../../model/PreparedField'
import { FormikTextField } from './FormikTextField'
import { TranslatedTextField } from './TranslatedTextField'

export function PreparedFieldTemplate(props: PreparedFieldTemplateProps) {
  const { isFormik = true, ...rest } = props
  const FinalField = isFormik ? FormikTextField : TranslatedTextField

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <FinalField {...rest} />
}
