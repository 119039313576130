const steps = [
  {
    selector: '.first-step',
    content: 'Here you can see your notifications.',
  },
  {
    selector: '.second-step',
    content: 'Your profile',
  },
  {
    selector: '.third-step',
    content: 'Navigation links',
  },
]

export default steps