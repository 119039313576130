import { getRouteObjectList, withLayout } from '../router/util/router.helper'
import { RouterLayout } from '../router/component/layout/RouterLayout'
import { authRouteIds } from '../router/model/RouteId'

export const AutRoutes = [
  withLayout({
    layout: <RouterLayout />,
    routes: getRouteObjectList(authRouteIds),
  }),
]
