import { Select, Theme } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { AnyObject } from '../../../model/Object'

interface Props {
  onChange: (value: string) => void,
  value: string,
  links: AnyObject
}

export function VillkorSelector(props: Props) {
  const { onChange, value, links } = props
  const linksArray = Object.keys(links)

  return (
    <Select
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={e => onChange(e.target.value)}
      value={value}
      sx={{
        margin: 10,
      }}
    >
      {
        linksArray.map(link => (
          <MenuItem key={links[link].label} sx={styles} value={links[link].value}>{links[link].label}</MenuItem>
        ))
      }
    </Select>
  )
}

const styles = ({ palette }: Theme) => ({
  '&:hover': {
    backgroundColor: palette.grey[2],
  },
})
