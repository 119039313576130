import { ShouldBe } from './Util'
import { SetState } from './React'

type BoolType = boolean

export type IsTrue = ShouldBe<BoolType> | null

export interface BoolProps {
  isTrue: IsTrue
  update: SetState<BoolType>
  reset: VoidFunction
  makeFalse: VoidFunction
  makeTrue: VoidFunction
  toggle: VoidFunction
}

export function toggle(value: boolean): boolean {
  return !value
}
