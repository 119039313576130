import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { AdministratorUserDetailsPage } from './AdministratorUserDetailsPage'

export const UserDetailsNotFoundPage = observer(() => {
  const { userId } = useParams()

  if (!userId) {
    throw new Error('Missing user key')
  }

  return (
    <AdministratorUserDetailsPage userId={userId} />
  )
})
