import { AppBox } from '../AppBox'
import { NotOverridableComponentProps } from '../../../../../model/Component'
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent'

export type AppDialogContentProps = NotOverridableComponentProps<DialogContentProps>

export function AppDialogContent(props: AppDialogContentProps) {
  return (
    <AppBox
      component={DialogContent}
      {...props}
    />
  )
}
