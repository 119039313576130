import { DeepReadonlyArray, Tuple } from './Array'
import { isLength } from '../util/helper'

export type DeepReadonly<T> =
  T extends (infer R)[] ? DeepReadonlyArray<R> :
    // eslint-disable-next-line @typescript-eslint/ban-types
    T extends Function ? T :
      T extends object ? DeepReadonlyObject<T> :
        T;

export type Mutable<Type> = {
  -readonly [Key in keyof Type]: Type[Key];
};

export type DeepPartial<T> = T extends AnyObject ? {
  [P in keyof T]?: DeepPartial<T[P]>;
} : T;

export type DeepReadonlyObject<T> = {
  readonly [P in keyof T]: DeepReadonly<T[P]>;
};

export type ObjKey = string | number | symbol

export type ObjProp = ObjKey

export type AnyObject = Record<ObjKey, any>

export type EmptyObject = Record<ObjKey, never>

export const OBJECT_ENTRY_KEY_INDEX = 0

export function getKeyFromObjEntry(entry: [ObjKey, unknown]) {
  return entry[OBJECT_ENTRY_KEY_INDEX]
}

export type Entries<Key extends ObjKey, Value extends AnyObject> = Tuple<Key, Value>[]

export function objKeys<T extends object>(obj: T) {
  const result = Object.keys(obj)

  if (!result) {
    throw new Error('Provide an object!')
  }

  return result as Array<keyof typeof obj>
}

export function freeze<T extends Record<string, any>>(obj: T) {
  return obj as DeepReadonly<T>
}

export function getObjProp<Obj extends AnyObject = AnyObject,
  Key extends keyof Obj = keyof Obj>(obj: Obj, key: Key): Obj[Key] {
  return obj[key]
}

export function checkAreObjKeys<T extends AnyObject>(obj: any): obj is T {
  return obj && isLength(Object.keys(obj))
}

export function arePropsInObj<T extends object>(obj: T, props: any[]): obj is T {
  if (!checkAreObjKeys(obj)) {
    return false
  }

  return props.every(c => c in obj)
}

export function getConditionalObjProps<T extends object>({ if: condition, props }: { if: unknown, props: T }) {
  return condition ? props : {}
}
