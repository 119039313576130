import { StringText } from '../feature/localization/model/Text'

export function toStr(value: any): string {
  return value?.toString() || ''
}

export function concatStrings(arr: any[]) {
  return arr.join(' ').trim()
}

export function isStr(value: any): value is string {
  return typeof value === 'string'
}

export function isStrContains(source: string, searched: string) {
  return source?.toLowerCase()?.includes(searched?.toLowerCase())
}

export function getAcronym(value: string): string {
  const defaultValue = '-'

  if (!isStr(value)) {
    return defaultValue
  }

  const matches = value.match(/\b(\w)/g)

  if (!matches) {
    return defaultValue
  }

  return matches.join('')
}

export function toLowerCaseEachWord(text: StringText): StringText {
  return text.replace(/(^\w|\s\w)/g, m => m.toLowerCase())
}

export function toKebabCase(str = ''): string {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    ?.map(x => x.toLowerCase())
    ?.join('-')
}
