import { AxiosErrorModel } from '../../../model/Axios'
import { HelperAxios } from '../helper.axios'
import { handleAxiosError } from '../handle-error.axios'
import { logAxios } from '../log.axios'
import { AxiosResponse } from 'axios'
import { authStore } from '../../../feature/auth/store/auth.store'

export function handleFulfilledAxiosResponse(response: AxiosResponse) {
  logAxios(response)

  if (HelperAxios.isError(response)) {
    if (HelperAxios.shouldRepeatRequest(response)) {
      return HelperAxios.handleRepeatRequest(response)
    }

    return handleAxiosError({ response } as AxiosErrorModel) // Refactor
  }

  handleSaveTokens(response)

  return response
}

function handleSaveTokens(response: AxiosResponse) {
  const accessToken = response?.data?.tokens?.accessToken
  const refreshToken = response?.data?.tokens?.refreshToken

  if (accessToken) {
    authStore.accessToken = accessToken
  }
  if (refreshToken) {
    authStore.refreshToken = refreshToken
  }
}
