import { RouterLayout } from '../../../router/component/layout/RouterLayout'
import { PayrollStoreProvider } from '../../store/payroll.store'

export function PayrollRoutesProvider() {
  return (
    <PayrollStoreProvider>
      <RouterLayout />
    </PayrollStoreProvider>
  )
}
