import * as MobxPersistStore from 'mobx-persist-store'
import { safe_makeAutoObservable } from '../observable'
import { getEnv } from '../../helper'
import lodashUnion from 'lodash/union'
import { EncryptedLocalStorage } from '../../local-storage'
import { GenericFields } from './generic-fields'
import { removeElementsFromArrByAnotherArr } from '../../../model/Array'
import { LOCAL_STORAGE_PREFIX } from './generic.constant'
import { ObjProp } from '../../../model/Object'

export interface Persist {
  encrypt: boolean;
  excludedProperties?: ObjProp[];
  includedProperties?: ObjProp[]
}

export class GenericStore extends GenericFields {
  constructor(private storeName: string) {
    super()
  }

  private get defaultPersistOptions(): Persist {
    return { excludedProperties: [], encrypt: true, includedProperties: this.propertyNames }
  }

  private get localStorageKey(): string {
    return LOCAL_STORAGE_PREFIX + this.storeName
  }

  private get isStoreAlreadyPersisted(): boolean {
    return [...MobxPersistStore.PersistStoreMap.values()]
      .map(item => item.storageName)
      .includes(this.localStorageKey)
  }

  private get encryptedStorage() {
    const instance = new EncryptedLocalStorage({
      secret: getEnv('REACT_APP_LOCAL_STORAGE_ENCRYPT_KEY'),
      encrypt: true,
    })

    return {
      setItem: (key: string, value: any) => instance.set(key, value),
      getItem: (key: string) => instance.getRaw(key),
      removeItem: instance.delete,
    }
  }

  stopPersisting() {
    MobxPersistStore.stopPersisting(this)
  }

  protected observe(context: any) {
    safe_makeAutoObservable(context, {}, { autoBind: true, deep: true })
  }

  protected async hydrateStore() {
    await MobxPersistStore.hydrateStore(this)
  }

  protected async getPersistedStore() {
    return MobxPersistStore.getPersistedStore(this)
  }

  protected async clearPersistedStore() {
    await MobxPersistStore.clearPersistedStore(this)
  }

  protected startPersisting() {
    MobxPersistStore.startPersisting(this)
  }

  protected pausePersisting() {
    MobxPersistStore.pausePersisting(this)
  }

  protected persist(
    options: Persist = this.defaultPersistOptions,
  ) {
    if (!this.isStoreAlreadyPersisted) {
      const properties = this.handleProperties(options)

      MobxPersistStore.makePersistable(this, {
        name: this.localStorageKey,
        storage: this.getStorage(options.encrypt),
        properties,
      })
    }
  }

  private handleProperties(options: Persist) {
    const afterExcluding = removeElementsFromArrByAnotherArr(this.propertyNames, options.excludedProperties)

    return lodashUnion(afterExcluding, options.includedProperties) as unknown as (keyof this)[]
  }

  private getStorage(shouldEncryptStorage: boolean) {
    return shouldEncryptStorage ? this.encryptedStorage : localStorage
  }
}
