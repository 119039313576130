import { Maybe } from '../../../../../model/Util'
import { handlePassedSx } from '../../../../../feature/theme/model/Sx'
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'
import { NotOverridableComponentProps } from '../../../../../model/Component'
import { AppBox } from '../AppBox'

interface BaseProps extends CircularProgressProps {
  isShowing: Maybe<boolean>
}

export type AppCircleLoaderProps = NotOverridableComponentProps<BaseProps>

export function AppCircleLoader(props: AppCircleLoaderProps) {
  const { sx = [], isShowing, ...rest } = props

  if (!isShowing) {
    return null
  }

  return (
    <AppBox
      sx={[
        styles,
        ...handlePassedSx(sx),
      ]}
      {...rest}
      component={CircularProgress}
    />
  )
}

const styles = () => ({
})
