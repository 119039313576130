import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import { NotOverridableComponentProps } from '../../../../../model/Component'
import { AppBox } from '../AppBox'

export type AppIconButtonProps = NotOverridableComponentProps<IconButtonProps>

export function AppIconButton(props: AppIconButtonProps) {
  return (
    <AppBox
      component={IconButton}
      {...props}
    />
  )
}
