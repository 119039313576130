import { Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Word } from 'src/feature/localization/component/atom/Word'
import { keyframes } from '@emotion/react'
import { AppBox } from './AppBox'

export type AppAnimatedTextProps = {
  text: string
  delay?: number
  sx?: object
}

export const AppAnimatedText = observer(({ text, delay = 50, sx }: AppAnimatedTextProps) => {
  const [displayedText, setDisplayedText] = useState<string>('')
  const [isAnimating, setIsAnimating] = useState<boolean>(true)

  useEffect(() => {
    setDisplayedText('')
    setIsAnimating(true)
    let currentIndex = 0

    const displayNextLetter = () => {
      if (currentIndex <= text.length) {
        setDisplayedText(text.slice(0, currentIndex))
        currentIndex++
        setTimeout(displayNextLetter, delay)
      } else {
        setIsAnimating(false)
      }
    }

    displayNextLetter()

    return () => {
      currentIndex = text.length
    }
  }, [text, delay])

  return (
    <AppBox sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
      <Typography sx={sx}>{displayedText}</Typography>
      {isAnimating && <Word sx={styles.animatedDot} />}
    </AppBox>
  )
})

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.9;
  }
  100% {
    transform: scale(1.5);
    opacity: 1;
  }
`

const styles = {
  animatedDot: {
    width: '0.8rem',
    height: '0.8rem',
    marginLeft: '8px',
    backgroundColor: 'text.primary',
    borderRadius: '50%',
    animation: `${pulse} 0.6s infinite alternate`,
  },
}
