/*eslint-disable no-template-curly-in-string*/

import { yupPrintValue } from '../print-value'

// Baserat på https://github.com/jquense/yup/blob/2973d0a/src/locale.js
const mixed = {
  default: '${path} är ogiltig',
  required: '${path} är ett obligatoriskt fält',
  oneOf: '${path} måste vara ett av följande värden: ${values}',
  notOneOf: '${path} får inte vara ett av följande värden: ${values}',
  notType: ({ path, type, value, originalValue }: any) => {
    // eslint-disable-next-line no-eq-null
    const isCast = originalValue != null && originalValue !== value

    let msg = `${path} måste vara en \`${type}\` typ, ` + `men det slutliga värdet var: \`${yupPrintValue(value, true)}\`` + `${ isCast
      ? ` (cast från värdet \`${yupPrintValue(originalValue, true)}\`).`
      : '.'}`

    if (value === null) {
      msg += `\n Om "null" är avsett som ett tomt värde, se till att markera schemat som \`.nullable()\``
    }

    return msg
  },
}

const string = {
  length: '${path} måste vara exakt ${length} tecken',
  min: '${path} måste bestå av minst ${min} tecken',
  max: '${path} får vara högst ${max} tecken',
  matches: '${path} måste matcha följande: "${regex}"',
  email: '${path} måste vara en giltig e-postadress',
  url: '${path} måste vara en giltig URL',
  trim: '${path} måste vara en trimmad sträng',
  lowercase: '${path} måste vara en gemen sträng',
  uppercase: '${path} måste vara en versalsträng',
}

const number = {
  min: '${path} måste vara större än eller lika med ${min}',
  max: '${path} måste vara mindre än eller lika med ${max}',
  lessThan: '${path} måste vara mindre än ${less}',
  moreThan: '${path} måste vara större än ${more}',
  notEqual: '${path} får inte vara lika med ${notEqual}',
  positive: '${path} måste vara ett positivt tal',
  negative: '${path} måste vara ett negativt tal',
  integer: '${path} måste vara ett heltal',
}

const date = {
  min: '${path} fältet måste vara senare än ${min}',
  max: '${path} fältet måste vara tidigare än ${max}',
}

const boolean = {}

const object = {
  noUnknown: '${path} fältet kan inte ha nycklar som inte är specificerade i objektformen',
}

const array = {
  min: '${path} fältet måste ha minst ${min} objekt',
  max: '${path} fältet måste ha mindre än eller lika med ${max} objekt',
}

export const sv_SE_Yup = {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
}
