import { SidebarProps } from '../model/Sidebar'
import { DesktopSidebar } from './DesktopSidebar'
import { MobileSidebar } from './MobileSidebar'
import { useBreakpoints } from '../../theme/hook/useBreakpoints'

export function Sidebar(props: SidebarProps) {
  const { isMd } = useBreakpoints()

  const CurrentSidebar = isMd ? MobileSidebar : DesktopSidebar

  return <CurrentSidebar {...props} />
}
