import { Id } from './Id'
import { Maybe } from './Util'
import lodashIsObject from 'lodash/isObject'
import { isStr } from './String'

export interface Size {
  width: number
  height: number
}

export interface Sizes {
  client: Size
  scroll: Size
}

const defaultSize = {
  width: 0,
  height: 0,
}

export type DomNodeId = Id

export function getNodeSizes(id: DomNodeId): Sizes

export function getNodeSizes(node: HTMLElement): Sizes

export function getNodeSizes(value: any): Sizes {
  let element: Maybe<HTMLElement>

  let sizes: Sizes = {
    client: defaultSize,
    scroll: defaultSize,
  }

  if (lodashIsObject(value)) {
    element = value as HTMLElement
  } else if (isStr(value)) {
    element = document.getElementById(value)
  }

  if (element) {
    sizes = {
      client: {
        width: element.clientWidth,
        height: element.clientHeight,
      },
      scroll: {
        width: element.scrollWidth,
        height: element.scrollHeight,
      },
    }
  }

  return sizes
}

export function isBrowser() {
  return typeof window !== 'undefined'
}
