import React, { SetStateAction } from 'react'
import { createRoot } from 'react-dom/client'
import { AnyObject } from './Object'

export type ReactChildren = React.ReactNode

export type ReactElement<T0 = any> = React.ReactElement<T0, any>

export interface ChildrenProps {
  children?: ReactChildren
}

export type WithoutChildren<T extends AnyObject> = Omit<T, 'children'>

export type SetState<T = any> = React.Dispatch<SetStateAction<T>>

export function renderApp(component: React.ReactNode, rootNodeId = 'root') {
  const rootNode = document.getElementById(rootNodeId)

  if (!rootNode) {
    throw new Error(`Could not find element with id: ${rootNodeId}`)
  }

  const root = createRoot(rootNode)

  return root.render(component)
}

export type OnChangeFn = React.ChangeEventHandler<any>

export type TextFieldTargetValue = string

export type ComponentModel<Props extends AnyObject = AnyObject> = ((props: Props) => React.ReactElement | null) & { displayName?: string }
