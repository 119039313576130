import { createStore } from '../../../util/mobx/generic-store/store-creator'
import { BodyRow, PayrollSheetPage } from '../model/PayrollGoogleSheets'
import { FIRST_INDEX, SECOND_INDEX } from '../../../util/constant'
import { getEnv, isLength } from '../../../util/helper'
import { GenericStoreAsyncMethod } from '../../../util/mobx/generic-store/decorator/async-method.decorator'
import { GoogleSheetsResponse } from '../../google/model/GoogleSheets'
import { fetchGoogleSheets } from '../../google/api/google-sheets'
import { TextFieldTargetValue } from '../../../model/React'
import { generateUniqId } from '../../../model/Id'
import { localizationStoreInstance } from '../../localization/store/localization.store'
import { GenericStore } from '../../../util/mobx/generic-store/generic.store'
import { GenericStoreMethod } from '../../../util/mobx/generic-store/decorator/method.decorator'
import { getArrMiddleElement } from '../../../model/Array'
import { isStrContains } from '../../../model/String'

type RowsPerPageValue = number
const DEFAULT_PAGE = 0
const ROWS_PER_OPTIONS = [10, 80, 120]
const DEFAULT_ROWS_PER_PAGE = getArrMiddleElement(ROWS_PER_OPTIONS)

const payrollRequestBaseArg = {
  spreadSheetId: '1toFSAeGN7Q8HsRBKlYUQtLKiGMZRx2oHclpNMH9GC-A',
  apiKey: getEnv('REACT_APP_API_KEY_GOOGLE_CONSOLE'),
}

export class PayrollStore extends GenericStore {
  raw!: GoogleSheetsResponse
  searchedFixedBody!: BodyRow[]
  page!: number
  rowsPerPage!: RowsPerPageValue
  rowsPerPageOptions: RowsPerPageValue[] = ROWS_PER_OPTIONS
  searchValue!: string

  constructor() {
    super('PayrollStore')

    super.observe(this)
    this.reset()
  }

  reset() {
    this.page = DEFAULT_PAGE
    this.searchValue = ''
    this.searchedFixedBody = []
    this.rowsPerPage = DEFAULT_ROWS_PER_PAGE
    this.raw = {
      range: '',
      majorDimension: '',
      values: [],
    }
  }

  get isSearchedValue() {
    return isLength(this.searchValue)
  }

  get displayedFixedBody() {
    return this.isSearchedValue ? this.searchedFixedBody : this.fullFixedBody
  }

  get isData() {
    return isLength(this.raw.values)
  }

  get head() {
    return this.raw.values[FIRST_INDEX]
  }

  get rawBody() {
    return this.raw.values.slice(SECOND_INDEX)
  }

  get fullFixedBody() {
    if (!this.rawBody || !isLength(this.rawBody)) {
      return []
    }

    const result: BodyRow[] = []

    this.rawBody.forEach((c: string[], i: number, a) => {
      if (c.length === 0) {
        return
      }

      const position = c[FIRST_INDEX]
      const hasPosition = isLength(position) && (i === 0 || i % 3 === 0)
      const totalSalaryRow = a[i + 2]

      if (!hasPosition || !totalSalaryRow) {
        return
      }

      const salaryData = totalSalaryRow.slice(1)
      const row: BodyRow = {
        position,
        median2020: salaryData[0],
        percentage10: salaryData[1],
        percentage25: salaryData[2],
        percentage75: salaryData[3],
        percentage90: salaryData[4],
        uid: generateUniqId(),
      }
      result.push(row)
    })

    return result
  }

  get currentPageRows() {
    return this.getCurrentPageRows(this.displayedFixedBody)
  }

  getCurrentPageRows(list: BodyRow[]) {
    return list.slice(this.page * this.rowsPerPage, this.page * this.rowsPerPage + this.rowsPerPage)
  }

  changePage(newPage: number) {
    this.page = newPage
  }

  changeRowsPerPage(value: RowsPerPageValue) {
    this.rowsPerPage = Number(value) || DEFAULT_ROWS_PER_PAGE
    this.changePage(DEFAULT_PAGE)
  }

  @GenericStoreAsyncMethod()
  async handleFetchPayrollByLocale() {
    const payrollSheetPageByLocale: PayrollSheetPage = localizationStoreInstance.locale

    this.raw = await fetchGoogleSheets({ ...payrollRequestBaseArg, range: payrollSheetPageByLocale })
  }

  @GenericStoreMethod()
  searchRows(value: TextFieldTargetValue) {
    this.searchValue = value

    this.searchedFixedBody = this.fullFixedBody
      .filter((row: BodyRow) => isStrContains(row.position, this.searchValue))
  }
}

export const {
  storeInstance: payrollStoreInstance,
  useStore: usePayrollStore,
  StoreProvider: PayrollStoreProvider,
} = createStore(new PayrollStore())
