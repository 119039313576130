import { TranslatableText, TranslatableTextVariantProps } from './TranslatableText'

export function Info(props: TranslatableTextVariantProps) {
  return (
    <TranslatableText
      component={'p'}
      {...props}
      variant={'body2'}
    />
  )
}
