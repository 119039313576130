import { YohrsUser } from '../model/User'
import { axiosInstance } from '../../../util/axios/instance.axios'
import { YohrsUserDataResponse } from '../model/Response'
import { Organisation } from '../model/Organisation'
import { SiteMap } from '../model/SiteMap'
import { PositionUser } from '../model/PositionUser'

const APP_API_PREFIX = '/user/'
const APP_PAYMENT_API_PREFIX = '/user-payment/'
const APP_B2B_PREFIX = '/b2b/'

export function getUserApiUrl(path = '') {
  return APP_API_PREFIX + path
}

export function getUserPaymentApiUrl(path = '') {
  return APP_PAYMENT_API_PREFIX + path
}

export function getB2bApiUrl(path = '') {
  return APP_B2B_PREFIX + path
}

export async function getCurrentUser() {
  const response = await axiosInstance.request<YohrsUserDataResponse>({
    url: getUserApiUrl(),
  })

  return response.data.userData
}

export async function updateCurrentUser(user: Partial<YohrsUser>): Promise<YohrsUser> {
  const response = await axiosInstance.request<YohrsUserDataResponse>({
    url: getUserApiUrl(),
    method: 'patch',
    data: user,
  })

  return response.data.userData
}

export async function deleteCurrentUser() {
  return axiosInstance({
    url: getUserApiUrl(),
    method: 'delete',
  })
}

export async function resendVerfication() {
  return axiosInstance({
    url: getUserApiUrl('verify'),
    method: 'put',
  })
}

export async function uploadAvatar(file: File) {
  const formData = new FormData()
  formData.append('file', file)
  await axiosInstance({
    url: getUserApiUrl('upload'),
    method: 'post',
    headers: {
      contentType: 'multipart/form-data',
    },
    data: formData,
  })

  // axiosInstance({
  //   url,
  //   method: 'put',
  //   data: file,
  //   headers: {
  //     'Content-Type': file.type,
  //   },
  // })
}

export async function getAvatarUrlFromS3(avatarUrl: string): Promise<Blob> {
  const response = await axiosInstance.request<Blob>({
    url: avatarUrl,
  })

  return response.data
}

export async function getActiveSubscriptions(): Promise<string[]> {
  const { data } = await axiosInstance.request<string[]>({
    url: getUserPaymentApiUrl('subscriptions'),
  })

  return data
}

export async function getOrganisation(): Promise<Organisation> {
  const { data } = await axiosInstance.request<Organisation>({
    url: getB2bApiUrl('admin/organisation'),
  })

  return data
}

export async function getSiteMap(): Promise<SiteMap> {
  const { data } = await axiosInstance.request<SiteMap>({
    url: getB2bApiUrl('admin/sitemap'),
  })

  return data
}

export async function getPositionUser(): Promise<PositionUser[]> {
  const { data } = await axiosInstance.request<PositionUser[]>({
    url: getUserApiUrl('position'),
  })

  return data
}

export async function postPositionUser(positions: PositionUser[]): Promise<void> {
  await axiosInstance.request<PositionUser[]>({
    url: getUserApiUrl('position'),
    method: 'post',
    data: positions,
  })
}