import { useEffect, useState } from 'react'
import { DomNodeId, getNodeSizes, Sizes } from '../../model/DOM'

export function useScrollSizes(id: DomNodeId, subscribe = false): Sizes {
  const [sizes, setSizes] = useState<Sizes>(getNodeSizes(id))

  const handleSetSizes = () => setSizes(getNodeSizes(id))

  useEffect(() => {
    handleSetSizes()

    if (subscribe) {
      document.addEventListener('scroll', handleSetSizes)

      return () => document.removeEventListener('scroll', handleSetSizes)
    }
  }, [])

  return sizes
}
