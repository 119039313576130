import { TranslatableText, TranslatableTextVariantProps } from './TranslatableText'

export function Title4(props: TranslatableTextVariantProps) {
  return (
    <TranslatableText
      variant={'h4'}
      {...props}
      component={'h4'}
    />
  )
}
