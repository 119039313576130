export const BROWSER_FONT_SIZE = 15 // px // learn more - https://mui.com/material-ui/customization/typography/#html-font-size

export const APP_FONT_SIZE = 19 // px

export const LINK_FONT_SIZE = APP_FONT_SIZE

export const APP_HTML_FONT_SIZE = `${(APP_FONT_SIZE / BROWSER_FONT_SIZE)}rem`

export const MuiSystemProp = {
  fullSize: 1,
}
