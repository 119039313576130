import { NotOverridableComponentProps } from '../../../../../model/Component'
import { AppStack, AppStackProps } from './AppStack'
import MenuList, { MenuListProps } from '@mui/material/MenuList'
import { forwardRef } from 'react'

export type AppMenuListProps = NotOverridableComponentProps<AppStackProps & MenuListProps>

export const AppMenuList = forwardRef((props: AppMenuListProps, ref) => {
  return (
    <AppStack
      {...props}
      ref={ref}
      component={MenuList}
    />
  )
})

AppMenuList.displayName = 'AppMenuList'
