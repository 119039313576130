import { observer } from 'mobx-react-lite'
import { useAuthStore } from '../../../auth/store/auth.store'
import { PublicRoutesStack } from './stack/PublicRoutesStack'
import { AuhtenticatedRoutesStack } from './stack/AuhtenticatedRoutesStack'
import { MaintenanceRoutesStack } from './stack/MaintenanceRoutesStack'
import { useMaintenanceStore } from '../../../maintenance/store/maintenance.store'

export const Router = observer(() => {
  const maintenanceStore = useMaintenanceStore()
  const authStore = useAuthStore()

  switch (true) {
  case maintenanceStore.isMaintenance:
    return <MaintenanceRoutesStack />
  case authStore.isAuthorized:
    return <AuhtenticatedRoutesStack />
  default:
    return <PublicRoutesStack />
  }
})
