import { OverrideFunc } from '../../../../model/Override'
import { AppStyle } from '../../../../style/app.style'

export const MuiLink: OverrideFunc<'MuiLink'> = theme => ({
  styleOverrides: {
    root: {
      ...AppStyle.text(theme),
      textAlign: 'center',
      alignItems: 'center',
    },
  },
})
