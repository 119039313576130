import { AxiosErrorModel } from '../../model/Axios'
import {
  checkHttpClientError,
  checkHttpServerError,
  HttpClientError,
  HttpServerError,
  HttpStatus,
  HttpUnknownError,
} from '../../model/Http'
import { mainStoreInstance } from '../../store/main.store'
import { HelperAxios } from './helper.axios'
import { maintenanceStoreInstance } from '../../feature/maintenance/store/maintenance.store'
import { AppLogger } from '../logger'

export async function handleAxiosError(error: AxiosErrorModel) {
  try {
    const status = HelperAxios.getStatusCode(error?.response)
    const errorMessage = HelperAxios.getErrorMessage(error)
    const isYohrs = HelperAxios.isYohrsResponse(error.response)

    const isMaintenanceError = status === HttpStatus.SERVICE_UNAVAILABLE && isYohrs
    const isBlockedUserError = status === HttpStatus.FORBIDDEN && isYohrs && errorMessage.includes('You are blocked')
    const isKnownClientError = checkHttpClientError(status)
    const isKnownServerError = checkHttpServerError(status)
    const isUnknownAxiosError = errorMessage === 'Network Error'

    if (isBlockedUserError) {
      await mainStoreInstance.reset()
    } else if (isMaintenanceError) {
      maintenanceStoreInstance.changeIsMaintenance(true)
    } else if (isKnownClientError) {
      throw new HttpClientError(errorMessage)
    } else if (isKnownServerError) {
      throw new HttpServerError(errorMessage)
    } else if (isUnknownAxiosError) {
      throw new HttpUnknownError('Unknown server error')
    } else {
      throw new Error('Unknown axios error')
    }

    throw new HttpClientError(errorMessage)
  } catch (e: any) {
    AppLogger.error(e)

    return Promise.reject(e)
  }
}
