import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { MetricWithValue } from './MetricWithValue'
import { useEffect } from 'react'
import { useDashboardStore } from '../store/dashboard.store'
import { Line, LineChart, ResponsiveContainer } from 'recharts'
import { Fade, Theme, Tooltip, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'

export type UserCardsProps = {
  levelId: string
  userId: string
}

export const UserCards = observer(({ levelId, userId }: UserCardsProps) => {
  const { translation } = useLocalizationStore()
  const translations = translation.pages.myTeam
  const navigate = useNavigate()
  const dashboardStore = useDashboardStore()

  useEffect(() => {
    dashboardStore.getUserOverview(levelId, userId)
    dashboardStore.getUserStatus(userId)
  }, [levelId, userId])

  return (
    <Fade in={true} timeout={500} mountOnEnter unmountOnExit>
      <AppPaper key={levelId} sx={styles.root} onClick={() => navigate(appRoutePath.administratorUserDetails(userId))}>
        <Tooltip title={dashboardStore.userOverview?.[userId]?.userName} arrow>
          <Typography style={{ fontSize: '1.3rem', fontWeight: 500, maxWidth: '15rem' }} noWrap>{dashboardStore.userOverview?.[userId]?.userName}</Typography>
        </Tooltip>
        <AppBox sx={styles.metricsWrapper}>
          <MetricWithValue size='small' label={translations.metricsHeader.totalCourses()} value={dashboardStore.userOverview?.[userId]?.TotalCourses || 0} />
          <MetricWithValue size='small' label={translations.metricsHeader.coursesGiven()} value={dashboardStore.userOverview?.[userId]?.CoursesGiven || 0} />
          <MetricWithValue size='small' label={translations.metricsHeader.completedCourses()} value={dashboardStore.userOverview?.[userId]?.CompletedCourses || 0} />
          <MetricWithValue size='small' label={translations.metricsHeader.ongoingCourses()} value={dashboardStore.userOverview?.[userId]?.InProgressCourses || 0} />
          <ResponsiveContainer style={{ position: 'absolute' }} width="100%" height="100%">
            <LineChart data={dashboardStore.userStatus?.[userId]}>
              <Line
                type="bump"
                dataKey="value"
                stroke="#8884d8"
                strokeWidth={1}
                isAnimationActive={true}
                animationBegin={0}
                animationDuration={1500}
                animationEasing="ease-in-out"
                opacity={0.2}
              />
            </LineChart>
          </ResponsiveContainer>
        </AppBox>
      </AppPaper>
    </Fade>
  )
})

const styles = {
  root: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 15,
    padding: '2em 0.5em 1em 0.5em',
    borderRadius: '12px',
    transition: 'transform 1s ease, border-color 0.3s ease',
    '&:hover': {
      borderColor: `${theme.palette.primary.main}60`,
      transform: 'scale(1.02)',
    },
    cursor: 'pointer',
    maxWidth: '25em',
  }),
  metricsWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)', lg: 'repeat(4, 1fr)' },
    gap: 1,
    position: 'relative',
  },
}
