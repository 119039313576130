import { AppBox } from '../../common/analog/molecule/AppBox'
import { handlePassedSx } from '../../../feature/theme/model/Sx'
import { NotOverridableComponentProps } from '../../../model/Component'
import { SxHelperStyle } from '../../../feature/theme/style/sx-helper.style'

export type MainProps = NotOverridableComponentProps

export function Main(props: MainProps) {
  const { sx = [], ...rest } = props

  return (
    <AppBox
      sx={[
        styles,
        ...handlePassedSx(sx),
      ]}
      {...rest}
      component={'main'}
    />
  )
}

const styles = {
  ...SxHelperStyle.flexColumn,
  width: 1,
}
