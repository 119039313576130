import { companyLinks } from '../../../util/link'

import { Name } from '../../localization/model/Text'
import { LinkType } from '../../../model/Link'
import { NewTabLink } from '../../../component/common/analog/molecule/link/NewTabLink'
import { Image } from '../../image/component/Image'
import FacebookIcon from 'src/feature/image/asset/icon/facebook-transparent.svg'
import InstagramIcon from 'src/feature/image/asset/icon/instagram-transparent.svg'
import LinkedinIcon from 'src/feature/image/asset/icon/linkedin-transparent.svg'
import MailIcon from 'src/feature/image/asset/icon/mail-transparent.svg'
import { Theme } from '@mui/material'
import { AppListItem } from '../../../component/common/analog/molecule/list/AppListItem'
import { AppStack } from '../../../component/common/analog/molecule/list/AppStack'
import { Id } from '../../../model/Id'
import { PathToPublicFolder } from '../../../model/Path'
import { SxHelperStyle } from '../../theme/style/sx-helper.style'
import { CONTACT_EMAIL } from 'src/util/constant'

interface SocialItem {
  name: Name
  link: LinkType
  icon: PathToPublicFolder
}

interface Socials {
  [key: Id]: SocialItem
}

const socials: Socials = {
  facebook: {
    name: 'Facebook', link: companyLinks.socials.facebook, icon: FacebookIcon,
  },
  instagram: {
    name: 'Instagram', link: companyLinks.socials.instagram, icon: InstagramIcon,
  },
  linkedin: {
    name: 'Linkedin', link: companyLinks.socials.linkedin, icon: LinkedinIcon,
  },
  mail: {
    name: 'E-post', link: `mailto:${CONTACT_EMAIL}`, icon: MailIcon,
  },
}

export function SocialIcons() {
  return (
    <AppStack sx={styles.root}>
      {Object.entries(socials).map(([id, item]) => (
        <AppListItem key={id} sx={styles.item}>
          <NewTabLink link={item.link}>
            <Image src={item.icon} alt={item.name} />
          </NewTabLink>
        </AppListItem>
      ))}
    </AppStack>
  )
}

const styles = {
  root: ({ handleCssUnit }: Theme) => ({
    ...SxHelperStyle.flexCenterX,
    gap: handleCssUnit(24),
    mb: {
      xs: 24,
      md: 0,
    },
  }),
  item: ({ handleCssUnit }: Theme) => ({
    ...SxHelperStyle.flexCenter,
    width: handleCssUnit(25),
  }),
}
