import { ListItem, ListItemProps } from '@mui/material'
import { AppBox } from '../AppBox'
import { NotOverridableComponentProps } from '../../../../../model/Component'

export type AppListItemProps = NotOverridableComponentProps<ListItemProps>

export function AppListItem(props: AppListItemProps) {
  const { component, ...rest } = props

  return (
    <AppBox
      component={(listItemProps: ListItemProps) => <ListItem {...listItemProps} component={component} /> }
      p={0}
      {...rest}
    />
  )
}
