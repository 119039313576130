import { SidebarProps } from '../model/Sidebar'
import { BaseSidebar } from './BaseSidebar'
import { DropdownNav } from '../../header/component/original/template/DropdownNav'
import { ChildrenProps } from '../../../model/React'

export type MobileSidebarProps = SidebarProps & ChildrenProps

export function MobileSidebar(props: MobileSidebarProps) {
  return (
    <BaseSidebar {...props.baseSidebarProps}>

      {props.nav && (
        <DropdownNav nav={props.nav!} />
      )}

      {props.children}
    </BaseSidebar>
  )
}
