import { StackProps } from '@mui/material/Stack'
import { handlePassedSx } from '../../../../../feature/theme/model/Sx'
import { NotOverridableComponentProps } from '../../../../../model/Component'
import { AppBox } from '../AppBox'
import { forwardRef } from 'react'

export type AppStackProps = NotOverridableComponentProps<StackProps>

export const AppStack = forwardRef((props: AppStackProps, ref) => {
  const { sx = [], ...rest } = props

  return (
    <AppBox
      direction={'row'}
      p={0}
      m={0}
      sx={[
        styles,
        ...handlePassedSx(sx),
      ]}
      {...rest}
      ref={ref}
      isStack
      component={'ul' as React.ElementType}
    />
  )
})

AppStack.displayName = 'AppStack'

const styles = {
  listStyle: 'none',
}
