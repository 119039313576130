import { combineDecorators } from '../../../../model/Decorator'
import lodashDefaultsDeep from 'lodash/defaultsDeep'
import { GenericStore } from '../generic.store'
import {
  CatchFinallyCustom,
  defaultOptions,
  MethodAllProps,
  MethodOptions,
  TryBeginningCustom,
} from './decorator.helper'
import { TryEndingAsync } from '../../../decorator/method/try-ending-async'

/*
  The "GenericStoreAsyncMethod" decorator was created for reducing "GenericStore" boilerplate

  // from something like:

  async method() {
    try {
      this._success = null
      this.startLoading()
      // your async code
      this._success = true
    } catch (e) {
      AppLogger.error(e)
      this._success = false
      throw e
    } finally {
      this.endLoading()
    }
  }

   // to:

  async method() {
    // your code
  }
 */

function TryEndingAsyncCustom<T extends GenericStore>() {
  return TryEndingAsync<T>(({ context, propertyKey }) => {
    context.setError({
      value: null,
      callerMethodName: propertyKey,
    })

    context.setSuccess({
      value: true,
      callerMethodName: propertyKey,
    })
  })
}

export function GenericStoreAsyncMethod<T extends GenericStore = GenericStore>(options: MethodOptions = defaultOptions) {
  const settedOptions: MethodAllProps = lodashDefaultsDeep(options, defaultOptions)

  return combineDecorators(
    TryBeginningCustom<T>(settedOptions),
    TryEndingAsyncCustom<T>(),
    CatchFinallyCustom<T>(settedOptions),
  )
}
