import { baseLocale, detectLocale, i18nObject } from '../util/i18n/i18n-util'
import { Locales, TranslationFunctions } from '../util/i18n/i18n-types'
import { createStore } from '../../../util/mobx/generic-store/store-creator'
import {
  documentCookieDetector,
  htmlLangAttributeDetector,
  localStorageDetector,
  queryStringDetector,
  sessionStorageDetector,
} from 'typesafe-i18n/detectors'
import { loadLocaleAsync } from '../util/i18n/i18n-util.async'
import { GenericStoreAsyncMethod } from '../../../util/mobx/generic-store/decorator/async-method.decorator'
import { GenericStore } from '../../../util/mobx/generic-store/generic.store'
import { LocalizedStringFn } from '../model/Text'
import { isStr } from '../../../model/String'

export class LocalizationStore extends GenericStore {
  locale!: Locales
  translation!: TranslationFunctions

  constructor() {
    super('LocalizationStore')

    super.observe(this)
    this.reset()
    super.persist({ encrypt: false, excludedProperties: ['translation'] })
  }

  reset() {
    this.initLocale()
    this.setText(this.locale)
  }

  get isLocale() {
    return Boolean(this.locale)
  }

  get currentOrBaseLocal() {
    return this.locale || baseLocale
  }

  setText(locale: Locales) {
    this.translation = i18nObject(locale)
  }

  getErrorMessage(text: LocalizedStringFn) {
    let errorMessage: string

    try {
      errorMessage = text()

      if (!isStr(errorMessage)) {
        throw new Error()
      }
    } catch (e) {
      try {
        errorMessage = this.translation.util.unknownErrorOccurred()

        if (!isStr(errorMessage)) {
          throw new Error()
        }
      } catch (e) {
        errorMessage = 'An unknown error has occurred. Contact us and we will fix it.'
      }
    }

    return errorMessage
  }

  initLocale() {
    if (!this.isLocale) {
      this.locale = this.detectedLocale
    }
  }

  @GenericStoreAsyncMethod()
  async changeLocale(locale: Locales) {
    await loadLocaleAsync(locale)
    this.locale = locale
    this.setText(locale)
  }

  @GenericStoreAsyncMethod()
  async resetLocale() {
    await this.changeLocale(this.currentOrBaseLocal)
  }

  @GenericStoreAsyncMethod()
  async withTranslatedError(func: () => Promise<void>, errorFunc: (errorMessage: string) => void) {
    try {
      await func()
    } catch (e: any) {
      let errorMessage = e.message || 'errorUnknownType'

      // Check if message can be translated
      const translatedErrorMessage = Object.entries(this.translation.errorMessages).find(k => k[0] === errorMessage)

      if (translatedErrorMessage) {
        errorMessage = translatedErrorMessage[1]()
      }

      errorFunc(errorMessage)
    }
  }

  private get detectedLocale() {
    return detectLocale(...this.detectors)
  }

  private get customDetectors() {
    return [
      () => [this.currentOrBaseLocal],
    ]
  }

  private get defaultDetectors() {
    return [htmlLangAttributeDetector, queryStringDetector, localStorageDetector, documentCookieDetector, sessionStorageDetector]
  }

  private get detectors() {
    return [
      ...this.customDetectors,
      ...this.defaultDetectors,
    ]
  }
}

export const {
  storeInstance: localizationStoreInstance,
  useStore: useLocalizationStore,
  StoreProvider: LocalizationStoreProvider,
} = createStore(new LocalizationStore())
