import { APP_FONT_SIZE } from '../util/theme.constant'
import lodashIsNumber from 'lodash/isNumber'
import { isStr } from '../../../model/String'

export type RemUnit = `${number}rem`

export type EmUnit = `${number}em`

export type PxStrUnit = `${number}px`

export type PercentageUnit = `${number}%`

export type PxNumUnit = number

export type BeforeConvertingToRemPxUnit = number

export type CssUnit = RemUnit | EmUnit | PxStrUnit | PercentageUnit | PxNumUnit | BeforeConvertingToRemPxUnit | string

export function getEm(fontSize: BeforeConvertingToRemPxUnit, value: BeforeConvertingToRemPxUnit): EmUnit {
  return `${value / fontSize}em`
}

export function pxToRem(px: BeforeConvertingToRemPxUnit): RemUnit {
  return `${px / APP_FONT_SIZE}rem`
}

export function handleCssUnit(value: CssUnit): CssUnit {
  switch (true) {
  case checkIsBeforeConvertingPxToRem(value):
    return pxToRem(value as BeforeConvertingToRemPxUnit)
  case checkIsAbsoluteUnit(value):
    throw new Error('You should not use absolute css unit values, because all styles should be flexible. Use relative values or use px as number instead (last one will be converted to rem).')
  case isUnintlessStr(value):
    throw new Error('Invalid css unit value. It should not be a unitless string without unit.')
  default:
    return value
  }
}

export const absoluteUnits = ['cm', 'mm', 'Q', 'in', 'pc', 'pt', 'px']

export function checkIsAbsoluteUnit(value: unknown): boolean {
  if (!isStr(value)) {
    return false
  }

  return absoluteUnits.some(item => value.includes(item))
}

export function isUnintlessStr(value: unknown) {
  if (!isStr(value)) {
    return false
  }

  const numberLike = Number(value.trim())

  return Number.isFinite(numberLike)
}

export function checkIsBeforeConvertingPxToRem(value: unknown): value is BeforeConvertingToRemPxUnit {
  return lodashIsNumber(value)
}
