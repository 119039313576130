import { Main } from '../template/Main'
import { ChildrenProps } from '../../../model/React'
import { Aside } from '../template/Aside'
import { AppFlexContainer } from '../../common/analog/molecule/list/AppFlexContainer'

export interface WithSidebarLayoutProps extends ChildrenProps {
  sidebar?: React.ReactNode
}

export function WithSidebarLayout(props: WithSidebarLayoutProps) {
  return (
    <AppFlexContainer
      component={null}
      sx={styles.container}
      spacing={{
        xs: 32,
        md: 64,
      }}
    >
      <Aside
        isFlexible
        item
        sx={styles.aside}
      >
        {props.sidebar}
      </Aside>

      <Main
        isFlexible
        item
        sx={styles.main}
      >
        {props.children}
      </Main>
    </AppFlexContainer>
  )
}

const styles = {
  container: {
    flexWrap: 'no-wrap',
    mx: 'auto',
    mt: 0,
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
  },
  aside: {
    flex: {
      xs: '1',
      md: '0 30% !important',
    },
  },
  main: {
    flex: {
      xs: '1',
      md: '1 0px !important',
    },
  },
}
