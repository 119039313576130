import { createStore } from '../../../util/mobx/generic-store/store-creator'
import { GenericStoreAsyncMethod } from '../../../util/mobx/generic-store/decorator/async-method.decorator'
import { GenericStore } from '../../../util/mobx/generic-store/generic.store'
import { LoginSettings } from '../model/LoginSettings'
import { getLoginSettings } from '../api/login'
import { OrganisationLevel } from '../model/OrganisationLevel'
import { deleteOrganisationLevel, getOrganisationCoursePlanItem, getOrganisationLevels, postOrganisationCoursePlanItem, postOrganisationLevel } from '../api/organisation'
import { OrganisationCoursePlanItem } from '../model/OrganisationCoursePlanItem'
import { NewOrUpdatedOrganisationLevel } from '../model/NewOrUpdatedOrganisationLevel'

export class B2bStore extends GenericStore {
  loginSettings: LoginSettings | undefined
  organisationLevels: OrganisationLevel[] = []
  courseOrganisationSettings: OrganisationCoursePlanItem | undefined

  constructor() {
    super('B2bStore')

    super.observe(this)
    this.reset()
    super.persist({ encrypt: false })
  }

  reset() {
    this.loginSettings = undefined
    this.organisationLevels = []
    this.courseOrganisationSettings = undefined
  }

  @GenericStoreAsyncMethod()
  async handleLoadSettings(id: string) {
    this.loginSettings = await getLoginSettings(id)
  }

  @GenericStoreAsyncMethod()
  async handleLoadOrganisationLevels() {
    this.organisationLevels = await getOrganisationLevels()
  }

  @GenericStoreAsyncMethod()
  async loadCourseOrganisationPlanItem(courseId: string) {
    this.courseOrganisationSettings = await getOrganisationCoursePlanItem(courseId)
  }

  @GenericStoreAsyncMethod()
  async saveCourseOrganisationPlanItem(planItem: OrganisationCoursePlanItem | null, courseId: string) {
    await postOrganisationCoursePlanItem(planItem, courseId)
    this.courseOrganisationSettings = planItem !== null ? planItem : undefined
  }

  @GenericStoreAsyncMethod()
  async saveOrganisationLevel(level: NewOrUpdatedOrganisationLevel) {
    await postOrganisationLevel(level)
  }

  @GenericStoreAsyncMethod()
  async removeOrganisationLevel(id: string) {
    await deleteOrganisationLevel(id)
  }
}

export const {
  storeInstance: b2bStoreInstance,
  useStore: useB2bStore,
  StoreProvider: B2bStoreProvider,
} = createStore(new B2bStore())
