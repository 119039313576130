import { useLocalizationStore } from '../../../localization/store/localization.store'
import { AppTextField, AppTextFieldProps } from '../analog/AppTextField'
import { observer } from 'mobx-react-lite'
import SearchIcon from 'src/feature/image/asset/icon/search.svg'
import { Image } from '../../../image/component/Image'
import { Theme } from '@mui/material'

export type AppSearchFieldProps = AppTextFieldProps

export const SearchField = observer((props: AppSearchFieldProps) => {
  const { translation } = useLocalizationStore()
  const { placeholder = translation.util.search, alt = translation.imgAlt.search, children, ...rest } = props

  return (
    <AppTextField
      name="search"
      fz={18}
      placeholder={placeholder}
      InputProps={{
        sx: styles.field,
        endAdornment: (
          <Image
            src={SearchIcon}
            alt={alt}
          />
        ),
      }}
      {...rest}
    />
  )
})

const styles = {
  field: ({ palette }: Theme) => ({
    border: `0.119em solid ${palette.line}`,
    backgroundColor: palette.background.default,
    py: '0.7em',
  }),
}
