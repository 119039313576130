import { Locales } from '../../localization/util/i18n/i18n-types'
import { AnyObject } from '../../../model/Object'
import { contentfulClient } from '../util/contentful-client'
import { Id } from '../../../model/Id'

export type ContentfulBaseLocalizedRequest = {
  locale: Extract<Locales, 'en-US' | 'sv-SE'>
} & AnyObject

export interface GetLocalizedEntryRequest {
  id: Id
  params: ContentfulBaseLocalizedRequest
}

export type GetLocalizedEntryListRequest = ContentfulBaseLocalizedRequest & { tags?: string[] }

export async function getLocalizedEntry<T>(request: GetLocalizedEntryRequest) {
  return contentfulClient.getEntry<T>(request.id, (request.params))
}

export async function getLocalizedEntryList<T>(request: GetLocalizedEntryListRequest) {
  return contentfulClient.getEntries<T>(request)
}
