import { useState } from 'react'
import { Theme } from '@mui/material'
import { Image } from '../../../image/component/Image'
import { observer } from 'mobx-react-lite'
import { useLocalizationStore } from '../../store/localization.store'
import { allLocalesData } from '../../model/Locale'
import { Locales } from '../../util/i18n/i18n-types'
import { useELearningStore } from '../../../e-learning/store/e-learning.store'
import { AppIconButton } from '../../../../component/common/analog/molecule/button/AppIconButton'
import { AppMenu } from '../../../../component/common/analog/molecule/list/AppMenu'
import { AppMenuItem } from '../../../../component/common/analog/molecule/list/AppMenuItem'
import { AppListItemIcon } from '../../../../component/common/analog/molecule/list/AppListItemIcon'
import { Info } from '../atom/Info'
import { handlePassedSx } from '../../../theme/model/Sx'
import { SxProps } from '@mui/system'
import { getConditionalObjProps } from '../../../../model/Object'

interface Props {
  buttonSx?: SxProps<Theme>
}

export const LocalesButton = observer((props: Props) => {
  const [isOpen, setIsOpen] = useState<HTMLElement | null>(null)
  const localization = useLocalizationStore()
  const eLearningStore = useELearningStore()

  const { buttonSx } = props
  const currentLocaleData = allLocalesData[localization.locale]

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setIsOpen(event.currentTarget)
    eLearningStore.startLoading()
  }

  const handleClose = () => setIsOpen(null)

  const handleChangeLocale = async (selectedLocale: Locales) => {
    await localization.changeLocale(selectedLocale)
    handleClose()
  }

  return (
    <>
      <AppIconButton
        onClick={handleOpen}
        sx={[
          styles.button(isOpen),
          ...handlePassedSx(buttonSx),
        ]}
      >
        <Image
          src={currentLocaleData.icon}
          alt={currentLocaleData.endonym}
          outerProps={{
            sx: styles.imageRoot,
          }}
        />
      </AppIconButton>

      <AppMenu
        open={Boolean(isOpen)}
        anchorEl={isOpen}
        onClose={handleClose}
        sx={styles.popup}
        PaperProps={{
          sx: styles.paper,
        }}
        MenuListProps={{
          spacing: 10,
        }}
      >
        {Object.entries(allLocalesData).map(([locale, value]) => (
          <AppMenuItem
            key={locale}
            selected={localization.locale === locale}
            onClick={() => handleChangeLocale(locale as Locales)}
            sx={styles.listItem}
          >
            <AppListItemIcon sx={styles.listItemIcon}>
              <Image
                alt={value.endonym}
                src={value.icon}
                outerProps={{
                  sx: styles.imageRoot,
                }}
              />
            </AppListItemIcon>

            <Info textValue={value.endonym} />
          </AppMenuItem>
        ))}
      </AppMenu>
    </>
  )
})

const styles = {
  paper: ({ handleCssUnit }: Theme) => ({
    my: 10,
    boxShadow: `rgb(145 158 171 / 20%) 0px 0px ${handleCssUnit(2)} 0px, rgb(145 158 171 / 20%) ${handleCssUnit(-20)} ${handleCssUnit(20)} ${handleCssUnit(40)} ${handleCssUnit(-4)}`,
  }),
  button: (isOpen: unknown) => ({
    fontSize: '1rem',
    width: '1.6em',
    height: '1.1em',
    padding: 0,
    margin: 0,
    ...getConditionalObjProps({ if: isOpen, props: { backgroundColor: 'action.selected' } }),
  }),
  imageRoot: {
    borderRadius: '0.15em',
    overflow: 'hidden',
    height: 1,
    width: 1,
  },
  popup: ({ handleCssUnit, palette }: Theme) => ({
    fontSize: '1rem',
    margin: 0,
    padding: 8,
    boxShadow: `rgb(146 158 171 / 10%) 0px 0px ${handleCssUnit(2)} 0px, rgb(145 158 171 / 8%) ${handleCssUnit(-20)} ${handleCssUnit(20)} ${handleCssUnit(40)} ${handleCssUnit(-4)}`,
    border: `${handleCssUnit(1)} solid ${palette.grey[0]}`,
  }),
  listItem: ({ palette }: Theme) => ({
    backgroundColor: 'transparent',
    borderRadius: '0.4em',
    padding: '0.45em',
    '&:hover': {
      backgroundColor: palette.grey[0],
    },
  }),
  listItemIcon: {
    height: '1.5em',
    mr: '0.6em',
    fontSize: '1em',
    width: '2em',
  },
}
