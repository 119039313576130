import { observer } from 'mobx-react-lite'
import { Box, Collapse, Typography } from '@mui/material'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { useSkillAssessmentStore } from '../../skill-assessment/store/skill-assessment.store'
import { useEffect, useState } from 'react'
import { SkillSet } from '../../skill-assessment/model/skill-assessment-model'
import { useDashboardStore } from '../store/dashboard.store'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { AppCircularRating } from 'src/component/common/analog/molecule/AppCircularRating'

export type UserSkillGapAnalysisProps = {
  userId: string
}

export const UserSkillGapAnalysis = observer(({ userId }: UserSkillGapAnalysisProps) => {
  const { translation, locale } = useLocalizationStore()
  const translations = translation.pages.myTeam
  const skillAssessmentStore = useSkillAssessmentStore()
  const dashboardStore = useDashboardStore()
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    skillAssessmentStore.reset()
    skillAssessmentStore.getSkillSets(userId).then(() => {
      if (skillAssessmentStore.skillSets && skillAssessmentStore.skillSets?.skills?.length !== 0) {
        setOpen(true)
      } else {
        setOpen(false)
      }
    })
  }, [])

  const checkIfAllSkillsAreRated = () => {
    const allSkillsRated: boolean = skillAssessmentStore?.skillSets?.skills?.every(skill => skill.selectedSetLevel !== null) ?? false
    
    return allSkillsRated
  }

  return (
    <>
      <Collapse in={open} timeout={400} mountOnEnter unmountOnExit>
        <Box sx={styles.root}>
          <AppBox sx={{ flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 500, fontSize: '2.5em' }}>{`${dashboardStore.userOverview?.[userId]?.userName}'s ${translations.skillAndGapAnalysisHeader()}`}</Typography>
            <Typography
              sx={{ width: { xs: '100%', md: '85%', lg: '70%' }, textAlign: 'center', color: 'text.secondary', hyphens: 'auto', overflowWrap: 'break-word' }}
              lang={locale}
            >{translations.skillAndGapAnalysisDesc()}</Typography>
          </AppBox>
          {skillAssessmentStore.skillSets && <AppBox sx={styles.ratingWrapper}>
            {skillAssessmentStore.skillSets?.skills?.map((skill: SkillSet, index: number) => (
              <AppCircularRating
                key={skill.id}
                indexKey={index}
                userRating={skill.selectedSetLevel}
                expectedRating={skill.expected_set_level}
                label={skill.setName}
                showRating={checkIfAllSkillsAreRated()}
                viewLabel='ManagerUserView'
              />
            ))}
          </AppBox>}
          {!checkIfAllSkillsAreRated() && <Typography sx={{ textAlign: 'center', color: 'text.secondary' }}>
            {`${dashboardStore.userOverview?.[userId]?.userName}'s ${translations.userNotRatedSkills()}`}
          </Typography>}
        </Box>
      </Collapse>
      <Collapse in={!open} timeout={400} mountOnEnter unmountOnExit>
        <Typography sx={{ textAlign: 'center', color: 'text.secondary' }}>
          {`${dashboardStore.userOverview?.[userId]?.userName}'s ${translations.managerNotSetSkills()}`}
        </Typography>
      </Collapse>
    </>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2rem',
    padding: { xs: '0 1rem', md: '0 3rem' },
  },
  progressWrapper: {
    transition: 'transform 0.3s',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)',
    },
  },
  ratingWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(5, 1fr)' },
    justifyContent: 'center',
    rowGap: '1.5rem',
    columnGap: '3rem',
  },
  circleContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '9em',
  },
  circleExpectedPosition: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  circleSelectedPosition: {
    top: 12,
    left: 12,
    bottom: 12,
    right: 12,
  },
  wordWrapper: {
    textAlign: 'center',
    wordWrap: 'break-word',
    fontWeight: '500',
    width: '100%',
  },
}