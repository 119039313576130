import { AppExternalLink, AppExternalLinkProps } from './AppExternalLink'
import { Email } from '../../../../../model/Email'

export type EmailLinkProps = AppExternalLinkProps & {
  email: Email
}

export function EmailLink(props: EmailLinkProps) {
  const { email, ...rest } = props

  return (
    <AppExternalLink
      href={`mailto:${email}`}
      {...rest}
    />
  )
}
