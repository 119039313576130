import { handleFulfilledAxiosRequest } from './handlers/fulfilled-request.axios'
import { handleRejectedAxiosRequest } from './handlers/rejected-request.axios'
import { handleFulfilledAxiosResponse } from './handlers/fulfilled-response.axios'
import { handleRejectedResponse } from './handlers/rejected-response.axios'
import { AxiosInstance } from 'axios'

export function applyInterceptors(instance: AxiosInstance) {
  instance.interceptors.request.use(
    handleFulfilledAxiosRequest,
    handleRejectedAxiosRequest,
  )

  instance.interceptors.response.use(
    handleFulfilledAxiosResponse,
    handleRejectedResponse,
  )
}
