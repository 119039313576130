import { useState, useEffect } from 'react'
import { getNodeSizes, Sizes } from '../../model/DOM'

const getViewportSizes = () => getNodeSizes(document.documentElement)

export function useViewportSizes(subscribe = false): Sizes {
  const [size, setSize] = useState<Sizes>(getViewportSizes())

  const handleSetSizes = () => setSize(getViewportSizes())

  useEffect(() => {
    handleSetSizes()

    if (subscribe) {
      document.addEventListener('resize', handleSetSizes)

      return () => document.removeEventListener('resize', handleSetSizes)
    }
  }, [])

  return size
}
