/* eslint-disable @typescript-eslint/no-explicit-any,no-magic-numbers */
import { FIRST_INDEX } from './constant'
import { AnyObject, checkAreObjKeys } from '../model/Object'
import lodashIsNumber from 'lodash/isNumber'
import { AnyArray } from '../model/Array'
import { isStr } from '../model/String'

export function getEnv(name: string): string {
  const env = process.env[name]

  if (env) {
    return env
  }

  throw new Error(`Cannot find ${name} env variable!`)
}

export function isLength(value: any): boolean {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return value?.length > 0
}

export function isStrOrNum(value: unknown): value is (string | number) {
  return isStr(value) || lodashIsNumber(value)
}

export function getFirstStr(value: unknown): string {
  if (Array.isArray(value)) {
    value = value[FIRST_INDEX]
  }

  return isStr(value) ? value : ''
}

export async function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function isIndexNotFound(index: number): boolean {
  const notFoundIndex = -1

  return index === notFoundIndex
}

export function arrOfObjToObj(arr: any[]) {
  return arr.reduce((prev, curr) => ({ ...prev, ...curr }), {})
}

export function checkIsArrayOrObject(item: unknown): item is (AnyObject | AnyArray) {
  return Array.isArray(item) || checkAreObjKeys(item)
}

export const arrToStr = (arr: unknown[]) => arr.join(',')

export const checkIsStrOrNum = (value: any): value is string | number => ['number', 'string'].includes(typeof value)

export const showRelation = (a: string, b: string) => `${a} / ${b}`

export function getIdentity<T>(value: T): T {
  return value
}

type sortArg<T> = keyof T | `-${string & keyof T}`

/**
 * Returns a comparator for objects of type T that can be used by sort
 * functions, were T objects are compared by the specified T properties.
 *
 * @param sortBy - the names of the properties to sort by, in precedence order.
 *                 Prefix any name with `-` to sort it in descending order.
 */
export function byPropertiesOf<T extends object>(sortBy: Array<sortArg<T>>) {
  function compareByProperty(arg: sortArg<T>) {
    let key: keyof T
    let sortOrder = 1

    if (typeof arg === 'string' && arg.startsWith('-')) {
      sortOrder = -1
      // Typescript is not yet smart enough to infer that substring is keyof T
      key = arg.substr(1) as keyof T
    } else {
      // Likewise it is not yet smart enough to infer that arg here is keyof T
      key = arg as keyof T
    }

    return function(a: T, b: T) {
      const result = a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0

      return result * sortOrder
    }
  }

  return function(obj1: T, obj2: T) {
    let i = 0
    let result = 0
    const numberOfProperties = sortBy?.length

    while (result === 0 && i < numberOfProperties) {
      result = compareByProperty(sortBy[i])(obj1, obj2)
      i++
    }

    return result
  }
}

/**
 * Sorts an array of T by the specified properties of T.
 *
 * @param arr - the array to be sorted, all of the same type T
 * @param sortBy - the names of the properties to sort by, in precedence order.
 *                 Prefix any name with `-` to sort it in descending order.
 */
export function sort<T extends object>(arr: T[], ...sortBy: Array<sortArg<T>>) {
  arr.sort(byPropertiesOf<T>(sortBy))
}