import { ForumModuleProvider } from './component/unique/ForumModuleProvider'
import { getRouteObjectList, withLayout } from '../router/util/router.helper'
import { RouteObjectList } from '../router/model/Route'
import { forumRouteIds } from '../router/model/RouteId'

export const ForumRoutes: RouteObjectList = [
  withLayout({
    layout: <ForumModuleProvider />,
    routes: getRouteObjectList(forumRouteIds),
  }),
]
