import { AppBox, AppBoxProps } from './AppBox'
import { CssUnit } from '../../../../feature/theme/model/CssUnit'

export type AppLazyLoadedProps = AppBoxProps & {
  placeholderSizes?: {
    width: CssUnit
    height: CssUnit
  }
}

export function AppLazyLoaded(props: AppLazyLoadedProps) {
  return (
    <AppBox
      isLazyLoaded
      // width={placeholderSizes.width}
      // height={placeholderSizes.height}
      placeholder={<AppBox />}
      {...props}
    />
  )
}
