/* eslint-disable @typescript-eslint/ban-types */
import { ObjKey } from './Object'

export function combineDecorators(...decorators: any[]) {
  return <TFunction extends Function>(
    target: object | TFunction,
    propertyKey?: string | symbol,
    descriptor?: any,
  ) => {
    for (const decorator of decorators) {
      if (target instanceof Function && !descriptor) {
        decorator?.(target)
        continue
      }
      decorator?.(target, propertyKey, descriptor)
    }
  }
}

export type Context = object | Function

export interface BaseCallbackOptions<T extends Context> {
  propertyKey: ObjKey
  context: T
}
