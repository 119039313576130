import { Author } from 'src/feature/forum/model/Author'
import { axiosInstance } from '../../../util/axios/instance.axios'
import { CompanyPageFile } from '../model/CompanyPageFile'
import { CompanyPageItem } from '../model/CompanyPageItem'
import { Document as CDoc } from '@contentful/rich-text-types'

export const getOrCreateDraft = async (): Promise<CompanyPageItem> => {
  const req = await axiosInstance.request<{
    id: string
    title: string
    mediaUrl?: string
    mediaType?: string
    content: string
    files: CompanyPageFile[]
    published: Date | undefined
    removed: boolean
    author: Author
    isPromo: boolean
    orgLevels: string[]
  }>({
    url: `/company-page/draft`,
  })

  return { ...req.data, content: JSON.parse(req.data.content) as CDoc }
}

export const getItems = async (): Promise<CompanyPageItem[]> => {
  const req = await axiosInstance.request<{
    id: string
    title: string
    mediaUrl?: string
    mediaType?: string
    content: string
    files: CompanyPageFile[]
    published: Date | undefined
    removed: boolean
    author: Author
    isPromo: boolean
    orgLevels: string[]
  }[]>({
    url: `/company-page/item`,
  })

  return req.data.map(d => ({ ...d, content: JSON.parse(d.content) as CDoc }))
}

export const deleteMedia = async (itemId: string): Promise<void> => {
  await axiosInstance.request({
    url: `/company-page/media/${itemId}`,
    method: 'delete',
  })
}

export const postItem = async (itemId: string, title: string, content: string, isPromo: boolean, orgLevels: string[], publish: boolean) => {
  await axiosInstance.request({
    url: `/company-page/item`,
    method: 'post',
    data: {
      id: itemId,
      title,
      content,
      isPromo,
      orgLevels,
      publish,
    },
  })
}

export const deleteItem = async (itemId: string): Promise<void> => {
  await axiosInstance.request({
    url: `/company-page/item/${itemId}`,
    method: 'delete',
  })
}

export async function uploadMediaFile(itemId: string, file: File, reportProgress?: (progress: number) => void) {
  const formData = new FormData()
  formData.append('file', file)
  const req = await axiosInstance.request<CompanyPageFile>({
    url: `/company-page/media/${itemId}/${encodeURIComponent(file.type)}`,
    method: 'post',
    headers: {
      contentType: 'multipart/form-data',
    },
    data: formData,
    onUploadProgress: (e: ProgressEvent) => {
      if (reportProgress) {
        const precentage = Math.floor((e.loaded * 100) / e.total)
        reportProgress(precentage)
      }
    },
  })

  return req.data
}

export async function uploadFile(itemId: string, file: File, reportProgress?: (progress: number) => void) {
  const formData = new FormData()
  formData.append('file', file)
  const req = await axiosInstance.request<CompanyPageFile>({
    url: `/company-page/file/${itemId}/${encodeURIComponent(file.type)}`,
    method: 'post',
    headers: {
      contentType: 'multipart/form-data',
    },
    data: formData,
    onUploadProgress: (e: ProgressEvent) => {
      if (reportProgress) {
        const precentage = Math.floor((e.loaded * 100) / e.total)
        reportProgress(precentage)
      }
    },
  })

  return req.data
}

export const deleteFile = async (fileId: string): Promise<void> => {
  await axiosInstance.request({
    url: `/company-page/file/${fileId}`,
    method: 'delete',
  })
}