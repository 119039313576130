import { breakpoints } from './breakpoints'
import { shape } from './shape'
import { spacing } from './spacing'
import { createTheme } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'
import { basePalette } from './palette/base-palette'
import { baseTypography } from './typography/base-typography'

const defaultTheme = createTheme()

export const baseThemeOptions = deepmerge(defaultTheme, {
  typography: baseTypography,
  breakpoints,
  palette: basePalette,
  shape,
  spacing,
})
