import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { PageTitle } from '../../../localization/component/atom/PageTitle'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, Document } from '@contentful/rich-text-types'
import { Image } from '../../../image/component/Image'
import { AppProgressLine } from '../../../../component/common/analog/molecule/loader/AppProgressLine'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Theme } from '@mui/material'
import { AppArticle } from '../../../../component/common/analog/molecule/AppArticle'
import { getEm } from '../../../theme/model/CssUnit'
import { SxHelperStyle } from '../../../theme/style/sx-helper.style'
import { useContentfulStore } from '../../../contentful/store/contentful.store'
import { Id } from '../../../../model/Id'
import { AppButton } from '../../../../component/common/analog/molecule/button/AppButton'
import { useELearningStore } from '../../store/e-learning.store'
import { NoAccess } from './NoAccess'
import { authStore } from 'src/feature/auth/store/auth.store'

export const Platform = observer(() => {
  const { id } = useParams()
  const contentfulStore = useContentfulStore()
  const eLearningStore = useELearningStore()
  const { item } = contentfulStore
  const isDiploma = id === '76hgLUAloFX9AdBXXyrm7V'

  useEffect(() => {
    contentfulStore.handleGetBlogArticle({ id: id as Id })
  }, [contentfulStore.availableLocale])

  useEffect(() => {
    if (isDiploma && !eLearningStore.isELearningUriValid) {
      eLearningStore.handleGetELearningUri()
    }
  }, [id, eLearningStore.eLearningUri])

  if (contentfulStore.isLoading) {
    return <AppProgressLine isShowing={true} />
  }

  if (!contentfulStore.isItemData || !authStore.isAuthorized) {
    return <NoAccess />
  }

  const options = {
    renderNode: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [BLOCKS.EMBEDDED_ASSET]: (_node: any) => {
        return <Image
          src={_node.data?.target?.fields?.file?.url}
          alt={_node.data?.target?.fields?.title}
          outerProps={{
            sx: styles.imageRoot,
          }}
        />
      },
    },
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const data = documentToReactComponents(item.description as Document, options)
  const _onClick = () => {
    if (isDiploma && eLearningStore.eLearningUri) {
      window.open(eLearningStore.eLearningUri)
    } else {
      window.open(item.href)
    }
  }

  return (
    <AppArticle sx={styles.root}>
      <AppBox sx={styles.text}>
        <PageTitle textValue={item.title} mb={0} />

        <div>
          {data}
          <AppButton type="submit" onClick={_onClick} textValue={'Go to platform'} />
        </div>
      </AppBox>

      <Image
        src={item.picture?.fields?.file?.url}
        alt={item.picture?.fields?.file?.fileName}
        outerProps={{
          sx: styles.imageRoot,
        }}
      />

    </AppArticle>
  )
})

const styles = {
  root: ({ handleCssUnit }: Theme) => ({
    display: 'flex',
    gap: handleCssUnit(100),
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
  }),
  date: ({ handleCssUnit }: Theme) => ({
    fontSize: handleCssUnit(14),
    mb: getEm(14, 40),
    fontWeight: 500,
  }),
  text: {
    ...SxHelperStyle.flexColumn,
    flex: 1,
    order: {
      xs: 1,
      md: 0,
    },
  },
  imageRoot: {
    display: 'flex',
    flex: 1,
    alignSelf: 'flex-start',
    order: {
      xs: 0,
      md: 1,
    },
  },
  imageText: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    order: {
      xs: 0,
      md: 1,
    },
    padding: '10 10 10 0',
  },
}
