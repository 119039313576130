import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { AppTextField } from 'src/feature/form/component/analog/AppTextField'
import { AppCheckbox } from 'src/feature/form/component/analog/AppCheckbox'
import { OrganisationRole } from '../model/OrganisationRole'
import { useMemo } from 'react'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export type RoleSelectorProps = {
  label: string
  placeholder: string
  disabled?: boolean
  defaultValue: OrganisationRole
  onChange: (role: OrganisationRole) => void
}

export default function RoleSelector(props: RoleSelectorProps) {
  const options = [
    { key: OrganisationRole.User, text: 'Användare' },
    { key: OrganisationRole.LearningAdministrator, text: 'Learning-administratör' },
    { key: OrganisationRole.UserAdministrator, text: 'Användaradministratör' },
    { key: OrganisationRole.SystemAdministrator, text: 'Systemadministratör' },
    { key: OrganisationRole.ContentAdministrator, text: 'Innehållsadministratör' },
  ]

  return (
    <Autocomplete
      multiple
      id="role-selector"
      sx={{
        '& .MuiAutocomplete-popupIndicator, & .MuiAutocomplete-clearIndicator': {
          color: 'text.primary',
        },
      }}
      fullWidth={true}
      disabled={props.disabled}
      options={options}
      isOptionEqualToValue={(o, v) => o.key === v.key}
      value={useMemo(() => options.filter(l => (props.defaultValue & l.key) === l.key), options)}
      onChange={(_, v) => {
        const value = v.map(o => o.key).reduce((o1, o2) => o1 + o2, 0)
        props.onChange(value)
      }}
      getOptionLabel={option => option.text}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <AppCheckbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
          />
          {option.text}
        </li>
      )}
      renderInput={params => (
        <AppTextField {...params} label={props.label} placeholder={props.placeholder} />
      )}
    />
  )
}