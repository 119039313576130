import { appRoutePath } from '../../../util/app-route-path'
import { RouteStacker } from '../../template/RouteStacker'
import { MaintenanceRoutes } from '../../../../maintenance/MaintenanceRoutes'

export function MaintenanceRoutesStack() {
  return (
    <RouteStacker
      redirectToIfNoFound={appRoutePath.maintenance}
      routes={MaintenanceRoutes}
    />
  )
}
