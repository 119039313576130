import { NotOverridableComponentProps } from '../../../../../model/Component'
import ListItemIcon, { ListItemIconProps } from '@mui/material/ListItemIcon'
import { AppBox } from '../AppBox'

export type AppListItemIconProps = NotOverridableComponentProps<ListItemIconProps>

export function AppListItemIcon(props: AppListItemIconProps) {
  return (
    <AppBox
      {...props}
      component={ListItemIcon}
    />
  )
}
