import { observer } from 'mobx-react-lite'
import { Field, FieldProps, useFormikContext } from 'formik'
import { getFirstStr } from '../../../../util/helper'
import { TranslatedTextField, TranslatedTextFieldProps } from './TranslatedTextField'

export type FormikTextFieldProps = TranslatedTextFieldProps

export const FormikTextField = observer((props: FormikTextFieldProps) => {
  const formik = useFormikContext<any>()

  const {
    keyword,
    type,
    required = true,
    ...rest
  } = props
  const error = formik?.touched?.[keyword] && formik?.errors?.[keyword]
  const helperText = getFirstStr(error)

  return (
    <Field name={keyword}>
      {({ field }: FieldProps) => (
        <TranslatedTextField
          {...field}
          fullWidth
          type={type}
          required={required}
          isError={error}
          helperText={helperText}
          {...rest}
          // below props should be the latest because they shouldn't be overridden (since the purpose of the component in this)
          id={keyword}
          keyword={keyword}
        />
      )}
    </Field>
  )
})
