import { OverrideFunc } from '../../../../model/Override'

export const MuiPaper: OverrideFunc<'MuiPaper'> = ({ border }) => ({
  defaultProps: {
    variant: 'outlined',
    elevation: 0,
  },
  styleOverrides: {
    root: {
      borderRadius: 0,
      flexDirection: 'column',
      border,
      alignItems: 'flex-start',
    },
  },
})
