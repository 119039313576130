import { axiosInstance } from '../../../util/axios/instance.axios'

export const likePost = async (postId: string) => {
  await axiosInstance({
    url: `/posts/${postId}/likes`,
    method: 'post',
  })
}

export const unlikePost = async (postId: string) => {
  await axiosInstance({
    url: `/posts/${postId}/likes`,
    method: 'delete',
  })
}
