import axios, { AxiosError } from 'axios'
import { API_BASE_URL } from '../constant'

axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.validateStatus = () => true // we use it because we found it as the best way to handle all errors in fulfilled response
const instance = axios.create({ baseURL: API_BASE_URL })
instance.interceptors.response.use(undefined, (error: AxiosError) => {
  return Promise.reject(error)
})
export const axiosInstance = instance
