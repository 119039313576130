import {
  TranslatableBox,
  TranslatableBoxProps,
} from '../../../../../feature/localization/component/atom/TranslatableBox'
import MuiLink, { LinkProps } from '@mui/material/Link'
import { handlePassedSx } from '../../../../../feature/theme/model/Sx'
import { Theme } from '@mui/material'

type Underline = 'none' | 'hover' | 'always'

const defaultUnderline = 'hover'

export type AppBaseMuiLinkProps = TranslatableBoxProps & LinkProps & {
  underline?: Underline
}

export function AppBaseMuiLink(props: AppBaseMuiLinkProps) {
  const { sx = [], component, underline = defaultUnderline, ...rest } = props

  return (
    <TranslatableBox
      component={componentProps => <MuiLink {...componentProps} component={component} />}
      underline={underline}
      sx={[
        styles({ underline, disabled: props.disabled }),
        ...handlePassedSx(sx),
      ]}
      {...rest}
    />
  )
}

const styles = ({ underline = defaultUnderline, disabled }: AppBaseMuiLinkProps) => ({ palette }: Theme) => ({
  color: palette.primary.main,
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  ...(disabled && { pointerEvent: 'none', cursor: 'unset' }),
  textDecoration: disabled ? 'none' : underline === 'always' ? 'underline' : ['none', 'hover'].includes(underline) ? 'none' : 'none',
  '&:hover': {
    textDecoration: disabled ? 'none' : underline === 'none' ? 'none' : ['hover', 'always'].includes(underline) ? 'underline' : 'none',
  },
})
