import { AppProgressLine } from '../../../../component/common/analog/molecule/loader/AppProgressLine'
import { appRoutePath } from '../../../router/util/app-route-path'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { AppFlexContainer } from '../../../../component/common/analog/molecule/list/AppFlexContainer'
import { AppFlexItem } from '../../../../component/common/analog/molecule/list/AppFlexItem'
import {
  ContentName,
  useContentfulStore,
} from '../../../contentful/store/contentful.store'
import { PlatformCard } from '../../../../component/common/original/template/PlatformCard'
import { getAssetData } from '../../../contentful/model/Contentful'
import { NoAccess } from './NoAccess'
import { authStore } from 'src/feature/auth/store/auth.store'
import { useUserStore } from 'src/feature/user/store/user.store'

export type PlatformListProps = {
  excludePayed?: boolean
  compactList?: boolean
}

export const PlatformList = observer((props: PlatformListProps) => {
  const contentfulStore = useContentfulStore()
  const userStore = useUserStore()
  useEffect(() => {
    contentfulStore.handleFetchEntryList({
      contentName: ContentName.Elearning,
    })

    userStore.handleUpdateSubscriptions()
  }, [contentfulStore.availableLocale, userStore.subscriptions?.length])

  if (!authStore.isAuthorized) {
    return <NoAccess />
  }

  const sortedList = contentfulStore.list.slice().filter(i => !props.excludePayed || !i.fields.paymentProducts)
    .sort((a, b) => a.fields.priority - b.fields.priority)

  return (
    <>
      {/* <CheckoutForm /> */}
      <AppFlexContainer
        spacing={90}
        height={1}
        columns={{
          xs: 1,
          lg: props.compactList ? 3 : 2,
          md: 2,
          sm: 1,
        }}
      >
        {contentfulStore.isListData && sortedList.map(({ fields, sys }) => (
          <AppFlexItem key={sys.id} xs={1}>
            <PlatformCard
              title={fields.title}
              shortDescription={fields.shortDescription}
              picture={getAssetData(fields?.picture)}
              description={fields.description}
              paymentRequired={fields.paymentProducts}
              hasSubscription={userStore.hasSubscriptionPlan(fields.paymentProducts)}
              path={appRoutePath.platformArticle(sys.id)}
            />
          </AppFlexItem>
        ))}
      </AppFlexContainer>
      {contentfulStore.isLoading && <AppProgressLine isShowing={true} />}
    </>
  )
})
