import { AppStyle } from '../../../style/app.style'
import { APP_FONT_SIZE } from '../../theme.constant'
import { BeforeConvertingToRemPxUnit } from '../../../model/CssUnit'
import { genericTypographyStyles } from './base-typography'
import { Theme } from '@mui/material'
import { deepmerge } from '@mui/utils'
import { getIdentity } from '../../../../../util/helper'

export const h1FontSize = getIdentity<BeforeConvertingToRemPxUnit>(55)

export const h2FontSize = getIdentity<BeforeConvertingToRemPxUnit>(23)

export const h3FontSize = getIdentity<BeforeConvertingToRemPxUnit>(15)

export const h4FontSize = getIdentity<BeforeConvertingToRemPxUnit>(15)

export const body1FontSize = getIdentity<BeforeConvertingToRemPxUnit>(15)

export const subtitle1FontSize = getIdentity<BeforeConvertingToRemPxUnit>(13)

export const captionFontSize = getIdentity<BeforeConvertingToRemPxUnit>(13)

export const overrideTypography = (theme: Theme) => {
  return deepmerge(theme, {
    typography: {
      fontSize: APP_FONT_SIZE,
      // htmlFontSize: APP_FONT_SIZE,
      ...genericTypographyStyles,
      h1: {
        ...genericTypographyStyles,
        ...AppStyle.title,
        fontSize: theme.handleCssUnit(h1FontSize),

        color: theme.palette.common.black,
      },
      h2: {
        ...genericTypographyStyles,
        ...AppStyle.title,
        fontSize: theme.handleCssUnit(h2FontSize),
        color: theme.palette.common.black,
        marginTop: '1em',
        marginBottom: '1em',
      },
      h3: {
        ...genericTypographyStyles,
        ...AppStyle.title,
        fontSize: theme.handleCssUnit(h3FontSize),
        fontWeight: 600,
      },
      h4: {
        ...genericTypographyStyles,
        fontSize: theme.handleCssUnit(17),
        lineHeight: 1.35,
        fontWeight: 500,
        fontStyle: 'normal',
      },
      h5: genericTypographyStyles,
      h6: genericTypographyStyles,
      body1: {
        ...genericTypographyStyles,
        ...AppStyle.text(theme),
        fontSize: theme.handleCssUnit(body1FontSize),
      },
      body2: {
        ...genericTypographyStyles,
        fontSize: theme.handleCssUnit(15),
        fontWeight: 500,
        fontStyle: 'normal',
        lineHeight: 1.48,
        // color: theme.palette.text.secondary,
      },
      subtitle1: {
        ...genericTypographyStyles,
        fontSize: theme.handleCssUnit(subtitle1FontSize),
        marginTop: '0.2em',
        marginBottom: '0.2em',
      },
      subtitle2: {
        ...genericTypographyStyles,
      },
      caption: {
        ...genericTypographyStyles,
        fontSize: theme.handleCssUnit(captionFontSize),
        fontWeight: 400,
        color: '#9DA1AC',
      },
      button: {
        ...genericTypographyStyles,
        textTransform: 'unset',
        lineHeight: 1.34,
        fontSize: theme.handleCssUnit(17),
        fontStyle: 'normal',
      },
      overline: genericTypographyStyles,
    },
  })
}
