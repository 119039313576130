import { Translatable } from '../../model/Text'
import { AppBox, AppBoxProps } from '../../../../component/common/analog/molecule/AppBox'
import { observer } from 'mobx-react-lite'
import { localTranslate } from '../../model/Translate'

export type TranslatableBoxProps = AppBoxProps & {
  textValue?: Translatable | undefined
}

export const TranslatableBox = observer((props: TranslatableBoxProps) => {
  const { textValue, children = null, ...rest } = props

  return (
    <AppBox {...rest}>
      {children || (textValue ? localTranslate(textValue!) : null)}
    </AppBox>
  )
})
