import { Link as RouterLink, LinkProps } from 'react-router-dom'
import { AppBaseLinkKind, AppBaseLinkProps } from './AppBaseLinkKind'

export type AppInternalLinkProps = AppBaseLinkProps & LinkProps

export function AppInternalLink(props: AppInternalLinkProps) {
  const { to, ...rest } = props

  const $to = to || '/'

  return <AppBaseLinkKind component={RouterLink} to={$to} {...rest} />
}
