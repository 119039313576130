import { observer } from 'mobx-react-lite'
import { PublicHeader } from '../molecule/PublicHeader'
import { WithSubscriptionHeader } from '../molecule/WithSubscriptionHeader'
import { HeaderProps } from '../../../model/HeaderProps'
import { ELearningStoreProvider } from '../../../../e-learning/store/e-learning.store'
import { BaseAppHeaderBar } from '../template/BaseAppHeaderBar'
import { useAuthStore } from 'src/feature/auth/store/auth.store'
import { useLocation } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { isOnRoute } from 'src/feature/router/util/router.helper'

export const Header = observer(({ blockLogo }: HeaderProps) => {
  const { isAuthorized } = useAuthStore()
  const location = useLocation()
  const isB2bLogin = isOnRoute(location, appRoutePath.login)
  
  return (
    <ELearningStoreProvider>
      <BaseAppHeaderBar blockLogo={blockLogo}>
        {isAuthorized ? <WithSubscriptionHeader /> : isB2bLogin ? null : <PublicHeader />}
      </BaseAppHeaderBar>
    </ELearningStoreProvider>
  )
})
