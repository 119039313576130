import { handlePassedSx } from '../../../theme/model/Sx'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { NotOverridableComponentProps } from '../../../../model/Component'

export type AppCheckboxProps = NotOverridableComponentProps<CheckboxProps>

export function AppCheckbox(props: AppCheckboxProps) {
  const { sx = [], ...rest } = props

  return (
    <AppBox
      {...rest}
      component={Checkbox}
      sx={[
        ...handlePassedSx(sx),
      ]}
    />
  )
}
