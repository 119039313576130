import { Hamburger } from '../molecule/Hamburger'
import { useBool } from '../../../../../hook/bool/useBool'
import { SwipeableMobileNavDrawer } from './SwipeableMobileNavDrawer'
import { ReactChildren } from '../../../../../model/React'
import { useEffect } from 'react'

export interface MobileHeaderProps {
  children?: ReactChildren,
  pathname?: string
}

export function MobileHeader(props: MobileHeaderProps) {
  const bool = useBool()

  const { pathname } = props
  useEffect(() => {
    bool.makeFalse()
  }, [pathname])

  return (
    <>
      <Hamburger {...bool} />
      <SwipeableMobileNavDrawer {...bool} >
        {props.children}
      </SwipeableMobileNavDrawer>
    </>
  )
}
