import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { RecommendedCategoryCourses } from './RecommendedCategoryCourses'
import { useEffect } from 'react'
import { useELearningStore } from 'src/feature/e-learning/store/e-learning.store'
import { ELearningCourseCategory } from 'src/feature/e-learning/model/ELearningCourseCategory'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'

export const RecommendedCategories = observer(() => {
  const { translation } = useLocalizationStore()
  const translations = translation.pages.myGrowth
  const eLearningStore = useELearningStore()

  useEffect(() => {
    if (eLearningStore.categories === undefined) {
      eLearningStore.loadCategoriesIfNeeded()
    }
  })

  return (
    <>
      <AppPaper sx={styles.bannerWrapper}>
        <Typography sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}>{translations.outOfCoursesHeader()}</Typography>
        <Typography sx={{ width: { xs: '100%', md: '80%', lg: '60%' }, textAlign: 'center' }}>{translations.outOfCoursesDesc()}</Typography>
      </AppPaper>
      {eLearningStore.categories && eLearningStore.categories.slice(0, 3).map((category: ELearningCourseCategory) => <Box key={category.id} sx={{ padding: { xs: '0 2rem', md: '0 6rem', lg: '0 10rem' } }} >
        <RecommendedCategoryCourses category={category.titleLocale} />
      </Box>)}
    </>
  )
})

const styles = {
  bannerWrapper: {
    padding: { xs: '2rem', md: '5rem' },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 15,
    color: 'alternate.light',
    backgroundColor: 'alternate.dark',
  },
}
