import { AxiosErrorModel } from '../../../model/Axios'
import { logAxios } from '../log.axios'
import { HelperAxios } from '../helper.axios'
import { handleAxiosError } from '../handle-error.axios'

export function handleRejectedResponse(error: AxiosErrorModel) {
  logAxios(error)

  const response = error?.response

  if (HelperAxios.shouldRepeatRequest(response)) {
    return HelperAxios.handleRepeatRequest(response)
  }

  return handleAxiosError(error)
}
