import { OverrideFunc } from '../../../../model/Override'

export const MuiTypography: OverrideFunc<'MuiTypography'> = () => ({
  styleOverrides: {
    root: {
      textAlign: 'left',
    },
  },
  defaultProps: {
    variantMapping: {
      subtitle1: 'p',
      subtitle2: 'p',
      caption: 'span',
      body1: 'p',
    },
  },
})
