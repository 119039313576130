import { isDevLike } from '../../stage'
import { checkHttpAnyError } from '../../model/Http'
import { AppLogger } from '../logger'
import { AnyObject, checkAreObjKeys } from '../../model/Object'

export function logAxios(data: AnyObject = {}) {
  if (!isDevLike || !checkAreObjKeys(data)) {
    return
  }

  const messageType = data?.config ? 'RESPONSE' : 'REQUEST'
  const isError = data?.isAxiosError || checkHttpAnyError(data?.status)
  const requestData = data?.config || data
  const method = requestData?.method?.toUpperCase?.() || 'Unknown method'
  const status = isError ? 'error' : 'success'
  const url = requestData?.url

  const logger = isError ? AppLogger.error : AppLogger.info

  logger(`INTERCEPTORS → ${messageType} ${status} → ${method} ${url}:`, { ...data })
}
