import { Palette } from '../../../model/Palette'

export const darkModePalette = {
  line: Palette.BRIGHT_GREY_2,
  get divider() {
    return this.line
  },
  primary: {
    main: Palette.CORAL_REEF_GREEN,
    lighter: Palette.HAN_PURPLE,
    light: Palette.HAN_PURPLE_DARK,
    dark: Palette.BLACK_OUT,
  },
  secondary: {
    main: Palette.LIME_FLASH,
  },
  button: {
    main: Palette.CORAL_REEF_GREEN,
    secondary: Palette.WHITE,
    dark: Palette.WASH_ME,
    alternate: Palette.BLACK_OUT,
  },
  error: {
    main: Palette.DANGER,
  },
  background: {
    default: Palette.ONYX_BLACK,
    grey: Palette.GOSHAWK_GREY,
    paper: Palette.ONYX_BLACK,
    alternate: Palette.HAN_BLUE_LIGHT,
    light: Palette.SLATE_TEAL,
    secondary: Palette.WASH_ME,
    dark: Palette.CHARCOAL_SLATE,
  },
  text: {
    primary: Palette.WHITE,
    secondary: Palette.CHARCOAL_LAVENDER,
    dark: Palette.BLACK_OUT,
    alternate: Palette.SLATE_TEAL,
    light: Palette.GOSHAWK_GREY,
  },
  grey: {
    0: Palette.GOSHAWK_GREY_ALPHA_60,
    0.5: Palette.GOSHAWK_GREY_ALPHA_50,
    1: Palette.GOSHAWK_GREY_ALPHA_40,
    2: Palette.BLACK_OUT_ALPHA_50,
    3: Palette.BLACK_OUT_ALPHA_40,
    4: Palette.BLACK_OUT,
    5: Palette.WHITE,
    6: Palette.CHEFS_HAT,
    7: Palette.WASH_ME,
    8: Palette.BRIGHT_GREY_2,
  },
  alternate: {
    primary: Palette.LIME_FLASH,
    secondary: Palette.BLACK,
    alternate: `${Palette.LIME_FLASH}69`,
    background: Palette.WHITE,
    text: Palette.DEEP_SEA_BLUE,
    dark: Palette.BLACK,
    light: Palette.LIME_FLASH,
    lighter: Palette.ONYX_BLACK,
  },
}
