import { FormikProvider, useFormik } from 'formik'
import { FormikConfig } from 'formik/dist/types'
import { ChildrenProps } from '../../../../model/React'
import { AppForm } from '../../../../component/common/analog/molecule/basic/AppForm'
import { AnyObject } from '../../../../model/Object'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useLocalizationStore } from '../../../localization/store/localization.store'

type FormikProps<T0,
  T1 extends AnyObject,
  T2 extends FormikConfig<T0> = FormikConfig<T0>> =
  ChildrenProps & T1 & {
    onSubmit: T2['onSubmit']
    schema: T2['validationSchema']
    values: T0
  }

// <T0 extends FormikValues = FormikValues, T1 extends AppFormProps = AppFormProps>
// export const AppFormik = observer((props: FormikProps<T0, T1>) => {
export const AppFormik = observer((props: FormikProps<any, any>) => {
  const { values, /* schema, */ onSubmit, ...rest } = props
  const { locale } = useLocalizationStore()

  const formik = useFormik({
    initialValues: values, //    validationSchema: schema,
    onSubmit,
    enableReinitialize: true,
  })

  useEffect(() => {
    formik.setErrors({})
  }, [locale])

  return (
    <FormikProvider value={formik}>
      <AppForm {...formik} {...rest} onSubmit={formik.handleSubmit} onReset={formik.handleReset} />
    </FormikProvider>
  )
})
