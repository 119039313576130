import { observer } from 'mobx-react-lite'
import { Box, Collapse, Fade, Typography } from '@mui/material'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { useSkillAssessmentStore } from '../../skill-assessment/store/skill-assessment.store'
import { useEffect, useState } from 'react'
import { SkillSet } from '../../skill-assessment/model/skill-assessment-model'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { AppCircularRating } from 'src/component/common/analog/molecule/AppCircularRating'

export const SkillGapAnalysis = observer(() => {
  const { translation } = useLocalizationStore()
  const translations = translation.pages.myTeam
  const skillAssessmentStore = useSkillAssessmentStore()
  const navigate = useNavigate()
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    skillAssessmentStore.reset()
    skillAssessmentStore.getSkillSets().then(() => {
      if (skillAssessmentStore.skillSets && skillAssessmentStore.skillSets?.skills?.length !== 0) {
        setOpen(true)
      } else {
        setOpen(false)
      }
    })
  }, [])

  const checkIfAllSkillsAreRated = () => {
    const allSkillsRated: boolean = skillAssessmentStore?.skillSets?.skills?.every(skill => skill.selectedSetLevel !== null) ?? false
    
    return allSkillsRated
  }

  return (
    <Collapse in={open} timeout={500} mountOnEnter unmountOnExit>
      <Box sx={styles.root}>
        <AppBox sx={styles.ratingWrapper}>
          {skillAssessmentStore.skillSets?.skills?.map((skill: SkillSet, index: number) => (
            <AppCircularRating
              key={skill.id}
              indexKey={index}
              userRating={skill.selectedSetLevel}
              expectedRating={skill.expected_set_level}
              label={skill.setName}
              showRating={checkIfAllSkillsAreRated()}
              viewLabel='UserView'
            />
          ))}
        </AppBox>
        <Fade in={!checkIfAllSkillsAreRated()} timeout={700} mountOnEnter unmountOnExit>
          <Typography sx={{ width: { xs: '100%', md: '75%', lg: '60%' }, textAlign: 'center', color: 'text.secondary' }}>{translations.errorMsg.rateAllSkills()}</Typography>
        </Fade>
        <Fade in={checkIfAllSkillsAreRated()} timeout={700} mountOnEnter unmountOnExit>
          <Typography sx={{ width: { xs: '100%', md: '75%', lg: '60%' }, textAlign: 'center', color: 'text.secondary' }}>{translation.pages.myGrowth.skillGapAnalysisFinished()}</Typography>
        </Fade>
        <AppButton fontSize={15} size='small' variant='contained' color="primary" onClick={() => navigate(appRoutePath.skillAssessment)}>{checkIfAllSkillsAreRated() ? translations.rolesButton.updateSkill() : translations.rolesButton.rateSkill()}</AppButton>
      </Box>
    </Collapse>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '3rem',
    padding: { xs: '0 1rem', sm: '0 2rem', lg: '0 3rem' },
  },
  ratingWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(5, 1fr)' },
    justifyContent: 'center',
    rowGap: '1.5rem',
    columnGap: '3rem',
  },
}