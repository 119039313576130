import { getRouteObjectList, withLayout } from '../router/util/router.helper'
import { RouterLayout } from '../router/component/layout/RouterLayout'
import { profileRouteIds } from '../router/model/RouteId'

export const ProfileRoutes = [
  withLayout(
    {
      layout: <RouterLayout />,
      routes: getRouteObjectList(profileRouteIds),
    },
  ),
]
