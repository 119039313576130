import { getRouteObjectList, withLayout } from '../router/util/router.helper'
import { RouteObjectList } from '../router/model/Route'
import { blogRouteIds } from '../router/model/RouteId'
import { RouterLayout } from '../router/component/layout/RouterLayout'

export const BlogRoutes: RouteObjectList = [
  withLayout({
    layout: <RouterLayout />,
    routes: getRouteObjectList(blogRouteIds),
  }),
]
