import { yupInstance } from '../../../util/yup/yup-instance'
import { localizationStoreInstance } from '../../localization/store/localization.store'
import { localTranslate } from '../../localization/model/Translate'
import { autorun } from 'mobx'
import { Translatable } from '../../localization/model/Text'
import { AnyObject } from 'src/model/Object'

// constants
export const PERSONAL_NUMBER_LENGTH = 12

export const MESSAGE_MAX_LENGTH = 255

export const QUESTION_MAX_LENGTH = 1000

export const TEXTAREA_MIN_LENGTH = 3

export const GROSS_SALARY_MAX_LENGTH = 20000

export const PHONE_NUMBER_REGEXP = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/

export const PASSWORD_REGEXP = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/

export let baseStringSchema: AnyObject

export let textSchema: AnyObject

export let textareaSchema: AnyObject

export let basePasswordSchema: AnyObject

export let emailSchema: AnyObject

export let passwordSchema: AnyObject

export let confirmPasswordSchema: AnyObject

export let localitySchema: AnyObject

export let phoneNumberSchema: AnyObject

export let personalNumberSchema: AnyObject

export let messageSchema: AnyObject

export let questionSchema: AnyObject

export let addressSchema: AnyObject

export let zipSchema: AnyObject

export let grossSalarySchema: AnyObject

export let promoCodeSchema: AnyObject

export let studentCodeSchema: AnyObject

export let professionSchema: AnyObject

export let firstNameSchema: AnyObject

export let lastNameSchema: AnyObject

export let nameSchema: AnyObject

export let aliasSchema: AnyObject

export let companyNameSchema: AnyObject

function translateLabel(translation: Translatable) {
  return localTranslate(translation, false) || ''
}

autorun(() => {
  baseStringSchema = yupInstance.string().ensure()

  textSchema = baseStringSchema.trim()

  aliasSchema = baseStringSchema.trim()

  textareaSchema = textSchema.min(TEXTAREA_MIN_LENGTH)

  basePasswordSchema = baseStringSchema.min(8)

  passwordSchema = basePasswordSchema
    .label(translateLabel(localizationStoreInstance.translation.form.password.label))
    .matches(PASSWORD_REGEXP)

  emailSchema = textSchema.email().label(translateLabel(localizationStoreInstance.translation.form.email.label))

  confirmPasswordSchema = basePasswordSchema.label(translateLabel(localizationStoreInstance.translation.form.confirmPassword.label))

  localitySchema = textSchema.label(translateLabel(localizationStoreInstance.translation.form.locality.label))

  phoneNumberSchema = yupInstance
    .string()
    .matches(PHONE_NUMBER_REGEXP)
    .label(translateLabel(localizationStoreInstance.translation.form.phoneNumber.label))

  personalNumberSchema = textSchema
    .length(PERSONAL_NUMBER_LENGTH, 'Felaktigt personummer. Ange personummer som: ÅÅÅÅMMDDNNN')
    .matches(/[0-9]/)
    .label(translateLabel(localizationStoreInstance.translation.form.personalNumber.label))

  messageSchema = textareaSchema
    .max(MESSAGE_MAX_LENGTH)
    .label(translateLabel(localizationStoreInstance.translation.form.message.label))

  questionSchema = textareaSchema
    .max(QUESTION_MAX_LENGTH)
    .label(translateLabel(localizationStoreInstance.translation.form.question.label))

  addressSchema = textSchema.label(translateLabel(localizationStoreInstance.translation.form.address.label))

  zipSchema = textSchema.label(translateLabel(localizationStoreInstance.translation.form.zip.label))

  grossSalarySchema = yupInstance.number()
    .max(GROSS_SALARY_MAX_LENGTH, 'The value is too high. Please contact us att hello@yohrs.se')
    .label(translateLabel(localizationStoreInstance.translation.form.grossSalary.label))

  promoCodeSchema = yupInstance.string().label(translateLabel(localizationStoreInstance.translation.form.promoCode.label))

  studentCodeSchema = yupInstance.string().label(translateLabel(localizationStoreInstance.translation.form.studentCode.label))

  professionSchema = textSchema.label(translateLabel(localizationStoreInstance.translation.form.profession.label))

  firstNameSchema = textSchema.label(translateLabel(localizationStoreInstance.translation.form.firstName.label))

  lastNameSchema = textSchema.label(translateLabel(localizationStoreInstance.translation.form.lastName.label))

  nameSchema = textSchema.label(translateLabel(localizationStoreInstance.translation.form.name.label))

  companyNameSchema = textSchema.label(translateLabel(localizationStoreInstance.translation.form.companyName.label))
})
