import { getRouteObjectList, withLayout } from '../router/util/router.helper'
import { RouteObjectList } from '../router/model/Route'
import { payrollRouteIds } from '../router/model/RouteId'
import { PayrollRoutesProvider } from './component/provider/PayrollRoutesProvider'

export const PayrollRoutes: RouteObjectList = [
  withLayout({
    layout: <PayrollRoutesProvider />,
    routes: getRouteObjectList(payrollRouteIds),
  }),
]
