import React from 'react'
import { Location, Navigate, RouteObject } from 'react-router-dom'
import { RouteList, RouteObjectList, StaticRoutePath } from '../model/Route'
import lodashFlattenDepth from 'lodash/flattenDepth'
import { CONCAT_ROUTE_DEPTH, NOT_FOUND_ROUTE } from './router.constant'
import { RouteIdList } from '../model/RouteId'
import { lazyRoutes } from './lazy-routes'
import { appHomePath, routePathConfig } from './app-route-path'
import { isBrowser } from '../../../model/DOM'

interface WithLayoutArg {
  layout: React.ReactNode;
  routes: RouteObjectList
}

export function withLayout({ layout, routes }: WithLayoutArg): RouteObject {
  return { element: layout, children: routes }
}

export function withNoFoundRoute(route: StaticRoutePath): RouteObject {
  return { path: NOT_FOUND_ROUTE, element: <Navigate to={route} /> }
}

export function concatRoutes(...routes: RouteList): RouteObjectList {
  return lodashFlattenDepth(routes, CONCAT_ROUTE_DEPTH)
}

export function getRouteObjectList(list: RouteIdList): RouteObjectList {
  return list.map(key => ({ element: lazyRoutes[key], path: routePathConfig[key] }))
}

export function isOnRoute(location: Location, path: (subPath: string) => string): boolean {
  const loginPath = path('')
  
  return location.pathname.includes(loginPath)
}

export function goHome() {
  if (isBrowser()) {
    window.location.href = appHomePath
  }
}
