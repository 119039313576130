import { FaqArticle, FaqGroup, FaqGroupName } from '../../contentful/model/Contentful'
import {
  getLocalizedEntry,
  getLocalizedEntryList,
  GetLocalizedEntryListRequest,
  GetLocalizedEntryRequest,
} from '../../contentful/model/Api'

export async function getFaqGroups(request: GetLocalizedEntryListRequest) {
  if (request.tags) {
    return getLocalizedEntryList<FaqGroup>({
      ...request,
      content_type: 'faqGroup',
      'metadata.tags.sys.id[in]': request.tags.join(','),
    })
  }

  return getLocalizedEntryList<FaqGroup>({
    ...request,
    content_type: 'faqGroup',
    'metadata.tags[exists]': false,
  })
}

export interface GetFaqArticles extends GetLocalizedEntryListRequest {
  faqGroupName?: FaqGroupName
}

export async function getFaqArticles({ faqGroupName, ...rest }: GetFaqArticles) {
  const params: any = {
    ...rest,
    content_type: 'faq',
  }

  if (faqGroupName) {
    params['fields.faqGroupName'] = faqGroupName
  }

  if (rest.tags) {
    params['metadata.tags.sys.id[in]'] = rest.tags.join(',')
  } else {
    params['metadata.tags[exists]'] = false
  }

  return getLocalizedEntryList<FaqArticle>(params)
}

export async function getFaqArticle(request: GetLocalizedEntryRequest) {
  return getLocalizedEntry<FaqArticle>(request)
}
