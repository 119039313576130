import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Fade, Typography, useMediaQuery } from '@mui/material'
import { useELearningStore } from '../../store/e-learning.store'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { CourseListPresentationItem } from './CourseListPresentationItem'
import { ELearningCourseCategory } from '../../model/ELearningCourseCategory'
import { AppCarousel } from 'src/component/common/analog/molecule/carousel/AppCarousel'
import { CourseItem } from './CourseItem'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { CourseSearch } from './CourseSearch'

export type CourseListProps = {
  categoryKey: string
  categoryTitle: string
}

export const CourseList = observer((props: CourseListProps) => {
  const eLearningStore = useELearningStore()
  const { translation } = useLocalizationStore()
  const [searchTerm, setSearchTerm] = useState('')
  const texts = translation.pages.eLearning
  const { isLoading: isLoadingCoursesByCategory } = eLearningStore.getGenericFieldsState('getCoursesByCategory')
  const { isLoading: isLoadingAllCourses } = eLearningStore.getGenericFieldsState('getAllCourses')
  const isLoading = isLoadingCoursesByCategory || isLoadingAllCourses
  const pageSize = 30
  const [selectedSubCategory, setSelectedSubCategory] = useState('')
  const isSmallScreen = useMediaQuery('(max-width:900px)')

  useEffect(() => {
    eLearningStore.loadSubCategoriesIfNeeded()
    eLearningStore.clearSearch()
    eLearningStore.selectedFilters = undefined
    setSelectedFilterValues('all')
    eLearningStore.courseSubCategory = []
    eLearningStore.getCourseSubCategory(props.categoryKey)
    eLearningStore.getAllCourses(props.categoryKey, 0, pageSize)
  }, [])

  const setSelectedFilterValues = (type: string) => {
    const categoryId = eLearningStore.categories?.find((category: ELearningCourseCategory) => category.titleLocale === props.categoryKey)?.id
    const filteredSubs = eLearningStore.categoriesWithSubs?.filter((subCategory: any) => subCategory.category.id === categoryId).map((subCategory: any) => subCategory.subCategory)
    
    if (type === 'all') {
      eLearningStore.selectedFilters = filteredSubs
    } else {
      const selectedSub = filteredSubs.find((subCategory: any) => subCategory.id === type)
      eLearningStore.selectedFilters = [selectedSub]
    }
  }

  const transform = (category: ELearningCourseCategory, index: number) => {
    return <CourseItem key={category.id} item={category} index={index} selectedSubCategory={selectedSubCategory} clickHandler={getCourses} />
  }

  const getCourses = async (subCategory: ELearningCourseCategory) => {
    eLearningStore.clearSearch()
    eLearningStore.selectedFilters = undefined

    if (searchTerm.length > 0) {
      if (selectedSubCategory === subCategory.id) {
        setSelectedSubCategory('')
        setSelectedFilterValues('all')
      } else {
        setSelectedSubCategory(subCategory.id)
        setSelectedFilterValues(subCategory.id)
      }
      await eLearningStore.search(searchTerm, 0, pageSize)
      
      return
    }
  
    if (selectedSubCategory === subCategory.id) {
      setSelectedSubCategory('')
      setSelectedFilterValues('all')
      await eLearningStore.getAllCourses(props.categoryKey, 0, pageSize)
    } else {
      setSelectedSubCategory(subCategory.id)
      setSelectedFilterValues(subCategory.id)
      await eLearningStore.getCoursesByCategory(subCategory.id, 0, pageSize)
    }
  }

  const extendResults = async () => {
    if (eLearningStore.courseList && eLearningStore.courseList.length !== eLearningStore.courseCount) {
      if (searchTerm.length > 0) {
        await eLearningStore.appendSearch(searchTerm, eLearningStore.courseList.length, pageSize)
      } else if (selectedSubCategory) {
        await eLearningStore.appendCoursesByCategory(selectedSubCategory, eLearningStore.courseList.length, pageSize)
      } else {
        await eLearningStore.appendAllCourses(props.categoryKey, eLearningStore.courseList.length, pageSize)
      }
    }
  }

  const setSearchTermHandler = async (term: string) => {
    setSearchTerm(term)
    eLearningStore.clearSearch()
    if (term === '') {
      setSelectedSubCategory('')
      setSelectedFilterValues('all')
      eLearningStore.getAllCourses(props.categoryKey, 0, pageSize)
    } else {
      await eLearningStore.search(term, 0, pageSize)
    }
  }

  return (
    <AppBox sx={styles.root}>
      <CourseSearch categoryKey={props.categoryKey} setSearchTerm={setSearchTermHandler} />
      {!isSmallScreen && <Box sx={styles.chipContainer}>
        {eLearningStore.courseSubCategory && eLearningStore.courseSubCategory?.length > 1 && eLearningStore.courseSubCategory.map(transform)}
      </Box>}
      {isSmallScreen && <AppCarousel showProgressBar={false}>
        {eLearningStore.courseSubCategory && eLearningStore.courseSubCategory?.length > 1 && eLearningStore.courseSubCategory.map(transform)}
      </AppCarousel>
      }
      <AppProgressLine isShowing={isLoading} />
      {eLearningStore.courseCount !== undefined && eLearningStore.courseCount !== 0 && <Typography variant='h4'>{`${eLearningStore.courseCount} ${texts.courses().toLowerCase()}`}</Typography>}
      {!isLoading && eLearningStore.courseList && eLearningStore.courseList.length === 0 && <Caption sx={{ color: 'text.secondary' }} textValue={texts.missingCourse} />}
      {!isLoading && eLearningStore.courseList && eLearningStore.courseList.length > 0 && (
        <Fade in={eLearningStore.courseList?.length > 0} mountOnEnter unmountOnExit>
          <AppBox sx={styles.courseList}>
            {eLearningStore.courseList.map(course => (
              <CourseListPresentationItem key={course.id} item={course} />
            ))}
          </AppBox>
        </Fade>
      )}
      {!isLoading && eLearningStore.courseList && eLearningStore.courseList.length > 0 && eLearningStore.courseList.length !== eLearningStore.courseCount && (
        <AppBox sx={{ justifyContent: 'center' }}>
          <AppButton fontSize={17} size='small' variant="contained" color="primary" onClick={extendResults}>{translation.buttons.loadMore()}</AppButton>
        </AppBox>
      )}
    </AppBox>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    padding: { xs: '1rem 1rem', md: '2rem 3rem' },
  },
  chipContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    flexWrap: 'wrap',
  },
  courseList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: { xs: 'center', lg: 'flex-start' },
    gap: 10,
  },
}
