import React from 'react'
import { ChildrenProps } from '../../../../model/React'

interface Props extends ChildrenProps {
  condition: boolean
  wrapper: React.FC<any>
}

export function ConditionalWrapper(props: Props) {
  const { children } = props

  return props.condition ? props.wrapper(children) : <>{children}</>
}
