import { TranslatableTextVariantProps, TranslatableText } from './TranslatableText'

export function Caption(props: TranslatableTextVariantProps) {
  return (
    <TranslatableText
      component={'p'}
      {...props}
      variant={'caption'}
    />
  )
}
