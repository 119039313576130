import { getRouteObjectList, withLayout } from '../router/util/router.helper'
import { RouterLayout } from '../router/component/layout/RouterLayout'
import { villkorRouteIds } from '../router/model/RouteId'

export const VillkorRoutes = [
  withLayout(
    {
      layout: <RouterLayout />,
      routes: getRouteObjectList(villkorRouteIds),
    },
  ),
]
