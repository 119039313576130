import { Box, Fade, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { MetricWithValue } from './MetricWithValue'
import { useDashboardStore } from '../store/dashboard.store'
import { Title1 } from 'src/feature/localization/component/atom/Title1'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { useState } from 'react'
import { useControlPanelStore } from 'src/feature/control-panel/store/control-panel.store'
import { AddUser } from './AddUser'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'

export type UserMetricsProps = {
  levelId: string
}

export const UserMetrics = observer(({ levelId }: UserMetricsProps) => {
  const { translation } = useLocalizationStore()
  const translations = translation.pages.myTeam
  const dashboardStore = useDashboardStore()
  const controlPanelStore = useControlPanelStore()
  const [showInviteDialog, setShowInvite] = useState(false)
  const { isLoading } = dashboardStore.getGenericFieldsState('downloadReport')

  const closeInvite = (reload: boolean) => {
    if (reload) {
      controlPanelStore.updateOrganisationUsers()
    }

    setShowInvite(false)
  }

  return (
    <Box sx={styles.root}>
      <AppBox sx={{ flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
        <Title1>{dashboardStore.teamOverview?.[levelId]?.organisationLevelName}</Title1>
        <Typography sx={{ textAlign: 'center', color: 'text.secondary' }}>{translations.subteamDesc()}</Typography>
      </AppBox>
      <Fade in={true} timeout={500} mountOnEnter unmountOnExit>
        <AppBox sx={styles.metricsWrapper}>
          <MetricWithValue size='large' label={translations.metricsHeader.totalUsers()} value={dashboardStore.teamOverview?.[levelId]?.totalUsers || 0} />
          <MetricWithValue size='large' label={translations.metricsHeader.activeUsers()} value={dashboardStore.teamOverview?.[levelId]?.activeUsers || 0} />
          <MetricWithValue size='large' label={translations.metricsHeader.inactiveUsers()} value={dashboardStore.teamOverview?.[levelId]?.inactiveUsers || 0} />
          <MetricWithValue size='large' label={translations.metricsHeader.coursesGiven()} value={dashboardStore.teamOverview?.[levelId]?.assignedCourses || 0} />
          <MetricWithValue size='large' label={translations.metricsHeader.coursesCompleted()} value={dashboardStore.teamOverview?.[levelId]?.totalCoursesUsersCompleted || 0} />
          <MetricWithValue
            size='large'
            color={(dashboardStore.teamOverview?.[levelId]?.completionRate || 0) < 50 ? 'error.main' : 'success.main'}
            label={translations.metricsHeader.completionRate()}
            value={`${dashboardStore.teamOverview?.[levelId]?.completionRate || 0}%`}
          />
        </AppBox>
      </Fade>
      <AppBox sx={styles.buttonWrapper}>
        <AppButton fontSize={17} size='small' variant='light' color="primary" onClick={() => setShowInvite(true)}>{translations.inviteUserButton()}</AppButton>
        <AppButton
          fontSize={17}
          size='small'
          variant='contained'
          color="primary"
          onClick={() => dashboardStore.downloadReport(levelId)}
        >{translations.downloadTeamReportButton()}</AppButton>
      </AppBox>
      <AppProgressLine sx={{ width: '5rem' }} isShowing={isLoading}></AppProgressLine>
      {showInviteDialog && <AddUser levelId={levelId} show={showInviteDialog} onClose={closeInvite} />}
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
  },
  metricsWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(3, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(6, 1fr)' },
    columnGap: { xs: '0.5rem', md: '1.5rem' },
  },
  buttonWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' },
    gap: 20,
  },
}