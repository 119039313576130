import { NotOverridableComponentProps } from '../../../../../model/Component'
import { AppBox } from '../AppBox'
import Menu, { MenuProps } from '@mui/material/Menu'
import { AppMenuList } from './AppMenuList'
import { AppPaper } from '../AppPaper'
import { handlePassedSx } from '../../../../../feature/theme/model/Sx'

export type AppMenuProps = NotOverridableComponentProps<MenuProps>

export function AppMenu(props: AppMenuProps) {
  const {
    PaperProps: {
      component: PaperComponent = AppPaper,
      sx: paperSx = [],
      ...restPaperProps
    } = {},
    MenuListProps: {
      component: MenuListComponent = AppMenuList,
      ...restMenuListProps
    } = {},
    ...rest
  } = props

  return (
    <AppBox
      PaperProps={{
        variant: 'elevation',
        ...restPaperProps,
        component: PaperComponent,
        sx: [
          styles.paper,
          ...handlePassedSx(paperSx),
        ],
      }}
      {...rest}
      MenuListProps={{
        direction: 'column',
        ...restMenuListProps,
        component: MenuListComponent,
      }}
      component={Menu}
    />
  )
}

const styles = {
  paper: {
    padding: 10,
    border: 0,
    borderRadius: '0.8em',
  },
}
