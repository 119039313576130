import { Router } from './Router'
import { Suspense } from 'react'
import { FullscreenLoader } from '../../../../component/common/original/organism/FullscreenLoader'

export function LazyRouter() {
  return (
    <Suspense
      fallback={(
        <FullscreenLoader isShown={true} />
      )}
    >
      <Router />
    </Suspense>
  )
}
