import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { CourseList } from '../component/organism/CourseList'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'

export const LearningCategoryPage = observer(() => {
  const { key } = useParams()
  const { translation } = useLocalizationStore()

  if (!key) {
    throw new Error('Missing category key')
  }

  const { categories } = translation.pages.eLearning
  type ObjectKey = keyof typeof categories
  const texts = categories[key as ObjectKey]

  return (
    <>
      <CourseList categoryKey={key} categoryTitle={texts.title()} />
    </>
  )
})
