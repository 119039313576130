import { observer } from 'mobx-react-lite'
import { Box, Switch, Theme } from '@mui/material'
import { themeStoreInstance } from '../../store/theme.store'
import { AppThemeMode } from '../../model/Mode'

export const ThemeButton = observer(() => {
  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMode: AppThemeMode = event.target.checked ? 'dark' : 'light'
    themeStoreInstance.changeMode(newMode)
  }

  return (
    <Box sx={styles.root}>
      <Switch
        checked={themeStoreInstance.mode === 'dark'}
        onChange={handleModeChange}
        inputProps={{ 'aria-label': 'theme switch' }}
        color='secondary'
        sx={styles.customSwitch}
      />
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  customSwitch: (theme: Theme) => ({
    width: 50,
    height: 25,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(26px)',
        '& + .MuiSwitch-track': {
          backgroundColor: `${theme.palette.secondary.main}80`,
          opacity: 1,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      width: 20,
      height: 20,
      backgroundColor: theme.palette.background.default,
    },
    '& .MuiSwitch-track': {
      borderRadius: '12px',
      backgroundColor: `${theme.palette.secondary.main}80`,
      opacity: 1,
      position: 'relative',
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 10,
        height: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:before': {
        content: '"☀️"',
        left: 8,
        fontSize: 17,
      },
      '&:after': {
        content: '"🌙"',
        right: 8,
        fontSize: 17,
      },
    },
  }),
}
