import { TranslatableText, TranslatableTextVariantProps } from './TranslatableText'

export function Paragraph(props: TranslatableTextVariantProps) {
  return (
    <TranslatableText
      variant={'body1'}
      {...props}
      component={'p'}
    />
  )
}
