import { useLocation, useNavigate } from 'react-router-dom'
import React from 'react'
import { AppSelect } from '../../../../form/component/analog/AppSelect'
import { AppOption } from '../../../../form/component/analog/AppOption'
import { NavModel } from '../../../../../model/Nav'

interface Props {
  nav: NavModel
}

export function DropdownNav({ nav }: Props) {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const handleChange: React.ChangeEventHandler<any> = e => navigate(e.target.value)
  const list = Object.entries(nav)

  return (
    <AppSelect
      onChange={handleChange}
      value={pathname}
    >
      {list.filter(([_, { props }]) => !props.hidden).map(([id, { label, props }]) => (
        <AppOption key={id} value={props.link} textValue={label} />
      ))}
    </AppSelect>
  )
}
