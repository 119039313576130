import { APP_FONT_SIZE } from '../../../theme.constant'
import { OverrideFunc } from '../../../../model/Override'
import { Theme } from '@mui/material'

export const MuiCssBaseline: OverrideFunc<'MuiCssBaseline'> = ({ palette }) => ({
  styleOverrides: {
    '*': {
      '&::before, &::after': {
        boxSizing: 'border-box' as any,
      },
    },
    '*::selection': {
      backgroundColor: `${palette.alternate.primary}69`,
    },
    html: {
      WebkitFontSmoothing: 'auto',
      MozOsxFontSmoothing: 'auto',
      fontSize: APP_FONT_SIZE,
    },
    body: {
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    code: {
      fontFamily: 'source-code-pro, Menlo, monospace',
    },
    button: {
      fontSize: 'unset',
      fontFamily: 'unset',
      border: 'unset',
      boxShadow: 'unset',
      background: 'unset',
      margin: 0,
      padding: 0,
      cursor: 'pointer',
    },
    select: {
      appearance: 'none',
    },
    '.infinite-scroll-component__outerdiv': {
      width: '100%',
    },
    '::-webkit-scrollbar': {
      width: '5px',
      height: '5px',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '12px',
      backgroundColor: '#27827A60',
      '&:hover': {
        backgroundColor: '#27827A40',
      },
    },
    '::-webkit-scrollbar-track': (theme: Theme) => ({
      backgroundColor: theme.palette.background.dark,
    }),
  },
})
