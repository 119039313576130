import { getRouteObjectList, withLayout } from '../router/util/router.helper'
import { RouteObjectList } from '../router/model/Route'
import { RouterLayout } from '../router/component/layout/RouterLayout'
import { skillAssessment } from '../router/model/RouteId'

export const skillAssessmentRoutes: RouteObjectList = [
  withLayout({
    layout: <RouterLayout />,
    routes: getRouteObjectList(skillAssessment),
  }),
]
