import { AppBox, AppBoxProps } from '../AppBox'
import { GridProps } from '@mui/material/Grid'
import { AppUnstyledList } from './AppUnstyledList'

export type AppFlexContainerProps = Omit<GridProps & AppBoxProps, 'container' | 'item' | 'looksLike'>

export function AppFlexContainer(props: AppFlexContainerProps) {
  return (
    <AppBox
      isFlexible
      container
      direction={'row'}
      component={AppUnstyledList}
      {...props}
    />
  )
}
