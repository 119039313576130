import { arrToStr } from '../../../../../util/helper'
import { APP_FONT_SIZE } from '../../theme.constant'
import { pxToRem } from '../../../model/CssUnit'
import { TypographyOptions, TypographyUtils } from '@mui/material/styles/createTypography'

export const genericTypographyStyles = {
  fontFamily: arrToStr([
    'Poppins',
    'sans-serif',
  ]),
}

export const baseTypography: TypographyOptions & TypographyUtils = {
  fontSize: APP_FONT_SIZE,
  pxToRem,
  ...genericTypographyStyles,
} as const
