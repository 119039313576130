import { SocialIcons } from './SocialIcons'
import { AskQuestionButton } from '../../question/component/AskQuestionButton'
import { AppBox } from '../../../component/common/analog/molecule/AppBox'
import { observer } from 'mobx-react-lite'
import { Theme } from '@mui/material'
import { AppFooter } from '../../../component/common/analog/molecule/basic/AppFooter'
import { AppContainer } from '../../../component/common/analog/molecule/AppContainer'
import { Palette } from 'src/feature/theme/model/Palette'

export const Footer = observer(() => {
  return (
    <AppFooter sx={styles.root}>
      <AskQuestionButton />

      <AppContainer>
        
        <AppBox sx={styles.bottomContent}>
          <SocialIcons />
        </AppBox>

      </AppContainer>
    </AppFooter>
  )
})

const styles = {
  root: ({ spacing }: Theme) => ({
    backgroundColor: Palette.ONYX_BLACK,
    position: 'relative',
    color: Palette.WHITE,
    padding: {
      xs: spacing(20, 0),
      md: spacing(20, 0),
    },
  }),
  bottomContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
}
