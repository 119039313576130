import { Box, Fade, Slide } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useRef } from 'react'
import { ChildrenProps } from 'src/model/React'

export interface SlipAnimationProps extends ChildrenProps {
  page: number,
  current: number,
  previousPage: number,
}

export const SlipAnimation = observer((props: SlipAnimationProps) => {
  const { children } = props
  const containerRef2 = useRef(null)
  const show = props.page === props.current
  const speed = 1000

  const dir = props.page < props.current || props.previousPage > props.page ? 'right' : 'left'

  return (
    <Box>
      <Box sx={styles.container} ref={containerRef2}>
        <Slide direction={dir} in={show} container={containerRef2.current} timeout={speed}>
          <Box>
            <Fade in={show} timeout={speed * 2} mountOnEnter unmountOnExit>
              <Box>
                {children}
              </Box>
            </Fade>
          </Box>
        </Slide>
      </Box>
    </Box>
  )
})

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
}