import { emailSchema, nameSchema, questionSchema } from '../../form/model/FormSchema'
import { FormFieldId } from '../../form/model/FieldId'
import { toStr } from '../../../model/String'
import { yupInstance } from '../../../util/yup/yup-instance'
import { InferType } from 'yup'

export function getAskQuestionFormSchema(name?: string, email?: string) {
  return yupInstance.object({
    [FormFieldId.NAME]: nameSchema
      .default(toStr(name))
      .required(),
    [FormFieldId.EMAIL]: emailSchema
      .default(toStr(email))
      .required(),
    [FormFieldId.QUESTION]: questionSchema.required(),
  })
    .required()
}

export const askQuestionFormSchema = getAskQuestionFormSchema()

export type AskQuestionFormModel = InferType<ReturnType<typeof getAskQuestionFormSchema>>

export function getAskQuestionFormInitialValues(name?: string, email?: string) {
  return getAskQuestionFormSchema(name, email).getDefault()
}
