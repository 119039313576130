/* eslint-disable no-useless-catch */
import { Entry, EntryCollection } from 'contentful'
import { createStore } from '../../../util/mobx/generic-store/store-creator'
import { GenericStoreAsyncMethod } from '../../../util/mobx/generic-store/decorator/async-method.decorator'
import { GenericStore } from '../../../util/mobx/generic-store/generic.store'
import { getAdditionalServices } from 'src/feature/knowledge/api/additional-services'
import { getFaqArticles, getFaqGroups } from '../../knowledge/api/faq'
import { localizationStoreInstance } from '../../localization/store/localization.store'
import {
  getBlogArticle,
  getBlogArticleList,
} from '../../blog/api/blog'
import { Maybe } from '../../../model/Util'
import { AnyObject, checkAreObjKeys } from '../../../model/Object'
import { Id } from '../../../model/Id'
import { dayJsInstance } from '../../localization/model/Time'
import { isLength } from '../../../util/helper'
import { getEPlatformList } from '../../e-learning/api/e-learning'
import { delArticle, postArticle } from '../api/editContent'
import { Document } from '@contentful/rich-text-types'

export enum ContentName {
  FAQ_GROUPS = 'faqGroups',
  FAQ_ARTICLES = 'faqArticles',
  ADDITIONAL_SERVICES = 'additionalServices',
  BLOG = 'blog',
  Elearning = 'e-learning',
}

export interface HandleFetchEntryList {
  contentName: ContentName
  params?: AnyObject
  tags?: string[]
}

export interface HandleFetchEntryItem {
  id: Id
  params?: AnyObject
}

export class ContentfulStore extends GenericStore {
  currentContentName!: ContentName
  listData!: Maybe<EntryCollection<AnyObject>>
  itemData!: Maybe<Entry<AnyObject>>

  constructor() {
    super('ContentfulStore')

    super.observe(this)
    this.reset()
    super.persist({ encrypt: false, excludedProperties: ['listData'] })
  }

  get availableLocale() {
    switch (localizationStoreInstance.locale) {
    case 'en-US':
      return 'en-US'
    case 'sv-SE':
    default:
      return 'sv-SE'
    }
  }

  get list() {
    return this.isListData ? this.listData!.items : []
  }

  get item() {
    return this.isItemData ? this.itemData!.fields : []
  }

  get isListData() {
    return isLength(this.listData?.items)
  }

  get isItemData() {
    return Boolean(this.itemData?.fields)
  }

  reset() {
    this.listData = null
  }

  getContentListApiByName(name: ContentName) {
    switch (name) {
    case ContentName.FAQ_GROUPS:
      return getFaqGroups
    case ContentName.FAQ_ARTICLES:
      return getFaqArticles
    case ContentName.ADDITIONAL_SERVICES:
      return getAdditionalServices
    case ContentName.BLOG:
      return getBlogArticleList
    case ContentName.Elearning:
      return getEPlatformList
    default:
      throw new Error('Wrong content name')
    }
  }

  @GenericStoreAsyncMethod()
  async handleFetchEntryList(options: HandleFetchEntryList) {
    this.currentContentName = options.contentName

    const api = this.getContentListApiByName(options.contentName)
    this.listData = await api({
      ...options.params,
      locale: this.availableLocale,
      tags: options.tags,
    })
  }

  @GenericStoreAsyncMethod()
  async handleFetchMultipleEntryListsFaqGroups(options_low: HandleFetchEntryList, options_mid: HandleFetchEntryList, options_high: HandleFetchEntryList) {
    this.currentContentName = options_high.contentName

    const highResult = await getFaqGroups({
      ...options_high.params,
      locale: this.availableLocale,
      tags: options_high.tags,
    })

    const midResult = await getFaqGroups({
      ...options_mid.params,
      locale: this.availableLocale,
      tags: options_mid.tags,
    })

    const lowResult = await getFaqGroups({
      ...options_low.params,
      locale: this.availableLocale,
      tags: options_low.tags,
    })

    let items = highResult.items
    items = items.concat(midResult.items.filter(i => !items.some(t => t.fields.name === i.fields.name)))
    items = items.concat(lowResult.items.filter(i => !items.some(t => t.fields.name === i.fields.name)))

    this.listData = { ...highResult, items }
  }

  @GenericStoreAsyncMethod()
  async handleFetchMultipleEntryListsFaqArticles(options_low: HandleFetchEntryList, options_mid: HandleFetchEntryList, options_high: HandleFetchEntryList) {
    this.currentContentName = options_high.contentName

    const highResult = await getFaqArticles({
      ...options_high.params,
      locale: this.availableLocale,
      tags: options_high.tags,
    })

    const midResult = await getFaqArticles({
      ...options_mid.params,
      locale: this.availableLocale,
      tags: options_mid.tags,
    })

    const lowResult = await getFaqArticles({
      ...options_low.params,
      locale: this.availableLocale,
      tags: options_low.tags,
    })

    let items = highResult.items
    items = items.concat(midResult.items.filter(i => !items.some(t => t.fields.question === i.fields.question)))
    items = items.concat(lowResult.items.filter(i => !items.some(t => t.fields.question === i.fields.question)))

    this.listData = { ...highResult, items: items.filter(a => !a.fields.hidden) }
  }

  @GenericStoreAsyncMethod()
  async handleFetchLatestFaqArticles(options_low: HandleFetchEntryList, options_mid: HandleFetchEntryList, options_high: HandleFetchEntryList) {
    this.currentContentName = options_high.contentName

    const highResult = await getFaqArticles({
      ...options_high.params,
      locale: this.availableLocale,
      tags: options_high.tags,
    })

    const midResult = await getFaqArticles({
      ...options_mid.params,
      locale: this.availableLocale,
      tags: options_mid.tags,
    })

    const lowResult = await getFaqArticles({
      ...options_low.params,
      locale: this.availableLocale,
      tags: options_low.tags,
    })

    let items = highResult.items
    items = items.concat(midResult.items.filter(i => !items.some(t => t.fields.question === i.fields.question)))
    items = items.concat(lowResult.items.filter(i => !items.some(t => t.fields.question === i.fields.question)))

    return { ...highResult, items: items.filter(a => !a.fields.hidden) }
  }

  @GenericStoreAsyncMethod()
  async handleGetBlogArticle(options: HandleFetchEntryItem) {
    this.currentContentName = ContentName.BLOG
    this.itemData = await getBlogArticle({
      id: options.id,
      params: { ...options.params, locale: this.availableLocale },
    })

    if (checkAreObjKeys<Entry<AnyObject>>(this.itemData)) {
      // format date
      this.itemData.fields.date = dayJsInstance(
        this.itemData.fields.date,
      ).format('DD MMMM YYYY')
    }
  }

  @GenericStoreAsyncMethod()
  async removeArticle(id: string) {
    await delArticle(id)
  }

  @GenericStoreAsyncMethod()
  async updateArticle(id: string, document: Document) {
    await postArticle(id, document)
  }
}

export const {
  storeInstance: contentfulStoreInstance,
  useStore: useContentfulStore,
  StoreProvider: ContentfulStoreProvider,
} = createStore(new ContentfulStore())
