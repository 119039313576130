import { ForumStoreProvider } from '../../store/forum.store'
import { ForumSidebar } from './ForumSidebar'
import { RouterLayout } from '../../../router/component/layout/RouterLayout'
import { useUserStore } from 'src/feature/user/store/user.store'
import { useAuthStore } from 'src/feature/auth/store/auth.store'
import { NoAccess } from '../NoAccess'

export function ForumModuleProvider() {
  const authStore = useAuthStore()
  const { userData } = useUserStore()

  if (!authStore.isAuthorized || !userData || !userData.verified) {
    return <NoAccess />
  }

  return (
    <ForumStoreProvider>
      <RouterLayout sidebar={<ForumSidebar />} />
    </ForumStoreProvider>
  )
}
