import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { ProductOverlayStep } from './ProductOverlayStep'
import { SlipAnimation } from './SlipAnimation'
import Step1 from '../assets/step1.gif'
import Step2 from '../assets/step2.gif'
import Step3 from '../assets/step3.gif'

export type OverlayContentProps = {
  page: number,
  setPage: (page: number) => void,
  previousPage: number,
}

// https://codepen.io/tomquas/pen/gEJdxR
export const OverlayContent = observer((props: OverlayContentProps) => {
  const { page, previousPage } = props

  return (
    <AppBox sg={styles.box}>
      <ArrowBackIos sx={ { ...styles.navigate, opacity: (page !== 1 ? 1 : 0) } } onClick={() => props.setPage(page - 1)} />
      <Box sx={styles.containtContainer}>
        <SlipAnimation page={1} current={page} previousPage={previousPage} >
          <ProductOverlayStep title='' subTitle='Kompetensutveckling med YOHRS' icon={Step1} body='Välkommen till YOHRS, din plattform för kompetensutveckling! Utforska vårt breda kursutbud för din kompetensutveckling. Tillsammans formar vi din väg till framgång.' />
        </SlipAnimation>
        <SlipAnimation page={2} current={page} previousPage={previousPage} >
          <ProductOverlayStep title='' subTitle='Din portal till ständigt lärande' icon={Step2} body='På YOHRS tror vi att lärande är en livslång resa. Välj bland över 19 000 onlinekurser för att bygga och bredda din kunskapsbas.' />
        </SlipAnimation>
        <SlipAnimation page={3} current={page} previousPage={previousPage} >
          <ProductOverlayStep title='' subTitle='Vi är här för att hjälpa dig' icon={Step3} body='Har du några frågor om våra kurser eller vår plattform? Kontakta oss så hjälper vi dig! hello@yohrs.com' />
        </SlipAnimation>
      </Box>
      <ArrowForwardIos sx={ { ...styles.navigate, opacity: (page !== 3 ? 1 : 0) } } onClick={() => props.setPage(page + 1)} />
    </AppBox>
  )
})

const styles = {
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    wdith: '100%',
    alignItems: 'center',
  },
  containtContainer: {
    position: 'relative',
    height: '470px',
    width: '282px',
  },
  navigate: {
    position: 'relative',
    margin: 'auto 0',
    padding: '0 -20px',
    cursor: 'pointer',
  },
}