import { AppBox, AppBoxProps } from './AppBox'
import { forwardRef } from 'react'

export type AppPaperProps = Omit<AppBoxProps, 'isPaper'>

export const AppPaper = forwardRef((props: AppPaperProps, ref) => {
  return (
    <AppBox
      {...props}
      isPaper
      ref={ref}
    />
  )
})

AppPaper.displayName = 'AppPaper'
