import { getRouteObjectList, withLayout } from '../router/util/router.helper'
import { RouteObjectList } from '../router/model/Route'
import { b2bRouteIds } from '../router/model/RouteId'
import { RouterLayout } from '../router/component/layout/RouterLayout'

export const B2bRoutes: RouteObjectList = [
  withLayout({
    layout: <RouterLayout />,
    routes: getRouteObjectList(b2bRouteIds),
  }),
]
