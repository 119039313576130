import { Translatable } from '../../../localization/model/Text'
import { localTranslate, renderNodeOrLocalTranslate } from '../../../localization/model/Translate'
import { AppStyle } from '../../../theme/style/app.style'
import { handlePassedSx } from '../../../theme/model/Sx'
import { GenericFieldHTMLAttributes } from 'formik/dist/types'
import { InputAdornment, TextField, TextFieldProps, Theme } from '@mui/material'
import { FormLabelType } from '../../model/Form'
import { NotOverridableComponentProps } from '../../../../model/Component'
import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { useCallback } from 'react'
import { TEXTAREA_MIN_LENGTH } from '../../model/FormSchema'
import { emptyFunction } from '../../../../model/Function'
import { observer } from 'mobx-react-lite'

type OmittedTopLevelProp = 'placeholder' | 'label' | 'error' | 'disabled'

export type AppTextFieldProps =
  Omit<TextFieldProps & NotOverridableComponentProps, OmittedTopLevelProp>
  & Omit<GenericFieldHTMLAttributes, OmittedTopLevelProp>
  & { placeholder?: Translatable, label?: FormLabelType, isError?: unknown, disabled?: any, isTextarea?: boolean }

export const AppTextField = observer((props: AppTextFieldProps) => {
  const {
    sx = [],
    placeholder = '',
    label = '',
    isError,
    onChange = emptyFunction,
    isTextarea = false,
    multiline = isTextarea,
    minRows = isTextarea ? 5 : undefined,
    inputProps: {
      minLength = isTextarea ? TEXTAREA_MIN_LENGTH : undefined,
      ...rest_inputProps
    } = {},
    InputProps: {
      sx: InputPropsSx = [],
      startAdornment = null,
      endAdornment = null,
      ...rest_InputProps
    } = {},
    FormHelperTextProps: {
      sx: FormHelperTextPropsSx = [],
      ...FormHelperTextPropsRest
    } = {},
    InputLabelProps: {
      sx: InputLabelPropsSx = [],
      ...InputLabelPropsRest
    } = {},
    ...rest
  } = props

  const handleChange = useCallback(onChange, [])

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <AppBox
      fz={16}
      mb={'1.5em'}
      label={renderNodeOrLocalTranslate(label)}
      placeholder={localTranslate(placeholder)}
      type={'text'}
      multiline={multiline}
      minRows={minRows}
      onChange={handleChange}
      error={Boolean(isError)}
      sx={[
        styles.root,
        ...handlePassedSx(sx),
      ]}
      variant={'outlined'}
      inputProps={{
        minLength,
        ...rest_inputProps,
      }}
      InputProps={{
        sx: [
          AppStyle.fieldInput,
          styles.field,
          ...handlePassedSx(InputPropsSx),
        ],
        startAdornment: startAdornment && (
          <InputAdornment position="start" sx={styles.startAdornment}>{startAdornment}</InputAdornment>
        ),
        endAdornment: endAdornment && (
          <InputAdornment position="end" sx={styles.endAdornment}>{endAdornment}</InputAdornment>
        ),
        ...rest_InputProps,
      }}
      FormHelperTextProps={{
        sx: [
          styles.error,
          ...handlePassedSx(FormHelperTextPropsSx),
        ],
        ...FormHelperTextPropsRest,
      }}
      InputLabelProps={{
        sx: [
          styles.label,
          ...handlePassedSx(InputLabelPropsSx),
        ],
        shrink: true,
        required: false, // disable asterisk (*)
        ...InputLabelPropsRest,
      }}
      {...rest}
      component={TextField}
    />
  )
})

const styles = {
  root: {
    padding: 0,
    display: 'flex',
    flex: 1,
  },
  field: (theme: Theme) => ({
    fontSize: '1em',
    border: `0.119em solid ${theme.palette.background.default}69`,
    backgroundColor: 'background.default',
    transition: theme.transitions.create([
      'border-color',
    ]),
    borderRadius: theme.shape.borderRadius,
    '& fieldset': {
      border: 'none',
      borderRadius: '0px',
    },
    input: {
      padding: 0,
      fontSize: '1em',
    },
    '&.Mui-focused': {
      borderColor: `${theme.palette.primary.main}69`,
      borderWidth: '0.119em',
    },
    '&.Mui-disabled': {
      backgroundColor: 'text.secondary',
      cursor: 'not-allowed',
    },
  }),
  label: {
    color: 'text.primary',
    position: 'static',
    transform: 'none',
    margin: '0 auto 1.142em 0',
    fontWeight: 600,
    fontSize: '0.875em',
    lineHeight: 1.28,
    fontStyle: 'normal',
    width: 'auto',
  },
  error: {
    width: 'auto',
    fontSize: '0.9em',
    margin: 0,
    mt: '0.625em',
    ml: '0.25em',
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  startAdornment: {
    height: 'unset',
    mr: '0.6em',
  },
  endAdornment: {
    height: 'unset',
    ml: '0.6em',
  },
}
