import { observer } from 'mobx-react-lite'
import { useSkillAssessmentStore } from 'src/feature/skill-assessment/store/skill-assessment.store'
import { AddExpectedRole } from './AddExpectedRole'
import { useEffect, useState } from 'react'
import { ExpectedRating } from './ExpectedRating'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { Typography } from '@mui/material'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { ProfessionType } from 'src/feature/skill-assessment/model/skill-assessment-model'

export type SetSkilldemandsProps = {
  levelId: string
}

export const SetSkillDemands = observer(({ levelId }: SetSkilldemandsProps) => {
  const { translation } = useLocalizationStore()
  const translations = translation.pages.myTeam
  const skillAssessmentStore = useSkillAssessmentStore()
  const [professionsData, setProfessionsData] = useState<ProfessionType[]>([])
  const [showAddExpectedRole, setShowAddExpectedRole] = useState(false)
  const { isLoading: isSkillcategory } = skillAssessmentStore.getGenericFieldsState('getSkillCategory')
  const { isLoading: isProfessionLoading } = skillAssessmentStore.getGenericFieldsState('getProfessions')
  const { isLoading: isAddSkillCategory } = skillAssessmentStore.getGenericFieldsState('addSkillCategory')
  const { isLoading: isDeleteExpectedRole } = skillAssessmentStore.getGenericFieldsState('deleteExpectedRole')
  const { isLoading: isDeleteSkillSet } = skillAssessmentStore.getGenericFieldsState('deleteSkillSet')
  const { isLoading: isAddSkillSet } = skillAssessmentStore.getGenericFieldsState('addSkillSet')
  const isLoading = isSkillcategory || isProfessionLoading || isAddSkillCategory || isDeleteExpectedRole || isDeleteSkillSet || isAddSkillSet

  useEffect(() => {
    getSkillcategoryData()
  }, [skillAssessmentStore, levelId])

  const getSkillcategoryData = async () => {
    skillAssessmentStore.reset()
    skillAssessmentStore.getSkillCategory(levelId).then(() => {
      if (!skillAssessmentStore.skillCategory) {
        skillAssessmentStore.getProfessions().then((data) => {
          if (Array.isArray(data)) {
            setProfessionsData(data)
          }
          setShowAddExpectedRole(true)
        })
      } else {
        setShowAddExpectedRole(false)
      }
    })
  }

  return (
    <>
      {isLoading && <AppBox sx={{ flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
        <AppProgressLine sx={{ width: '5rem' }} isShowing={isLoading} />
        <Typography sx={{ textAlign: 'center', color: 'text.secondary' }}>{translations.loadingMsg()}</Typography>
      </AppBox>}
      {!isLoading && showAddExpectedRole && <AddExpectedRole levelId={levelId} professionsData={professionsData} onChange={getSkillcategoryData} />}
      {!showAddExpectedRole && skillAssessmentStore.skillCategory && <ExpectedRating levelId={levelId} onChange={getSkillcategoryData} />}
    </>
  )
})
