import { FormLabelType } from '../../model/Form'
import { TranslationFunctions } from '../../../localization/util/i18n/i18n-types'
import { useLocalizationStore } from '../../../localization/store/localization.store'
import { observer } from 'mobx-react-lite'
import { AppTextField, AppTextFieldProps } from '../analog/AppTextField'
import { FormFieldId } from '../../model/FieldId'

type AvailableTranslationField = keyof TranslationFunctions['form']

export type TranslatedTextFieldProps = AppTextFieldProps & {
  keyword: Extract<`${FormFieldId}`, AvailableTranslationField>
  label?: FormLabelType
}

export const TranslatedTextField = observer((props: TranslatedTextFieldProps) => {
  const localization = useLocalizationStore()

  const { translation } = localization
  const {
    keyword,
    ...rest
  } = props
  const { form } = translation
  const translatedField = form[keyword]
  const label = props.label || translatedField.label
  const placeholder = props.placeholder || translatedField.placeholder

  return (
    <AppTextField
      fullWidth
      label={label}
      autoComplete={keyword}
      placeholder={placeholder}
      {...rest}
      // below props should be the latest because they shouldn't be overridden (since the purpose of the component in this)
      id={keyword}
      name={keyword}
    />
  )
})
