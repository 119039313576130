import { PostModel } from '../model/Post'
import { axiosInstance } from '../../../util/axios/instance.axios'

export const createPost = async (content: string) => {
  const { data } = await axiosInstance.request<PostModel>({
    url: '/posts',
    method: 'post',
    data: { content },
  })

  return data
}

export const deletePost = async (postId: string) => {
  await axiosInstance({
    url: `/posts/${postId}`,
    method: 'delete',
  })
}

export interface GetPostsRequest {
  page?: number,
  amount?: number,
  searchQuery?: string,
  hashtag?: string,
}

export interface GetPostsResponse {
  paginatedPostsEntities: PostModel[]
  allPostsIds: string[]
  hasMore: boolean
}

export const getPosts = async (queries?: GetPostsRequest): Promise<GetPostsResponse> => {
  const { data } = await axiosInstance.request<GetPostsResponse>({
    url: '/posts',
    params: queries,
  })

  return data
}
