import { GenericClass } from '../../../model/Class'
import { Maybe } from '../../../model/Util'
import { arePropsInObj, ObjKey } from '../../../model/Object'

interface GenericField {
  value: any
  callerMethodName: Maybe<ObjKey>
}

interface GenericFieldsModel {
  loader: GenericField
  success: GenericField
  error: GenericField
}

const genericFieldDefaults: GenericField = {
  value: null,
  callerMethodName: null,
}

export class GenericFields extends GenericClass implements GenericFieldsModel {
  loader!: GenericField
  success!: GenericField
  error!: GenericField

  constructor() {
    super()
    this.resetGenericFields()
  }

  resetGenericFields() {
    this.resetLoader()
    this.resetSuccess()
    this.resetError()
  }

  get isDefault() {
    return !this.isSuccess && !this.isError
  }

  get isEnd() {
    return this.isError || this.isSuccess
  }

  get isLoading() {
    return this.checkIsGenericValueTrue(this.loader.value)
  }

  get isSuccess() {
    return this.checkIsGenericValueTrue(this.success.value)
  }

  get isError() {
    return this.checkIsGenericValueTrue(this.error.value)
  }

  getGenericFieldsState(methodCallerName?: keyof this) {
    return {
      isLoading: this.checkIsLoading(methodCallerName),
      isSuccess: this.checkIsSuccess(methodCallerName),
      isError: this.checkIsError(methodCallerName),
      isDefault: this.isDefault,
      isEnd: this.isEnd,
    }
  }

  startLoading() {
    this.loader.value = true
  }

  endLoading() {
    this.loader.value = false
  }

  setLoader(options: GenericField) {
    this.loader = this.getGenericFieldObj(options)
  }

  setSuccess(options: GenericField) {
    this.success = this.getGenericFieldObj(options)
  }

  setError(options: GenericField) {
    this.error = this.getGenericFieldObj(options)
  }

  resetLoader() {
    this.loader = this.getGenericFieldObj()
  }

  resetSuccess() {
    this.success = this.getGenericFieldObj()
  }

  resetError() {
    this.error = this.getGenericFieldObj()
  }

  checkIsGenericValueTrue(value: unknown): boolean {
    return Boolean(value) && value !== null
  }

  checkIsError(methodCallerName?: keyof this) {
    return this.checkIsGenericValueTrueByCaller('error', methodCallerName)
  }

  checkIsSuccess(methodCallerName?: keyof this) {
    return this.checkIsGenericValueTrueByCaller('success', methodCallerName)
  }

  checkIsLoading(methodCallerName?: keyof this) {
    return this.checkIsGenericValueTrueByCaller('loader', methodCallerName)
  }

  private getGenericFieldObj(options: GenericField = genericFieldDefaults) {
    return options
  }

  private checkIsGenericValueTrueByCaller(genericFieldName: keyof GenericFieldsModel, methodCallerName?: keyof this): boolean {
    const field = this[genericFieldName]
    const isWrongFieldName = !arePropsInObj(field, ['value', 'callerMethodName'])

    if (isWrongFieldName) {
      return false
    }

    const isValueTrue = this.checkIsGenericValueTrue(field.value)

    if (methodCallerName) {
      return field.callerMethodName === methodCallerName && isValueTrue
    }

    return isValueTrue
  }
}
