import { Title1 } from './Title1'
import { TranslatableTextVariantProps } from './TranslatableText'

export function PageTitle(props: TranslatableTextVariantProps) {
  return (
    <Title1
      mt={0}
      mx={0}
      mb={'0.571em'}
      {...props}
    />
  )
}
