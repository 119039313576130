import { concatRoutes, withNoFoundRoute } from '../../util/router.helper'
import { RouteObject, useRoutes } from 'react-router-dom'
import { RouteList, StaticRoutePath } from '../../model/Route'
import { getConditionalObjProps } from '../../../../model/Object'

interface Props {
  redirectToIfNoFound?: StaticRoutePath
  routes: RouteList
}

export function RouteStacker(props: Props) {
  const noFoundRouteRedirect: RouteObject = getConditionalObjProps({
    if: props.redirectToIfNoFound,
    props: withNoFoundRoute(props.redirectToIfNoFound!),
  })

  return useRoutes(
    concatRoutes(
      noFoundRouteRedirect,
      ...props.routes,
    ),
  )
}
