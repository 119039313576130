export interface SiteMap {
  modules: SiteModule[]
}

export interface SiteModule {
  key: string,
  level?: string,
  name?: string,
}

export const DefaultSiteMap = {
  modules: [
    { key: 'myGrowth', level: 'admin' },
    { key: 'eLearningV2', level: 'bas' },
  ],
}