import { Header } from '../../../feature/header/component/original/organism/Header'
import { Footer } from '../../../feature/footer/component/Footer'
import { ChildrenProps } from '../../../model/React'
import { AppBox } from '../../common/analog/molecule/AppBox'
import { useViewportSizes } from '../../../hook/sizes/useViewportSizes'
import { NodeId } from '../../../util/node-id'
import { CssUnit } from '../../../feature/theme/model/CssUnit'
import { useScrollSizes } from '../../../hook/sizes/useScrollSizes'
import { SxHelperStyle } from '../../../feature/theme/style/sx-helper.style'
import { findPercentageOfNumber, Percentage } from '../../../model/Number'
import { AppContainer } from '../../common/analog/molecule/AppContainer'
import { Theme } from '@mui/material'
import { useAuthStore } from 'src/feature/auth/store/auth.store'

const NORMAL_CONTENT_HEIGHT: Percentage = 58

export function BaseLayout(props: ChildrenProps) {
  const { isAuthorized } = useAuthStore()
  const header = useScrollSizes(NodeId.HEADER)
  const content = useScrollSizes(NodeId.CONTENT)
  const viewport = useViewportSizes()

  const areHeaderAndContentLessThanViewport = (header?.scroll.height + content?.scroll.height) < viewport?.client.height
  const isContentLongerThanViewport = content?.scroll.height > viewport?.scroll.height
  const normalizedContentHeight = `${(findPercentageOfNumber(viewport?.client.height, NORMAL_CONTENT_HEIGHT)) - header?.scroll.height}px`
  const minContentHeight = isContentLongerThanViewport ? 'unset' : areHeaderAndContentLessThanViewport ? normalizedContentHeight : '100vh'
  const scrollMarginTop = header?.scroll.height ? `${header.scroll.height + 10}px` : '5rem'

  return (
    <AppBox sx={styles.root}>
      {isAuthorized ? <Header /> : null}
      <AppContainer
        id={NodeId.CONTENT}
        sx={styles.content(minContentHeight, scrollMarginTop)}
      >
        {props.children}
      </AppContainer>
      {isAuthorized ? <Footer /> : null}
    </AppBox>
  )
}

const styles = {
  root: {
    ...SxHelperStyle.flexColumn,
    position: 'relative',
    minHeight: '100%',
  },
  content: (minHeight: CssUnit, scrollMarginTop: CssUnit) => ({ breakpoints }: Theme) => ({
    margin: '0 auto',
    width: 1,
    flexGrow: 2,
    minHeight,
    scrollMarginTop,
    [breakpoints.up('xs')]: {
      padding: 0,
    },
    maxWidth: 'unset',
  }),
}
