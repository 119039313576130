import { useNavigate } from 'react-router-dom'
import { useLocalizationStore } from '../../../../feature/localization/store/localization.store'
import { observer } from 'mobx-react-lite'
import { localTranslate } from '../../../../feature/localization/model/Translate'
import { AppBaseLinkKind, AppBaseLinkProps } from '../../analog/molecule/link/AppBaseLinkKind'
import { GO_BACK_DELTA } from '../../../../feature/router/util/router.constant'

export const BaseGoBackButton = observer((props: AppBaseLinkProps) => {
  const navigate = useNavigate()
  const localization = useLocalizationStore()

  const { textValue = localTranslate(localization.translation.buttons.goBack), ...rest } = props

  const handleGoBack = () => navigate(GO_BACK_DELTA)

  return (
    <AppBaseLinkKind
      onClick={handleGoBack}
      textValue={textValue}
      {...rest}
    />
  )
})
