import { getUri } from '../model/Link'
import { APP_BASE_URL } from './constant'
import { StaticRoutePath } from '../feature/router/model/Route'

export const companyLinks = {
  address: 'https://goo.gl/maps/atPLxkFYypsUGRZe6',
  website: {
    home: 'https://yohrs.se',
    get prePurchased() {
      return getUri(this.home, 'wp-content/uploads/2022/02/Förköpsinformation-Yohrs_Inkomstförsäkring-obl-grupp_22_01.pdf')
    },
    get terms() {
      return getUri(this.home, 'villkor')
    },
  },
  socials: {
    facebook: 'https://www.facebook.com/yohrsab/',
    linkedin: 'https://www.linkedin.com/company/yohrs/',
    instagram: 'https://www.instagram.com/yohrs_com/',
  },
}

export function getAppUri(path: StaticRoutePath): string {
  if (!APP_BASE_URL) {
    throw new Error('Invalid APP_BASE_URL')
  }

  return getUri(APP_BASE_URL, path)
}
