import { NotOverridableComponentProps } from '../../../../../model/Component'
import { AppBox } from '../AppBox'

export type AppFormProps = NotOverridableComponentProps

export function AppForm(props: AppFormProps) {
  return (
    <AppBox
      {...props}
      component={'form'}
    />
  )
}
