import { axiosInstance } from '../../../util/axios/instance.axios'
import { LoginSettings } from '../model/LoginSettings'

export const getLoginSettings = async (id: string): Promise<LoginSettings> => {
  const response = await axiosInstance.request<LoginSettings>({
    url: `/b2b/login`,
    method: 'get',
    params: {
      id,
    },
  })

  return response.data
}