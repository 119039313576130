import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { FileContent } from 'use-file-picker/dist/interfaces'
import { useUserStore } from '../../user/store/user.store'
import { AppAvatar } from '../../../component/common/analog/molecule/AppAvatar'
import { AppBox } from '../../../component/common/analog/molecule/AppBox'
import { useLoader } from '../../../hook/bool/useLoader'
import { Title1 } from 'src/feature/localization/component/atom/Title1'
import { Paragraph } from 'src/feature/localization/component/atom/Paragraph'
import { useSkillAssessmentStore } from '../store/skill-assessment.store'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { SkillAssessmentLevels } from './SkillAssessmentLevels'
import { Box } from '@mui/material'
import { GoBackButtonWithArrow } from 'src/component/common/original/molecule/GoBackButtonWithArrow'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { AppCircularRating } from 'src/component/common/analog/molecule/AppCircularRating'
import { SkillSet } from '../model/skill-assessment-model'
import { AppConfetti } from 'src/component/common/analog/molecule/confetti/AppConfetti'
  
export const SkillAssessmentMenu = observer(() => {
  const { locale } = useLocalizationStore()
  const [tempAvatarDataURL] = useState<FileContent | undefined>()
  const [avatarUrl] = useState<string | undefined>()
  const [skillAssessmentSetIndex, setSkillAssessmentSetIndex] = useState<number>(0)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isAllSkillRated, setIsAllSkillRated] = useState<boolean>(false)
  const { avatar, userData } = useUserStore()
  const skillAssessmentStore = useSkillAssessmentStore()
  const loader = useLoader()
  const { isLoading } = skillAssessmentStore.getGenericFieldsState('getSkillSets')
  const showTempAvatar = (tempAvatarDataURL || avatarUrl)
  const displayedAvatar = showTempAvatar ? tempAvatarDataURL?.content ? tempAvatarDataURL?.content : avatarUrl : avatar

  useEffect(() => {
    skillAssessmentStore.skillSets = undefined
    skillAssessmentStore.getSkillSets()
  }, [])

  const handleRateSkillSetClick = (selectedSkillSetIndex: number) => {
    setSkillAssessmentSetIndex(selectedSkillSetIndex)
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    const allSkillsRated: boolean = skillAssessmentStore?.skillSets?.skills?.every(skill => skill.selectedSetLevel !== null) ?? false
    setIsAllSkillRated(allSkillsRated)
  }

  return (
    <Box>
      <AppConfetti show={isAllSkillRated} />
      <AppBox sx={styles.backButtonWrapper}>
        <GoBackButtonWithArrow />
      </AppBox>
      <AppBox sx={styles.mainContainer}>
        <AppBox sx={styles.profileContainer}>
          <AppAvatar src={displayedAvatar} outerProps={{ squareSize: 130, withOpacity: loader.isShowing }} showVerfiedMark={true} isVerified={userData?.verified} />
          {skillAssessmentStore.skillSets && <>
            <Title1 sx={{ margin: 0 }}>{skillAssessmentStore.skillSets?.category_name}</Title1>
            <Paragraph
              sx={{ hyphens: 'auto', overflowWrap: 'break-word', color: 'text.secondary' }}
              lang={locale}
            >{skillAssessmentStore.skillSets?.category_description}</Paragraph>
          </>}
        </AppBox>
        <AppBox>
          <AppProgressLine isShowing={isLoading || skillAssessmentStore.skillSets?.skills === undefined} />
          <AppBox sx={styles.ratingWrapper}>
            {skillAssessmentStore.skillSets?.skills?.map((skill: SkillSet, index: number) => (
              <AppCircularRating
                key={skill.id}
                indexKey={index}
                userRating={skill.selectedSetLevel}
                expectedRating={skill.expected_set_level}
                label={skill.setName}
                showLevelBtn={true}
                onRateSkillClick={handleRateSkillSetClick}
                viewLabel='UserAssessmentView'
              />
            ))}
          </AppBox>
        </AppBox>
      </AppBox>
      {!isLoading && skillAssessmentStore.skillSets?.skills && <SkillAssessmentLevels skillAssessmentSetIndex={skillAssessmentSetIndex} isModalOpen={isModalOpen} isModalClose={handleModalClose} />}
    </Box>
  )
})

const styles = {
  backButtonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  mainContainer: {
    flexDirection: { xs: 'column', md: 'column', lg: 'row' },
    alignItems: 'center',
    gap: '2rem',
    justifyContent: 'center',
  },
  profileContainer: {
    width: { xs: '100%', md: '100%', lg: '25%' },
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 7,
  },
  ratingWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(5, 1fr)' },
    rowGap: { xs: '1rem', md: '1rem', lg: 0 },
    columnGap: '1rem',
    alignItems: 'stretch',
    '& > :nth-of-type(5n+2), & > :nth-of-type(5n+4)': {
      lg: { marginTop: '4rem' },
    },
  },
}
