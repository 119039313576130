import * as yup from 'yup'
import { autorun } from 'mobx'
import { localizationStoreInstance } from '../../feature/localization/store/localization.store'
import { sv_SE_Yup } from './locale/sv-SE.yup'
import { en_US_Yup } from './locale/en-US.yup'
import { getIdentity } from '../helper'

autorun(() => {
  switch (localizationStoreInstance.locale) {
  case 'sv-SE':
  default:
    yup.setLocale(sv_SE_Yup)
    break
  case 'en-US':
    yup.setLocale(en_US_Yup)
    break
  }
})

const identity = getIdentity(yup)

export { identity as yupInstance }
