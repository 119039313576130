import { Box } from '@mui/material'
import { AppBox } from '../AppBox'
import { observer } from 'mobx-react-lite'
import { EmblaCarouselType } from 'embla-carousel-react'
import { useCallback, useEffect, useState } from 'react'

export const CarouselProgressBar = observer(({ emblaApi }: { emblaApi?: EmblaCarouselType }) => {
  const [scrollProgress, setScrollProgress] = useState(0)

  const onScroll = useCallback((emblaApi: EmblaCarouselType) => {
    const progress = Math.max(0, Math.min(1, emblaApi.scrollProgress()))
    setScrollProgress(progress * 100)
  }, [])

  useEffect(() => {
    if (!emblaApi) {
      return
    }

    onScroll(emblaApi)
    emblaApi.on('reInit', onScroll)
    emblaApi.on('scroll', onScroll)
  }, [emblaApi, onScroll])

  return (
    <AppBox sx={styles.progressBarContainer}>
      <Box
        sx={styles.progressBar}
        style={{
          transform: `translateX(${scrollProgress - 100}%)`,
          transition: 'width 0.7s linear',
          willChange: 'width',
        }}
      />
    </AppBox>
  )
})

const styles = {
  progressBarContainer: {
    position: 'relative',
    width: '100%',
    height: '5px',
    backgroundColor: 'progress.primary',
    borderRadius: '10px',
  },
  progressBar: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'progress.secondary',
    borderRadius: '10px',
  },
}
