import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { FaqGroupName } from '../../../contentful/model/Contentful'
import { useContentfulStore } from '../../../contentful/store/contentful.store'
import { appRoutePath } from '../../../router/util/app-route-path'
import { Title4 } from '../../../localization/component/atom/Title4'
import { useLocalizationStore } from '../../../localization/store/localization.store'
import { observer } from 'mobx-react-lite'
import { Info } from '../../../localization/component/atom/Info'
import { NavModel } from '../../../../model/Nav'
import { Sidebar } from '../../../sidebar/component/Sidebar'
import { mergeTranslationWithObjects } from '../../../localization/util/localization.helper'
import { AppPaper } from '../../../../component/common/analog/molecule/AppPaper'

interface LocationState {
  faqGroupName: FaqGroupName
}

export const KnowledgeSidebar = observer(() => {
  const [value, setValue] = useState('')
  const contentfulStore = useContentfulStore()
  const location = useLocation()
  const { translation } = useLocalizationStore()

  const state = location.state as LocationState
  const faqGroupName = state?.faqGroupName

  const nav = mergeTranslationWithObjects(translation.components.knowledgeSidebar.nav, navLinks) as unknown as NavModel

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setValue(value)

    await contentfulStore.handleFetchEntryList({
      contentName: contentfulStore.currentContentName,
      params: {
        query: value,
        faqGroupName,
      },
    })
  }

  return (
    <Sidebar
      nav={nav}
      baseSidebarProps={{
        title: translation.components.knowledgeSidebar.title,
        searchProps: {
          value,
          onChange: handleSearch,
        },
      }}
    >
      <AppPaper
        p={32}
        flexDirection={'column'}
      >
        <Title4
          textValue={translation.components.knowledgeSidebar.cannotFindAnswer.title}
          fontWeight={400}
          mb={32}
        />
        <Info
          textValue={translation.components.knowledgeSidebar.cannotFindAnswer.description}
          fz={16}
          m={0}
          lineHeight={1.5}
          color={'text.secondary'}
        />
      </AppPaper>
    </Sidebar>
  )
})

const navLinks: NavModel<'props.link'> = {
  faq: { props: { link: appRoutePath.faq } },
  additionalServices: { props: { link: appRoutePath.additionalServices } },
}
