import { Chip } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'

export type OverlayOverviewProps = {
  currentPage: number,
  setPage: (page: number) => void,
  totalPages: number,
}

export const OverlayOverview = observer((props: OverlayOverviewProps) => {
  return (
    <AppBox sx={styles.pagination}>
      {[...Array(props.totalPages).keys()].map(key => <Chip sx={styles.dot} style={ { backgroundColor: (props.currentPage === key + 1 ? 'rgb(240, 235, 235)' : '') } } key={key} onClick={() => props.setPage(key + 1)} />)}
    </AppBox>
  )
})

const styles = {
  pagination: {
    display: 'flex',
    gap: 8,
  },
  dot: {
    width: 10,
    height: 10,
  },
}