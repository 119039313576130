/* eslint-disable @typescript-eslint/ban-types */
import { ObjKey } from '../../../model/Object'
import { BaseCallbackOptions, Context } from '../../../model/Decorator'

export function TryBeginning<T extends Context>(callback: ({ context, propertyKey }: BaseCallbackOptions<T>) => any) {
  return function <TFunction extends Function>(
    _target: object | TFunction,
    propertyKey: ObjKey,
    descriptor: any = {},
  ) {
    const method = descriptor?.value

    descriptor.value = function Yo(...args: unknown[]) {
      callback({ context: this, propertyKey })

      return method.apply(this, args)
    }
  }
}
