import { createStore } from '../../../util/mobx/generic-store/store-creator'
import { getPaymentHistory } from '../api/payment'
import { GenericStoreAsyncMethod } from '../../../util/mobx/generic-store/decorator/async-method.decorator'
import { PaymentHistoryItem } from '../model/Payment'
import { userStoreInstance } from '../../user/store/user.store'
import { FIRST_INDEX } from '../../../util/constant'
import { isLength } from '../../../util/helper'
import { GenericStore } from '../../../util/mobx/generic-store/generic.store'

export class PaymentStore extends GenericStore {
  paymentHistory!: PaymentHistoryItem[]

  constructor() {
    super('PaymentStore')

    super.observe(this)
    super.persist({ encrypt: false, excludedProperties: ['paymentHistory'] })
  }

  reset() {
    this.paymentHistory = []
  }

  get isHistory() {
    return isLength(paymentStoreInstance.paymentHistory)
  }

  transformPaymentHistory(arr: PaymentHistoryItem[]): PaymentHistoryItem[] {
    return arr.map(c => ({ ...c, createdAt: c.createdAt.split('T')[FIRST_INDEX] }))
  }

  @GenericStoreAsyncMethod()
  async handleGetPaymentHistory() {
    const response = await getPaymentHistory(userStoreInstance?.userData?.email)
    this.paymentHistory = this.transformPaymentHistory(response)
  }
}

export const {
  storeInstance: paymentStoreInstance,
  useStore: usePaymentStore,
  StoreProvider: PaymentStoreProvider,
} = createStore(new PaymentStore())
