import { observer } from 'mobx-react-lite'
import { Box, Typography } from '@mui/material'
import { Palette } from 'src/feature/theme/model/Palette'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { useSkillAssessmentStore } from '../../skill-assessment/store/skill-assessment.store'
import { useState } from 'react'
import { SkillSet } from '../../skill-assessment/model/skill-assessment-model'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { ExpectedLevels } from './ExpectedLevels'
import { useNotificationStore } from 'src/feature/notification/store/notification.store'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { ModalWindow } from 'src/component/common/analog/template/ModalWindow'
import { Paragraph } from 'src/feature/localization/component/atom/Paragraph'
import { AppConfetti } from 'src/component/common/analog/molecule/confetti/AppConfetti'
import { AppTextField } from 'src/feature/form/component/analog/AppTextField'
import { AppCircularRating } from 'src/component/common/analog/molecule/AppCircularRating'

export type ExpectedRatingProps = {
  levelId: string
  onChange: () => void
}

export const ExpectedRating = observer(({ levelId, onChange }: ExpectedRatingProps) => {
  const { translation, locale } = useLocalizationStore()
  const translations = translation.pages.myTeam
  const skillAssessmentStore = useSkillAssessmentStore()
  const notificationStore = useNotificationStore()
  const { isLoading: isSkillcategory } = skillAssessmentStore.getGenericFieldsState('getSkillCategory')
  const { isLoading: isDeleteExpectedRole, isError: isDeletedExpectedRoleError } = skillAssessmentStore.getGenericFieldsState('deleteExpectedRole')
  const { isLoading: isDeleteSkillSet, isError: isDeletedSkillSetError } = skillAssessmentStore.getGenericFieldsState('deleteSkillSet')
  const { isLoading: isAddSkillSetLoader, isError: isAddSkillError } = skillAssessmentStore.getGenericFieldsState('addSkillSet')
  const isLoading = isSkillcategory || isDeleteExpectedRole || isDeleteSkillSet || isAddSkillSetLoader
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [skillAssessmentSetIndex, setSkillAssessmentSetIndex] = useState<number>(0)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [isAllSkillsRated, setIsAllSkillsRated] = useState<boolean>(false)
  const [isEditSkill, setIsEditSkill] = useState<boolean>(false)
  const [isAddSkillSet, setIsAddSkillSet] = useState<boolean>(false)
  const [skillSetTitle, setSkillSetTitle] = useState<string>('')

  const handleRateSkillSetClick = (selectedSkillSetIndex: number) => {
    setSkillAssessmentSetIndex(selectedSkillSetIndex)
    setIsModalOpen(true)
  }

  const handleDeleteExpectedRole = async () => {
    skillAssessmentStore.deleteExpectedRole(levelId).then(() => {
      if (!isDeletedExpectedRoleError) {
        onChange()
      } else {
        notificationStore.enqueueError(translation.util.unknownErrorOccurred())
      }
    })
  }

  const handleDeleteSkillSet = (skillSet: SkillSet) => {
    skillAssessmentStore.deleteSkillSet(skillSet.id, skillSet.categoryId).then(() => {
      if (!isDeletedSkillSetError) {
        skillAssessmentStore.getSkillCategory(levelId)
      } else {
        notificationStore.enqueueError(translation.util.unknownErrorOccurred())
      }
    })
  }

  const skillSetTitleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSkillSetTitle(event.target.value)
  }

  const handleAddSkillSet = () => {
    const titleRegex = /^.{1,50}$/

    if (skillSetTitle && titleRegex.test(skillSetTitle)) {
      if (skillAssessmentStore.skillCategory) {
        setIsAddSkillSet(false)
        setSkillSetTitle('')
        skillAssessmentStore.addSkillSet(skillSetTitle, skillAssessmentStore.skillCategory?.id, locale).then(() => {
          if (!isAddSkillError) {
            skillAssessmentStore.getSkillCategory(levelId)
          } else {
            notificationStore.enqueueError(translation.util.unknownErrorOccurred())
          }
        })
      } else {
        notificationStore.enqueueError(translation.util.unknownErrorOccurred())
      }
    } else {
      notificationStore.enqueueError(translations.errorMsg.roleError())
    }
  }

  const checkIfAllSkillsAreRated = () => {
    setIsModalOpen(false)
    const allSkillsRated: boolean = skillAssessmentStore?.skillCategory?.skills?.every(skill => skill.expected_set_level !== 0) ?? false
    setIsAllSkillsRated(allSkillsRated)
  }

  return (
    <AppBox sx={styles.root}>
      <AppConfetti show={isAllSkillsRated} />
      {!isLoading && skillAssessmentStore.skillCategory && <AppBox sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
        <Typography sx={{ textAlign: 'center', color: 'text.secondary' }}>{translations.setRoleConfirm()}</Typography>
        <AppBox sx={{
          display: 'grid',
          gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
          justifyContent: 'center',
          gap: 15,
        }}>
          <AppButton
            sx={isEditSkill ? { pointerEvents: 'none' } : {}}
            textValue={translations.rolesButton.updateSkill()} fontSize={14} size='small' variant={isEditSkill ? 'light' : 'contained'} color="primary" onClick={() => setIsEditSkill(!isEditSkill)}
          />
          <AppButton
            sx={!isEditSkill ? { pointerEvents: 'none' } : {}}
            textValue={translations.rolesButton.finishButton()} fontSize={14} size='small' variant={!isEditSkill ? 'light' : 'contained'} color="primary" onClick={() => setIsEditSkill(!isEditSkill)}
          />
        </AppBox>
      </AppBox>}
      {!isLoading && skillAssessmentStore.skillCategory && <AppBox sx={styles.ratingWrapper}>
        {!isLoading && skillAssessmentStore.skillCategory && skillAssessmentStore.skillCategory?.skills?.length !== 0 && skillAssessmentStore.skillCategory?.skills?.map((skill: SkillSet, index: number) => (
          <AppCircularRating
            key={skill.id}
            indexKey={index}
            expectedRating={skill.expected_set_level}
            label={skill.setName}
            showLevelBtn={true}
            onRateSkillClick={handleRateSkillSetClick}
            showRemoveBtn={isEditSkill}
            onRemoveSkillClick={() => handleDeleteSkillSet(skill)}
            viewLabel='ManagerView'
          />
        ))}
        {skillAssessmentStore.skillCategory && skillAssessmentStore.skillCategory?.skills?.length !== 15 && isEditSkill && <Box
          sx={{
            ...styles.progressWrapper,
            position: 'relative',
            display: 'inline-flex',
            width: '180px',
            height: '180px',
            backgroundColor: 'background.default',
            opacity: 0.7,
          }}
        >
          <Caption
            variant="caption"
            component="div"
            sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <AppButton sx={styles.buttonContainer} onClick={() => setIsAddSkillSet(true)}>{translation.pages.editPositions.addPositionButton()}</AppButton>
          </Caption>
        </Box>}
      </AppBox>}
      {!isLoading && skillAssessmentStore.skillCategory && isEditSkill && <AppBox sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <AppButton sx={styles.deleteBtn} textValue={translations.rolesButton.deleteRole()} fontSize={14} size='small' variant='contained' onClick={() => setShowConfirmDelete(true)} />
      </AppBox>}
      {isModalOpen && <ExpectedLevels skillAssessmentSetIndex={skillAssessmentSetIndex} isModalOpen={isModalOpen} isModalClose={checkIfAllSkillsAreRated} />}
      {showConfirmDelete && <ModalWindow
        modalProps={{ open: showConfirmDelete, onClose: () => setShowConfirmDelete(false) }}
      >
        <AppBox sx={{ flexDirection: 'column' }}>
          <Paragraph textValue={translations.deleteRoleConfirmation()} m={5} />
          <AppBox sx={{ gap: 10, justifyContent: 'flex-end' }}>
            <AppButton
              textValue={translation.buttons.cancel}
              variant={'text'}
              size='small'
              color="primary"
              onClick={() => setShowConfirmDelete(false)}
            />
            <AppButton
              textValue={translation.buttons.confirm}
              size='small'
              variant="contained"
              color="primary"
              onClick={handleDeleteExpectedRole}
            />
          </AppBox>
        </AppBox>
      </ModalWindow>}
      {isAddSkillSet && <ModalWindow
        modalProps={{ open: isAddSkillSet, onClose: () => setIsAddSkillSet(false) }}
        titleProps={{ textValue: translation.pages.editPositions.addPositionButton() }}
      >
        <AppBox sx={{ flexDirection: 'column' }}>
          <AppTextField defaultValue={skillSetTitle} onChange={skillSetTitleChanged} fullWidth required />
          <AppBox sx={{ gap: 10, justifyContent: 'flex-end' }}>
            <AppButton
              textValue={translation.buttons.cancel}
              variant={'text'}
              size='small'
              color="primary"
              onClick={() => setIsAddSkillSet(false)}
            />
            <AppButton
              textValue={translation.buttons.confirm}
              size='small'
              variant="contained"
              color="primary"
              disabled={!skillSetTitle}
              onClick={handleAddSkillSet}
            />
          </AppBox>
        </AppBox>
      </ModalWindow>}
    </AppBox>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2rem',
  },
  progressWrapper: {
    borderRadius: '50%',
    transition: 'all 0.3s',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)',
    },
  },
  ratingWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(5, 1fr)' },
    justifyContent: 'center',
    rowGap: '1.5rem',
    columnGap: '3rem',
  },
  buttonContainer: {
    backgroundColor: Palette.BRIGHT_GREY_2,
    color: Palette.BLACK_OUT,
    borderRadius: '20px',
    padding: '5px 10px',
    fontSize: '11px',
    fontWeight: '400',
    borderColor: Palette.BRIGHT_GREY_2,
    transition: 'transform 0.3s ease, background-color 0.3s ease, color 0.3s ease',
    '&:hover': {
      backgroundColor: 'alternate.primary',
      color: 'alternate.text',
      transform: 'scale(1.1)',
    },
  },
  deleteBtn: {
    color: `${Palette.WHITE} !important`,
    border: `0.5px solid ${Palette.DANGER} !important`,
    backgroundColor: `${Palette.DANGER} !important`,
    transition: 'transform 0.3s ease, border 0.3s ease, background-color 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
      color: `${Palette.WHITE} !important`,
      border: `0.5px solid ${Palette.DANGER}cc !important`,
      backgroundColor: `${Palette.DANGER}cc !important`,
    },
  },
}