import { $mobx, isObservable, makeObservable } from 'mobx'
import { AnyObject } from '../../model/Object'

const annotationsSymbol = Symbol('annotationsSymbol')
const objectPrototype = Object.prototype

/*
  learn more:
    * docs explanation - https://github.com/mobxjs/mobx/discussions/2850
    * solution source - https://github.com/mobxjs/mobx/discussions/2850
 */

export function safe_makeAutoObservable(target: any, overrides: AnyObject = {}, options: AnyObject = {}): void {
  if (isObservable(target)) {
    throw new Error('Target must not be observable')
  }

  let annotations = target[annotationsSymbol]

  if (!annotations) {
    annotations = {}
    let current = target

    while (current && current !== objectPrototype) {
      Reflect.ownKeys(current).forEach((key) => {
        if (key === $mobx || key === 'constructor') {
          return
        }
        annotations[key] = !overrides ? true : key in overrides ? overrides[key] : true
      })
      current = Object.getPrototypeOf(current)
    }

    const proto = Object.getPrototypeOf(target)

    if (proto && proto !== objectPrototype) {
      Object.defineProperty(proto, annotationsSymbol, { value: annotations })
    }
  }

  return makeObservable(target, annotations, options)
}
