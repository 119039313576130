import { Stage, stage } from '../stage'
import { isBrowser } from '../model/DOM'

// REFACTOR
// FIX: add url for all environments
export const API_BASE_URL = (() => {
  switch (stage) {
  case Stage.local:
    return `http://localhost:3000`
  case Stage.dev:
    return `https://api-${Stage.dev}.yohrs.tech`
  case Stage.qa:
    return `https://api-${Stage.qa}.yohrs.tech`
  case Stage.staging:
    return `https://api-${Stage.staging}.yohrs.tech`
  case Stage.prod:
    return `https://api.yohrs.se`
  default:
    throw new Error('Undefined API_BASE_URL!')
  }
})()

export const IS_PRODUCTION_LIKE = stage === Stage.prod || stage === Stage.staging

export const APP_BASE_URL = typeof isBrowser === 'function'
  ? isBrowser() ? window.location.origin : undefined
  : undefined

export const FIRST_INDEX = 0

export const SECOND_INDEX = 1

export const STRING_START_INDEX = 0

export const PAGINATION_START_PAGE = 1

export const CONTACT_EMAIL = 'hello@yohrs.se'

export const COMPANY_NAME = 'Yohrs'

export const COMPANY_SITE_URL = 'https://yohrs.se'

export const GOODHABITS_ID = '4fc893bd-1ae8-496e-ae3e-b5e757519802'

export const DIPLOMA_ID = 'e8561362-1fdf-4b97-86d3-385fa63f3e41'

export const UDEMY_ID = 'ea0af619-56d5-4cd3-89b1-a47e27c96930'

export const UDEMY_ID_SUB_DOMAIN = (() => {
  switch (stage) {
  case Stage.local:
  case Stage.dev:
  case Stage.qa:
  case Stage.staging:
  case Stage.prod:
    return `yohrs-test`
  default:
    throw new Error('Undefined UDEMY_ID_SUB_DOMAIN!')
  }
})()