import { YohrsUser } from '../../user/model/User'
import { isProdLike } from '../../../stage'
import { HubSpotContactProperties } from '../model/HubSpot'
import { createStore } from '../../../util/mobx/generic-store/store-creator'
import { userStoreInstance } from '../../user/store/user.store'
import { GenericStore } from '../../../util/mobx/generic-store/generic.store'
import { hubSpotUpdateOrCreateContact } from '../api/hub-spot'
import { isNotNullish } from '../../../model/Nullish'

export class HubSpotStore extends GenericStore {
  contactProperties!: HubSpotContactProperties | undefined
  hubSpotContactId = userStoreInstance?.userData?.hubSpotContactId

  constructor() {
    super('HubSpotStore')

    super.observe(this)
    this.reset()
    super.persist({ encrypt: false })
  }

  get isHubSpotContact(): boolean {
    return isNotNullish(this.hubSpotContactId)
  }

  reset() {
    this.hubSpotContactId = undefined
    this.contactProperties = undefined
  }

  prepareUserDataHubSpot(userData: Partial<YohrsUser>): HubSpotContactProperties {
    const { email, firstName, lastName, phoneNumber } = userData

    return { email, firstName, lastName, phoneNumber }
  }

  async handleUpdateOrCreateContact(userData: Partial<YohrsUser>) {
    if (isProdLike) {
      const prepared = this.prepareUserDataHubSpot(userData)
      this.contactProperties = await hubSpotUpdateOrCreateContact(prepared)
    }
  }

  async handleCreateContact(userData: Partial<YohrsUser>) {
    await this.handleUpdateOrCreateContact(userData)
  }
}

export const {
  storeInstance: hubSpotStoreInstance,
  useStore: useHubSpotStore,
  StoreProvider: HubSpotStoreProvider,
} = createStore(new HubSpotStore())
