import { observer } from 'mobx-react-lite'
import { TranslatableBoxProps } from '../../../localization/component/atom/TranslatableBox'
import { handlePassedSx } from '../../../theme/model/Sx'
import { AppStyle } from '../../../theme/style/app.style'
import ArrowDownOutlinedIcon from 'src/feature/image/asset/icon/arrow-down-outlined.svg'
import { NotOverridableComponentProps } from '../../../../model/Component'
import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { Theme } from '@mui/material'

export type AppSelectProps = NotOverridableComponentProps<TranslatableBoxProps>

export const AppSelect = observer((props: AppSelectProps) => {
  const { sx = [], ...rest } = props

  return (
    <AppBox
      component={'select'}
      sx={[
        AppStyle.outlinedClickable,
        styles,
        ...handlePassedSx(sx),
      ]}
      {...rest}
    />
  )
})

const styles = ({ handleCssUnit }: Theme) => ({
  backgroundSize: handleCssUnit(12),
  backgroundPosition: `center right ${handleCssUnit(20)}`,
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${ArrowDownOutlinedIcon})`,
})
