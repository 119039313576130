import { BrowserRouter } from 'react-router-dom'
import { MainStoreProvider } from '../../../store/main.store'
import { AuthStoreProvider } from '../../../feature/auth/store/auth.store'
import { UserStoreProvider } from '../../../feature/user/store/user.store'
import { HubSpotStoreProvider } from '../../../feature/hub-spot/store/hub-spot.store'
import { ContentfulStoreProvider } from '../../../feature/contentful/store/contentful.store'
import { TypesafeI18nProvider } from '../../../feature/localization/component/provider/TypesafeI18nProvider'
import { AppThemeProvider } from 'src/feature/theme/component/provider/AppThemeProvider'
import { StrictMode } from 'react'
import { useChainProviders as createReactProviders } from 'react-flat-providers' // use alias here because of ESLint react hooks rule (lol)
import { LocalizationStoreProvider } from '../../../feature/localization/store/localization.store'
import { MaintenanceStoreProvider } from '../../../feature/maintenance/store/maintenance.store'
import { PaymentStoreProvider } from '../../../feature/payment/store/payment.store'
import { SnackbarProvider } from 'notistack'

export const AppProvider = createReactProviders()
// TODO fix this, https://react.dev/learn/you-might-not-need-an-effect and https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
  .add(StrictMode)
  .add(BrowserRouter)
  .add(LocalizationStoreProvider)
  .add(AppThemeProvider)
  .add(TypesafeI18nProvider)
  .add(MainStoreProvider)
  .add(MaintenanceStoreProvider)
  .add(PaymentStoreProvider)
  .add(AuthStoreProvider)
  .add(UserStoreProvider)
  .add(HubSpotStoreProvider)
  .add(ContentfulStoreProvider)
  .add(SnackbarProvider)
  //
  .make()
