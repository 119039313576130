import { useForumStore } from '../../store/forum.store'
import { observer } from 'mobx-react-lite'
import { Sidebar } from '../../../sidebar/component/Sidebar'
import { useNotificationStore } from '../../../notification/store/notification.store'
import Box from '@mui/material/Box'

export const ForumSidebar = observer(() => {
  const forum = useForumStore()
  const notificationStore = useNotificationStore()
  const handleSearchPosts = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      await forum.handleSearchPosts({ searchQuery: e.target.value })
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notificationStore.enqueueError(e.message ? e.message : 'ERROR')
    }
  }

  return (
    <Box sx={styles.box}>
      <Sidebar
        baseSidebarProps={{
          showDivider: false,
          searchProps: {
            onChange: handleSearchPosts,
            value: forum.searchQuery,
          },
        }}
      >
      </Sidebar>
    </Box>
  )
})

const styles = {
  box: {
    height: '100%',
    background: 'rgba(82,27,238,0.3)',
    padding: 10,
  },
}
