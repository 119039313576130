import { AppBox } from '../../analog/molecule/AppBox'
import { CircularProgress, GlobalStyles, Theme } from '@mui/material'
import { IsTrue } from '../../../../model/Boolean'
import { SxHelperStyle } from '../../../../feature/theme/style/sx-helper.style'

interface Props {
  isShown: IsTrue
}

export function FullscreenLoader(props: Props) {
  const { isShown } = props

  if (!isShown) {
    return null
  }

  return (
    <>
      <GlobalStyles styles={styles.global} />

      <AppBox sx={styles.fullScreenLayer}>
        <CircularProgress
          size={80}
          sx={styles.loader}
          color={'primary'}
        />
      </AppBox>
    </>
  )
}

const styles = {
  global: {
    body: {
      overflow: 'hidden',
    },
  },
  fullScreenLayer: ({ palette }: Theme) => ({
    ...SxHelperStyle.absoluteFullScreen,
    ...SxHelperStyle.flexCenter,
    backgroundColor: palette.background.default,
    zIndex: 4000,
  }),
  loader: {
    zIndex: 4100,
    position: 'absolute',
  },
}
