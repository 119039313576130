import lodashMapValues from 'lodash/mapValues'
import { isLength } from '../../../util/helper'
import { localTranslate } from '../model/Translate'
import lodashMerge from 'lodash/merge'
import lodashToPlainObject from 'lodash/toPlainObject'
import { TranslationModel } from '../model/Translation'
import { AnyObject, checkAreObjKeys } from '../../../model/Object'

// We do it because we work with Proxies
export function normalizeTranslationObj<T extends TranslationModel>(obj: T): T {
  const fn = lodashToPlainObject

  return (
    checkAreObjKeys(obj) || isLength(Array.from(obj))
      ? lodashMapValues(fn(obj), c => normalizeTranslationObj(c))
      : obj
  ) as T
}

export function mergeTranslationWithObjects<T extends TranslationModel>(translation: T, ...objList: AnyObject[]) {
  return lodashMerge(
    {},
    ...objList,
    normalizeTranslationObj(translation), // Important: translation should be the latest element! Why? Idk yet...(just otherwise the result will be a proxy)
  )
}

export function mergeTranslationByIds(target: TranslationModel, source: AnyObject, idKey = 'id') {
  return lodashMapValues(target, (baseItem) => {
    if (!checkAreObjKeys(source)) {
      return baseItem
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const idValue = localTranslate(baseItem?.[idKey]) || ''
    const extendedItem = source?.[idValue]

    if (!extendedItem) {
      return baseItem
    }

    return lodashMerge(baseItem, extendedItem)
  })
}
