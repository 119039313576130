import { axiosInstance } from '../../../util/axios/instance.axios'
import { BaseInfoFormModel } from '../../on-boarding/model/BaseInfo'
import { SignInFormModel } from '../model/SignIn'
import { YohrsUserDataResponse } from '../../user/model/Response'
import { AnyObject } from '../../../model/Object'
import { SamlResponse } from '../page/SsoVerifyPage'

const AUTH_API_PREFIX = '/app/'

export function getAuthApiUrl(path: string) {
  return AUTH_API_PREFIX + path
}

export const authApiUrl = {
  updateAccessToken: getAuthApiUrl('update-access-token'),
  signIn: getAuthApiUrl('sign-in'),
}

export const signUp = async (data: BaseInfoFormModel): Promise<YohrsUserDataResponse> => {
  const response = await axiosInstance.request<YohrsUserDataResponse>({
    url: getAuthApiUrl('sign-up'),
    method: 'post',
    data,
  })

  return response.data
}

export const getGoogleUser = async (accessToken: string): Promise<AnyObject> => {
  const { data } = await axiosInstance.request({
    url: `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${accessToken}`,
  })

  return data
}

export const signUpByGoogle = async (access_token: string): Promise<AnyObject> => {
  return getGoogleUser(access_token)
}

export const signIn = async (data: SignInFormModel): Promise<YohrsUserDataResponse> => {
  const response = await axiosInstance.request<YohrsUserDataResponse>({
    url: authApiUrl.signIn,
    method: 'post',
    data: {
      ...data,
      google: false,
    },
  })

  return response.data
}

export const signInByToken = async (token: string): Promise<YohrsUserDataResponse> => {
  const response = await axiosInstance.request<YohrsUserDataResponse>({
    url: `/b2b/login/sign-in?token=${token}`,
    method: 'post',
  })

  return response.data
}

export const signOut = async () => {
  return axiosInstance({
    url: getAuthApiUrl('sign-out'),
    method: 'delete',
  })
}

export async function updateAccessToken(): Promise<string> {
  const tryUpdate = async () => {
    const { data } = await axiosInstance.request<{accessToken: string}>({
      url: authApiUrl.updateAccessToken,
      method: 'post',
    })

    return data.accessToken
  }

  const t = tryUpdate().catch(_ => '')
  
  return t
}

export async function forgotPassword(email: string) {
  return axiosInstance({
    url: getAuthApiUrl('forgot-password'),
    method: 'post',
    data: { email },
  })
}

export const resetPassword = async (password: string, confirmPassword: string, token: string) => {
  return axiosInstance({
    url: getAuthApiUrl('reset-password'),
    method: 'patch',
    data: {
      password,
      confirmPassword,
      token,
    },
  })
}

export const verifyAccount = async (email: string, hashToken: string) => {
  return axiosInstance({
    url: getAuthApiUrl('verify-account'),
    method: 'patch',
    data: {
      email,
      hashToken,
    },
  })
}

export async function getMaintenanceState() {
  const { data } = await axiosInstance({
    url: getAuthApiUrl('maintenance-state'),
  })

  return data
}

export const ssoVerify = async (samlRequestId: string, provider?: string, domain?: string): Promise<SamlResponse> => {
  const { data } = await axiosInstance.request<SamlResponse>({
    url: domain ? `udemy-domain/sso/verify/${domain}?SAMLRequest=${samlRequestId}` : provider ? `${provider}/sso/verify?SAMLRequest=${samlRequestId}` : `saml/sso/verify?SAMLRequest=${samlRequestId}`,
    method: 'post',
  })

  return data
}
