import { Asset, Entry, EntryFields } from 'contentful'
import { FIRST_INDEX, STRING_START_INDEX } from '../../../util/constant'

// Articles
export interface ArticleModel {
  title: string
  subtitle?: string
  date: string
  image: Asset
  body: EntryFields.RichText
}

// E-platform
export interface PlatformModel {
  title: string
  hrefLinkText: string
  href: string
  picture: Asset
  description: EntryFields.RichText
}

// FAQ
export type FaqGroupName = string

export interface FaqGroup {
  name: FaqGroupName
  image: Asset
}

export interface FaqArticle {
  question: string
  answer: EntryFields.RichText
  faqGroupName: FaqGroupName
  attachments?: Entry<Attachment>[]
  hidden: boolean
  author?: string
}

export interface Attachment {
  title: string
  description: string
  file: AttachmentFile
}

export interface AttachmentFile {
  contentType: string
  fileName: string
  url: string
}

// Assets
export interface AssetData {
  url: string
  alt: string
}

export function getAssetData(asset: Asset): AssetData {
  return {
    url: asset?.fields?.file?.url,
    alt: asset?.fields?.file?.fileName,
  }
}

export function getContentfulTextBrief(richText: EntryFields.RichText, maxLength = 250) {
  const firstParagraph = richText.content.find(node => node.nodeType === 'paragraph')

  if (!firstParagraph) {
    return 'No content'
  }

  // cut paragraph
  return firstParagraph?.content?.[FIRST_INDEX]?.value?.substring(STRING_START_INDEX, maxLength)
}
