import Button, { ButtonProps } from '@mui/material/Button'
import {
  TranslatableBox,
  TranslatableBoxProps,
} from '../../../../../feature/localization/component/atom/TranslatableBox'

export type AppButtonProps = Omit<TranslatableBoxProps, 'variant'> & ButtonProps;

export function AppButton(props: AppButtonProps) {
  const { component, ...rest } = props

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <TranslatableBox
      component={(componentProps: ButtonProps) => <Button {...componentProps} component={component || 'button'} style={{ border: 'none' }} />}
      {...rest}
    />
  )
}
