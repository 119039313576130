import { OverrideFunc } from '../../../../model/Override'

export const MuiDialog: OverrideFunc<'MuiDialog'> = _theme => ({
  defaultProps: {
    PaperProps: {
      variant: 'outlined',
      elevation: 0,
    },
  },
  styleOverrides: {
    root: {
      border: '0px',
    },
  },
})
