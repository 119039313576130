import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useELearningStore } from '../../store/e-learning.store'
import { ELearningCourseCategory } from '../../model/ELearningCourseCategory'
import { CategoryItem } from './CategoryItem'
import { Box, Fade, Typography, useMediaQuery } from '@mui/material'
import { AppCarousel } from 'src/component/common/analog/molecule/carousel/AppCarousel'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { CourseListPresentationItem } from './CourseListPresentationItem'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { CategorySearch } from './CategorySearch'

export const CategoryList = observer(() => {
  const eLearningStore = useELearningStore()
  const { translation } = useLocalizationStore()
  const [searchTerm, setSearchTerm] = useState('')
  const { isLoading: isLoadingAllCourses } = eLearningStore.getGenericFieldsState('getAllCourses')
  const { isLoading: isLoadingSearch } = eLearningStore.getGenericFieldsState('search')
  const isLoading = isLoadingAllCourses || isLoadingSearch
  const isSmallScreen = useMediaQuery('(max-width:900px)')
  const texts = translation.pages.eLearning
  const pageSize = 30
  
  useEffect(() => {
    eLearningStore.clearSearch()
    eLearningStore.selectedFilters = undefined
    if (eLearningStore.categories === undefined) {
      eLearningStore.loadCategoriesIfNeeded()
    }
    eLearningStore.getAllCourses('all', 0, pageSize)
  }, [])

  const transform = (category: ELearningCourseCategory, index: number) => {
    return <CategoryItem key={category.id} item={category} index={index} />
  }

  const extendResults = async () => {
    if (eLearningStore.courseList && eLearningStore.courseList.length !== eLearningStore.courseCount) {
      if (searchTerm.length > 0) {
        await eLearningStore.appendSearch(searchTerm, eLearningStore.courseList.length, pageSize)
      } else {
        await eLearningStore.appendAllCourses('all', eLearningStore.courseList.length, pageSize)
      }
    }
  }

  return (
    <AppBox sx={styles.root}>
      <CategorySearch setSearchTerm={setSearchTerm} />
      {!isSmallScreen && <Box sx={styles.chipContainer}>
        {eLearningStore.categories && eLearningStore.categories.map(transform)}
      </Box>}
      {isSmallScreen && <AppCarousel showProgressBar={false}>
        {eLearningStore.categories && eLearningStore.categories.map(transform)}
      </AppCarousel>
      }
      {<AppProgressLine isShowing={isLoading} />}
      {eLearningStore.courseCount !== undefined && eLearningStore.courseCount !== 0 && <Typography variant='h4'>{`${eLearningStore.courseCount} ${texts.courses().toLowerCase()}`}</Typography>}
      {!isLoading && eLearningStore.courseList && eLearningStore.courseList.length === 0 && <Caption sx={{ color: 'text.secondary' }} textValue={texts.missingCourse} />}
      {!isLoading && eLearningStore.courseList && eLearningStore.courseList.length > 0 && (
        <Fade in={eLearningStore.courseList?.length > 0} mountOnEnter unmountOnExit>
          <AppBox sx={styles.courseList}>
            {eLearningStore.courseList.map(course => (
              <CourseListPresentationItem key={course.id} item={course} />
            ))}
          </AppBox>
        </Fade>
      )}
      {!isLoading && eLearningStore.courseList && eLearningStore.courseList.length > 0 && eLearningStore.courseList.length !== eLearningStore.courseCount && (
        <AppBox sx={{ justifyContent: 'center' }}>
          <AppButton fontSize={17} size='small' variant="contained" color="primary" onClick={extendResults}>{translation.buttons.loadMore()}</AppButton>
        </AppBox>
      )}
    </AppBox>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },
  chipContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    flexWrap: 'wrap',
  },
  courseList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: { xs: 'center', lg: 'flex-start' },
    gap: 10,
  },
}
