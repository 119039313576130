import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { NodeId } from 'src/util/node-id'

export function useScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    const mainElement = document.getElementById(NodeId.CONTENT)

    if (mainElement) {
      mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [pathname])
}
