import { LinkPath } from './Link'

export type PathToPublicFolder = string

export function getPathVariants(path: LinkPath) {
  return [path, `${path}/`]
}

export function isPathExactActive(currentPath: LinkPath, givenPath: LinkPath) {
  return getPathVariants(givenPath).includes(currentPath)
}

export function isPathActive(currentPath: LinkPath, givenPath: LinkPath) {
  if (!givenPath) {
    return false
  }
  if (currentPath === givenPath) {
    return true
  }

  const givenPathWithSlash = givenPath.endsWith('/') ? givenPath : `${givenPath}/`
  
  return currentPath.startsWith(givenPathWithSlash) && !currentPath.slice(givenPathWithSlash.length).startsWith('/')
}

export function isPathStartsWith(currentPath: LinkPath, givenPath: LinkPath) {
  return currentPath.startsWith(givenPath)
}

export function getSearchQuery(name: string): string {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  })

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return params[name]
}
