import { axiosInstance } from '../util/axios/instance.axios'

export async function getBase64FromFile(file: Blob | File): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      resolve(reader.result as string)
    }
  })
}

export async function getBase64FromUrl(url: string): Promise<string> {
  const { data: blob } = await axiosInstance({ url, responseType: 'blob' })

  return getBase64FromFile(blob)
}
