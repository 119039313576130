import { observer } from 'mobx-react-lite'
import { useLocalizationStore } from '../../../../localization/store/localization.store'
import { useLocation } from 'react-router-dom'
import { appRoutePath } from '../../../../router/util/app-route-path'
import { useBreakpoints } from '../../../../theme/hook/useBreakpoints'
import { MobileHeader } from '../template/MobileHeader'
import { DesktopHeader } from '../template/DesktopHeader'
import { LINK_LABEL_CLASS_NAME, NavListItem } from 'src/component/common/original/molecule/NavListItem'
import { TranslatableText } from 'src/feature/localization/component/atom/TranslatableText'
import { Palette } from 'src/feature/theme/model/Palette'
import { LocalesButton } from 'src/feature/localization/component/molecule/LocalesButton'
import { ThemeButton } from 'src/feature/theme/component/provider/ThemeButton'

export const PublicHeader = observer(() => {
  const { translation } = useLocalizationStore()
  const { pathname } = useLocation()
  const { isMd } = useBreakpoints()

  const authButtonText = translation.util.signUp

  return (
    <>
      {isMd ? (
        <MobileHeader pathname={pathname}>
          <NavListItem
            key={'signIn'}
            textValue={translation.util.signIn}
            isMain={true}
            link={appRoutePath.signIn}
          >
            <TranslatableText component={'p'} className={LINK_LABEL_CLASS_NAME} textValue={translation.util.signIn} color={Palette.WHITE} />
          </NavListItem>
          <NavListItem
            key={'signUp'}
            textValue={authButtonText}
            isMain={true}
            link={appRoutePath.signUp}
          >
            <TranslatableText component={'p'} className={LINK_LABEL_CLASS_NAME} textValue={authButtonText} color={Palette.WHITE} />
          </NavListItem>
          <ThemeButton />
          <LocalesButton />
        </MobileHeader>
      ) : (
        <DesktopHeader listProps={{ spacing: 26 }}>
          <NavListItem
            key={'signIn'}
            textValue={translation.util.signIn}
            isMain={true}
            link={appRoutePath.signIn}
          >
            <TranslatableText component={'p'} className={LINK_LABEL_CLASS_NAME} textValue={translation.util.signIn} color={Palette.WHITE} />
          </NavListItem>
          <NavListItem
            key={'signUp'}
            textValue={authButtonText}
            isMain={true}
            link={appRoutePath.signUp}
          >
            <TranslatableText component={'p'} className={LINK_LABEL_CLASS_NAME} textValue={authButtonText} color={Palette.WHITE} />
          </NavListItem>
          <ThemeButton />
          <LocalesButton />
        </DesktopHeader>
      )}
    </>
  )
})
