import { enUS, Localization, svSE } from '@mui/material/locale'
import { Locales } from '../util/i18n/i18n-types'
import { Name } from './Text'
import GBFlag from '../asset/flag/gb-flag-2.svg'
import SVFlag from '../asset/flag/sv-flag-2.svg'

export interface LocaleData {
  endonym: Name
  muiSystem: Localization
  icon: string
  id: Locales
}

export type AllLocalesModel = {
  [key in Locales]: LocaleData
}

export const localesObj = {
  'sv-SE': {
    id: 'sv-SE',
  },
  'en-US': {
    id: 'en-US',
  },
} as const

export const allLocalesData: AllLocalesModel = {
  'sv-SE': {
    endonym: 'Svenska',
    muiSystem: svSE,
    id: 'sv-SE',
    icon: SVFlag,
    // icon: 'https://catamphetamine.gitlab.io/country-flag-icons/3x2/SE.svg',
  },
  'en-US': {
    endonym: 'English',
    muiSystem: enUS,
    id: 'en-US',
    icon: GBFlag,
    // icon: 'https://catamphetamine.gitlab.io/country-flag-icons/3x2/GB.svg',
  },
}
