import { getRouteObjectList, withLayout } from '../router/util/router.helper'
import { RouteObjectList } from '../router/model/Route'
import { platformRouteIds } from '../router/model/RouteId'
import { RouterLayout } from '../router/component/layout/RouterLayout'

export const ELearningRoutes: RouteObjectList = [
  withLayout({
    layout: <RouterLayout />,
    routes: getRouteObjectList(platformRouteIds),
  }),
]
