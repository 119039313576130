import { createStore } from '../../../util/mobx/generic-store/store-creator'
import { Maybe } from '../../../model/Util'
import { getMaintenanceState } from '../../auth/api/auth'
import { GenericStoreAsyncMethod } from '../../../util/mobx/generic-store/decorator/async-method.decorator'
import { GenericStore } from '../../../util/mobx/generic-store/generic.store'

export class MaintenanceStore extends GenericStore {
  isMaintenance!: Maybe<boolean>

  constructor() {
    super('MaintenanceStore')

    super.observe(this)
    super.persist({ encrypt: false })
  }

  reset() {
    this.isMaintenance = null
  }

  changeIsMaintenance(value: this['isMaintenance']) {
    this.isMaintenance = value
  }

  @GenericStoreAsyncMethod()
  async handleGetMaintenanceState() {
    this.isMaintenance = await getMaintenanceState()
  }
}

export const {
  storeInstance: maintenanceStoreInstance,
  useStore: useMaintenanceStore,
  StoreProvider: MaintenanceStoreProvider,
} = createStore(new MaintenanceStore())
