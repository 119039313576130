import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppSearchBar } from 'src/component/common/analog/molecule/AppSearchBar'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { Box } from '@mui/material'
import { GoBackButtonWithArrow } from 'src/component/common/original/molecule/GoBackButtonWithArrow'

export type CategorySearchProps = {
  categoryKey: string
  setSearchTerm: (term: string) => void
}

export const CourseSearch = observer(({ categoryKey, setSearchTerm }: CategorySearchProps) => {
  const { translation } = useLocalizationStore()
  const { categories } = translation.pages.eLearning
  type ObjectKey = keyof typeof categories
  const texts = categories[categoryKey as ObjectKey]

  const handleSearch = debounce(async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 1) {
      setSearchTerm(event.target.value)
    } else if (event.target.value.length === 0) {
      setSearchTerm('')
    }
  }, 750)

  return (
    <Box sx={styles.root}>
      <GoBackButtonWithArrow sx={{ margin: '0 !important' }} textValue={`${translation.pages.eLearning.courses()} > ${texts.title()}`} />
      <AppBox sx={styles.searchbarContainer}>
        <AppSearchBar onChange={handleSearch} />
      </AppBox>
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: { lg: 'row', md: 'row', xs: 'column' },
    justifyContent: { lg: 'space-between', md: 'space-between', xs: 'center' },
    alignItems: 'center',
    width: '100%',
    gap: 30,
  },
  searchbarContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    width: { lg: '30rem', md: '25rem', sm: '20rem' },
  },
}
