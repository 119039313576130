import { observer } from 'mobx-react-lite'
import { ELearningCourseCategory } from '../../model/ELearningCourseCategory'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { Chip, Fade, Icon } from '@mui/material'
import { iconMapping } from 'src/util/icon-mapping'

export type CategoryItemProp = {
  item: ELearningCourseCategory
  index: number
}

export const CategoryItem = observer(({ item, index }: CategoryItemProp) => {
  const { translation } = useLocalizationStore()
  const navigate = useNavigate()
  const { categories } = translation.pages.eLearning
  type ObjectKey = keyof typeof categories
  const texts = categories[item.titleLocale as ObjectKey]

  return (
    <Fade in={true} timeout={index * 100} mountOnEnter unmountOnExit>
      <Chip
        key={index}
        variant='outlined'
        icon={<Icon sx={{ color: 'secondary.main', height: '18px', width: '18px' }} color='inherit' component={iconMapping[texts.icon()]} />}
        sx={styles.chip}
        label={texts.title()}
        onClick={() => navigate(appRoutePath.learningCategory(item.titleLocale))}
      />
    </Fade>
  )
})

const styles = {
  chip: {
    fontSize: '14px',
    padding: '0.5rem 0.75rem',
    transition: 'backgroundColor 0.3s ease, borderColor 0.3s ease',
    '&:hover': {
      backgroundColor: 'unset !important',
      borderColor: 'secondary.main',
    },
    ':active': {
      boxShadow: 'unset !important',
    },
  },
}
