import { observer } from 'mobx-react-lite'
import { DialogProps } from '@mui/material'
import { Title2 } from '../../../../feature/localization/component/atom/Title2'
import { AppBox, AppBoxProps } from '../molecule/AppBox'
import { AppUnstyledButton } from '../molecule/button/AppUnstyledButton'
import { IsTrue } from '../../../../model/Boolean'
import { TranslatableTextVariantProps } from '../../../../feature/localization/component/atom/TranslatableText'
import { AppDialogContent, AppDialogContentProps } from '../molecule/dialog/AppDialogContent'
import { ChildrenProps } from '../../../../model/React'
import { handlePassedSx } from '../../../../feature/theme/model/Sx'
import { AppDialog } from '../molecule/dialog/AppDialog'
import { SxHelperStyle } from '../../../../feature/theme/style/sx-helper.style'
import CloseIcon from '@mui/icons-material/Close'
import { Palette } from 'src/feature/theme/model/Palette'

export interface ModalProps extends ChildrenProps {
  modalProps: Omit<DialogProps, 'open'> & { open: IsTrue }
  disableCloseButton?: boolean
  titleProps?: TranslatableTextVariantProps
  dialogContentProps?: AppDialogContentProps
  topContent?: React.ReactNode
  topProps?: AppBoxProps
}

export const ModalWindow = observer((props: ModalProps) => {
  const {
    children,
    modalProps: {
      open,
      maxWidth = 'sm',
      transitionDuration = 200,
      scroll = 'paper',
      PaperProps: {
        sx: PaperSx = [],
        ...restPaperProps
      } = {},
      ...restRootProps
    } = {},
    topProps: {
      sx: topSx = [],
      mb: topMb = 24,
      ...restTopProps
    } = {},
    dialogContentProps: {
      sx: dialogContentSx = [],
      ...restDialogContentProps
    } = {},
    disableCloseButton,
  } = props

  return (
    <AppDialog
      TransitionComponent={props.modalProps.TransitionComponent}
      maxWidth={maxWidth}
      open={Boolean(open)}
      transitionDuration={transitionDuration}
      scroll={scroll}
      PaperProps={{
        sx: [
          styles.paper,
          ...handlePassedSx(PaperSx),
        ],
        ...restPaperProps,
      }}
      {...restRootProps}
    >
      <AppBox
        mb={topMb}
        sx={[
          styles.top,
          ...handlePassedSx(topSx),
        ]}
        {...restTopProps}
      >
        {props.titleProps ? (
          <Title2
            {...props.titleProps}
            my={0}
          />
        ) : props.topContent && (
          <>
            {props.topContent}
          </>
        )}

        {!disableCloseButton && (
          <AppUnstyledButton onClick={props.modalProps.onClose} sx={styles.closeButton} type={'button'}>
            <CloseIcon fontSize='medium' color='inherit' />
          </AppUnstyledButton>
        )}

      </AppBox>

      <AppDialogContent
        sx={[
          styles.content,
          ...handlePassedSx(dialogContentSx),
        ]}
        {...restDialogContentProps}
      >
        {children}
      </AppDialogContent>
    </AppDialog>
  )
})

const styles = {
  paper: {
    paddingTop: {
      xs: 16,
      md: 25,
    },
    paddingBottom: {
      xs: 16,
      md: 25,
    },
    paddingLeft: {
      xs: 19,
      md: 30,
    },
    paddingRight: {
      xs: 19,
      md: 30,
    },
    width: 1,
    margin: {
      xs: '10px',
      md: '30px',
    },
    backgroundColor: 'background.dark',
  },
  top: {
    ...SxHelperStyle.flexCenterX,
    justifyContent: 'space-between',
    width: 1,
  },
  closeButton: {
    ml: 'auto',
    overflow: 'unset',
    border: '2px solid',
    borderColor: `${Palette.CORAL_REEF_GREEN}30`,
    borderRadius: '50%',
    backgroundColor: `${Palette.CORAL_REEF_GREEN}30`,
    transition: 'transform 0.3s ease, borderColor 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
      borderColor: Palette.CORAL_REEF_GREEN,
    },
    color: Palette.CORAL_REEF_GREEN,
    width: '1.5rem',
    height: '1.5rem',
  },
  content: {
    padding: 0,
    margin: 0,
    width: 1,
  },
}
