/* eslint-disable @typescript-eslint/ban-types,@typescript-eslint/no-this-alias */
import { ObjKey } from '../../../model/Object'
import { Context } from '../../../model/Decorator'

interface BaseOptions<T0 extends Context> {
  context: T0
  args: unknown[]
  propertyKey: ObjKey
}

export type FinallyOptions<T0 extends Context> = BaseOptions<T0>

export interface CatchOptions<T0 extends Context> extends BaseOptions<T0> {
  error: any
}

export type CatchFn<T0 extends Context> = (options: HandleCatchOptions<T0>) => void;

export interface HandleCatchOptions<T0 extends Context> extends CatchOptions<T0> {
  errorClass: any
  handler: CatchFn<T0>
}

export type FinallyFn<T0 extends Context> = (options: FinallyOptions<T0>) => void;

function handleCatch<T0 extends Context>(options: HandleCatchOptions<T0>) {
  if (typeof options.handler === 'function' && options.error instanceof options.errorClass) {
    options.handler.call(options.context, options)
  } else {
    throw options.error
  }
}

export function CatchFinally<T0 extends Context = Context>(errorClass: any, catchHandler: CatchFn<T0>, finallyHandler?: FinallyFn<T0>) {
  return function <Target extends object | Function>(
    _target: Target,
    propertyKey: ObjKey,
    descriptor: any = {},
  ) {
    const originalMethod = descriptor?.value

    descriptor.value = function(...args: any[]) {
      let isPromise = false
      const context: T0 = this

      try {
        const result = originalMethod.apply(this, args)

        if (result && typeof result.then === 'function' && typeof result.catch === 'function') {
          isPromise = true

          return result.catch((error: any) => {
            handleCatch<T0>({ context, errorClass, handler: catchHandler, error, args, propertyKey })
          }).finally(() => {
            if (finallyHandler) {
              finallyHandler.call(context, { context, args, propertyKey })
            }
          })
        }

        return result
      } catch (error) {
        handleCatch({ context, errorClass, handler: catchHandler, error, args, propertyKey })
      } finally {
        if (!isPromise) {
          if (finallyHandler) {
            finallyHandler.call(context, { context, args, propertyKey })
          }
        }
      }
    }

    return descriptor
  }
}
