import { createTheme, StyledEngineProvider, ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import { observer } from 'mobx-react-lite'
import { ChildrenProps } from 'src/model/React'
import { getThemeOptionsByMode } from '../../util/theme.helper'
import { useLocalizationStore } from '../../../localization/store/localization.store'
import { allLocalesData } from '../../../localization/model/Locale'
import { themeStoreInstance, ThemeStoreProvider } from '../../store/theme.store'
import { useMemo } from 'react'
import { CssBaseline } from '@mui/material'

export const AppThemeProvider = observer(({ children }: ChildrenProps) => {
  const { locale } = useLocalizationStore()

  const theme = useMemo(() => {
    return createTheme(
      getThemeOptionsByMode(themeStoreInstance.mode) as any,
      allLocalesData[locale].muiSystem,
    )
  }, [themeStoreInstance.mode, locale])

  return (
    <ThemeStoreProvider>
      <StyledEngineProvider injectFirst>
        <MUIThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          {children}
        </MUIThemeProvider>
      </StyledEngineProvider>
    </ThemeStoreProvider>
  )
})
