import { NotOverridableComponentProps } from '../../../../../model/Component'
import { handlePassedSx } from '../../../../../feature/theme/model/Sx'
import { AppBox } from '../AppBox'

export type AppUnstyledListProps = NotOverridableComponentProps

export function AppUnstyledList(props: AppUnstyledListProps) {
  const { sx = [], ...rest } = props

  return (
    <AppBox
      component={'ul'}
      sx={[
        styles,
        ...handlePassedSx(sx),
      ]}
      {...rest}
    />
  )
}

const styles = {
  listStyle: 'none',
  margin: 0,
  padding: 0,
}
