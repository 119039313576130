import { axiosInstance } from '../../../util/axios/instance.axios'
import { AskQuestionFormModel } from '../model/AskQuestionForm'

export const askQuestion = async (data: AskQuestionFormModel) => {
  await axiosInstance({
    url: '/question/ask',
    method: 'post',
    data,
  })
}
