import { isStr } from './String'

export type URL = string

export type LinkPath = string

export type URI
  <T_URL extends string = string, T_PATH extends string = string, DefaultUri = URL & LinkPath>
  = T_URL extends string ? DefaultUri : T_PATH extends string ? DefaultUri : `${T_URL}/${T_PATH}`

export function getUri(url: URL, path: LinkPath): URI {
  return `${url}/${path}`
}

export type LinkType = URL | URI | LinkPath

export function checkIsExternalLink(link: LinkType): boolean {
  return isStr(link) && (link.startsWith('http') || link.startsWith('mailto'))
}

export function isUrl(value: unknown): value is URL {
  try {
    return Boolean(new URL(value as string))
  } catch (e) {
    return false
  }
}
