import { GenericStore } from '../generic.store'
import { AppLogger } from '../../../logger'
import { getFirstStr } from '../../../helper'
import { TryBeginning } from '../../../decorator/method/try-beginning'
import { CatchFinally, CatchOptions, FinallyOptions } from '../../../decorator/method/CatchFinally'

export interface MethodOptions {
  withStartLoading?: boolean
  withEndLoading?: boolean
  withAllLoading?: boolean
  throwOnError?: boolean
}

export type MethodAllProps = Required<MethodOptions>

export const defaultOptions: MethodOptions = {
  withAllLoading: true,
  throwOnError: true,
}

export function TryBeginningCustom<T extends GenericStore>(props: MethodAllProps) {
  return TryBeginning<T>(({ context, propertyKey }) => {
    context.setSuccess({
      value: null,
      callerMethodName: propertyKey,
    })

    context.setError({
      value: null,
      callerMethodName: propertyKey,
    })

    if (props.withAllLoading || props.withStartLoading) {
      context.setLoader({
        value: true,
        callerMethodName: propertyKey,
      })
    }
  })
}

const catchHandler = <T extends GenericStore>(props: MethodAllProps) => ({
  error,
  context,
  propertyKey,
}: CatchOptions<T>) => {
  AppLogger.error(`Error -> ${propertyKey.toString()}:`, error)

  context.setError({
    value: getFirstStr(error?.message || context),
    callerMethodName: propertyKey,
  })

  context.setSuccess({
    value: false,
    callerMethodName: propertyKey,
  })

  if (props.throwOnError) {
    throw error
  }
}

const finallyHandler = <T extends GenericStore>(props: MethodAllProps) => ({
  context,
  propertyKey,
}: FinallyOptions<T>) => {
  if (props.withEndLoading || props.withAllLoading) {
    context.setLoader({
      value: false,
      callerMethodName: propertyKey,
    })
  }
}

export function CatchFinallyCustom<T extends GenericStore>(props: MethodAllProps) {
  return CatchFinally(
    Error,
    catchHandler<T>(props),
    finallyHandler<T>(props),
  )
}
