import { Theme } from '@mui/material'
import { handlePassedSx } from '../../../../feature/theme/model/Sx'
import { AppBox, ContainerProps } from '../../analog/molecule/AppBox'
import { NotOverridableComponentProps } from '../../../../model/Component'

export type NarrowContainerProps = NotOverridableComponentProps<ContainerProps>

export function NarrowContent(props: NarrowContainerProps) {
  const { sx = [], ...rest } = props

  return (
    <AppBox
      sx={[
        styles,
        ...handlePassedSx(sx),
      ]}
      {...rest}
    />
  )
}

const styles = ({ handleCssUnit }: Theme) => ({
  // maxWidth: handleCssUnit(603),
  maxWidth: handleCssUnit(490),
  height: 1,
  margin: '0 auto',
  flexDirection: 'column',
})
