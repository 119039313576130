import { AppBox } from '../../../component/common/analog/molecule/AppBox'
import { observer } from 'mobx-react-lite'
import { Theme } from '@mui/material'
import { getEm } from '../../theme/model/CssUnit'
import { SxHelperStyle } from '../../theme/style/sx-helper.style'
import { useLocalizationStore } from '../../localization/store/localization.store'
import { localTranslate } from '../../localization/model/Translate'
import { PageTitle } from 'src/feature/localization/component/atom/PageTitle'
import { authStore } from 'src/feature/auth/store/auth.store'
import { AppInternalLink } from 'src/component/common/analog/molecule/link/AppInternalLink'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { useLoader } from 'src/hook/bool/useLoader'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { useMainStore } from 'src/store/main.store'
import { useNotificationStore } from 'src/feature/notification/store/notification.store'

export const NoAccess = observer(() => {
  const { translation, withTranslatedError } = useLocalizationStore()
  const loader = useLoader()
  const mainStore = useMainStore()
  const notificationStore = useNotificationStore()
  const handleResendVerification = async () => {
    withTranslatedError(
      async () => {
        loader.start()
        await mainStore.handleResendVerification()
        notificationStore.enqueueInfo(translation.pages.profile.verificationRequestSent())
        loader.end()
      },
      (errorMessage) => {
        notificationStore.enqueueError(errorMessage)
        loader.end()
      },
    )
  }
  
  return (
    <>
      <PageTitle textValue={translation.pages.forum.noAccessTitle({ emoji: '💬' })} />
      <AppBox sx={styles.root}>
        
        <div>
          {localTranslate(translation.pages.forum.noAccessText)}
        </div>
        {authStore.isAuthorized && (
          <AppBox sx={styles.buttonGroup}>
            <AppButton
              sx={styles.singleLineButton}
              kind={'linkLikeButton'}
              textValue={translation.pages.profile.buttons.resendVerificationEmail}
              variant="light"
              onClick={handleResendVerification}
              fullWidth={false}
            />
          </AppBox>)}
        {!authStore.isAuthorized && (
          <AppBox sx={styles.buttonGroup}>
            <AppInternalLink
              sx={styles.singleLineButton}
              kind={'linkLikeButton'}
              textValue={translation.util.signUp}
              variant="contained"
              to={appRoutePath.signUp}
              fullWidth={false}
            />
            eller
            <AppInternalLink
              sx={styles.singleLineButton}
              kind={'linkLikeButton'}
              textValue={translation.util.signIn}
              variant="light"
              to={appRoutePath.signIn}
              fullWidth={false}
            />
          </AppBox>
        )}
        
        <AppProgressLine isShowing={loader.isShowing} />
      </AppBox>
    </>
  )
})

const styles = {
  root: ({ handleCssUnit }: Theme) => ({
    display: 'flex',
    gap: handleCssUnit(40),
    flexDirection: 'column',
  }),
  buttonGroup: ({ handleCssUnit }: Theme) => ({
    display: 'flex',
    gap: handleCssUnit(5),
    alignItems: 'center',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
  }),
  date: ({ handleCssUnit }: Theme) => ({
    fontSize: handleCssUnit(14),
    mb: getEm(14, 40),
    fontWeight: 500,
  }),
  text: {
    ...SxHelperStyle.flexColumn,
    flex: 1,
    order: {
      xs: 1,
      md: 0,
    },
  },
  imageRoot: {
    display: 'flex',
    flex: 1,
    alignSelf: 'flex-start',
    order: {
      xs: 0,
      md: 1,
    },
  },
  imageText: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    order: {
      xs: 0,
      md: 1,
    },
    padding: '10 10 10 0',
  },
  singleLineButton: {
    whiteSpace: 'nowrap',
  },
}
