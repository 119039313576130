import { OverrideFunc } from '../../../../model/Override'

export const MuiCheckbox: OverrideFunc<'MuiCheckbox'> = ({ handleCssUnit }) => ({
  styleOverrides: {
    root: {
      width: 'unset',
      height: 'unset',
    },
  },
  variants: [
    {
      props: {
        size: 'medium',
      },
      style: {
        width: handleCssUnit(45),
        height: handleCssUnit(45),
      },
    },
  ],
})
