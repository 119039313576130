import { Theme } from '@mui/material'
import { SxHelperStyle } from './sx-helper.style'

export class AppStyle {
  // text
  static title = {
    lineHeight: 1.28,
    fontWeight: 600,
  }
  static normalText = {
    fontStyle: 'normal',
    fontWeight: 'normal',
  }
  static text = ({ handleCssUnit }: Theme) => ({
    ...this.normalText,
    fontSize: handleCssUnit(18),
    lineHeight: 1.48,
  })

  // clickable
  static fieldInput = {
    ...SxHelperStyle.flexCenter,
    ...SxHelperStyle.normalSize,
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '0.02em',
    lineHeight: 1.5,
    position: 'relative',
    border: 0,
    padding: '0.888em',
    transition: 'all 0.2s ease-in-out',
  }

  static baseClickable = (theme: Theme) => {
    return {
      fontWeight: 600,
      fontSize: theme.handleCssUnit(18),
      color: theme.palette.text.primary,
      padding: '0.888em',
      borderRadius: 0,
    }
  }

  static baseOutlinedClickable = (theme: Theme) => ({
    ...this.fieldInput,
    ...this.baseClickable(theme),
    lineHeight: 1.3,
    borderStyle: 'solid',
    borderWidth: '0.116em',
    maxWidth: '100%',
    borderRadius: '0.444rem',
    paddingRight: '1.555em',
    paddingLeft: '1.555em',
    '&:hover': {
      textDecoration: 'none',
    },
    '&::placeholder': {
      color: theme.palette.grey[3],
    },
    '&[type="number"]': {
      appearance: 'textfield',
    },
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      appearance: 'none',
    },
  })

  static outlinedClickable = (theme: Theme) => ({
    ...this.baseOutlinedClickable(theme),
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    '&:focus, &:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderWidth: '0.116em',
    },
  })
}
