import { observer } from 'mobx-react-lite'
import { useAuthStore } from '../../../../auth/store/auth.store'
import { useEffect } from 'react'
import { appRoutePath } from '../../../util/app-route-path'
import { ForumRoutes } from '../../../../forum/ForumRoutes'
import { RouteStacker } from '../../template/RouteStacker'
import { GlobalStyles, Theme, useTheme } from '@mui/material'
import { PayrollRoutes } from '../../../../payroll/PayrollRoutes'
import { BlogRoutes } from '../../../../blog/BlogRoutes'
import { KnowledgeRoutes } from '../../../../knowledge/KnowledgeRoutes'
import { ProfileRoutes } from '../../../../profile/ProfileRoutes'
import { ELearningRoutes } from '../../../../e-learning/ELearningRoutes'
import { VillkorRoutes } from '../../../../villkor/VillkorRoutes'
import { B2bRoutes } from 'src/feature/b2b/B2bRoutes'
import { ControlPanelRoutes } from 'src/feature/control-panel/ControlPanelRoutes'
import { GlobalOverlay } from 'src/feature/overlay/component/GlobalOverlay'
import { CompanyPageRoutes } from 'src/feature/company-page/CompanyPageRoutes'
import { skillAssessmentRoutes } from 'src/feature/skill-assessment/skill-assessment-router'
import { dashboardRoutes } from 'src/feature/dashboard/dashboard-router'

export const AuhtenticatedRoutesStack = observer(() => {
  const authStore = useAuthStore()
  const theme = useTheme()

  useEffect(() => {
    authStore.handleOpenApp()
  }, [])

  return (
    <>
      <GlobalStyles styles={styles.global(theme)} />
      <RouteStacker
        redirectToIfNoFound={appRoutePath.userDashboard}
        routes={[
          BlogRoutes,
          PayrollRoutes,
          KnowledgeRoutes,
          ForumRoutes,
          ProfileRoutes,
          ELearningRoutes,
          VillkorRoutes,
          B2bRoutes,
          ControlPanelRoutes,
          CompanyPageRoutes,
          skillAssessmentRoutes,
          dashboardRoutes,
        ]}
      />
      <GlobalOverlay />
    </>
  )
})

const styles = {
  // don't use in global styles SX syntax
  global: ({ palette }: Theme) => ({
    body: {
      backgroundColor: `${palette.background.dark} !important`,
    },
  }),
}
