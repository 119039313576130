import { axiosInstance } from '../../../util/axios/instance.axios'
import { NewOrUpdatedOrganisationLevel } from '../model/NewOrUpdatedOrganisationLevel'
import { OrganisationCoursePlanItem } from '../model/OrganisationCoursePlanItem'
import { OrganisationLevel } from '../model/OrganisationLevel'

export const getOrganisationLevels = async (): Promise<OrganisationLevel[]> => {
  const response = await axiosInstance.request<OrganisationLevel[]>({
    url: `/b2b/admin/organisation-levels`,
    method: 'get',
  })

  return response.data
}

export const getOrganisationCoursePlanItem = async (courseId: string): Promise<OrganisationCoursePlanItem | undefined> => {
  const response = await axiosInstance.request<OrganisationCoursePlanItem | undefined>({
    url: `/b2b/admin/course-plan-item/${courseId}`,
    method: 'get',
  })

  return response.data
}

export const postOrganisationCoursePlanItem = async (planItem: OrganisationCoursePlanItem | null, courseId: string): Promise<void> => {
  await axiosInstance.request({
    url: `/b2b/admin/course-plan-item/${courseId}`,
    method: 'post',
    data: planItem,
  })
}

export const postOrganisationLevel = async (level: NewOrUpdatedOrganisationLevel) => {
  await axiosInstance.request({
    url: `/b2b/admin/organisation-level`,
    method: 'post',
    data: level,
  })
}

export const deleteOrganisationLevel = async (id: string) => {
  await axiosInstance.request({
    url: `/b2b/admin/organisation-level/${id}`,
    method: 'delete',
  })
}