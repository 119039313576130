import { getRouteObjectList, withLayout } from '../router/util/router.helper'
import { RouteObjectList } from '../router/model/Route'
import { RouterLayout } from '../router/component/layout/RouterLayout'
import { dashboardIds } from '../router/model/RouteId'

export const dashboardRoutes: RouteObjectList = [
  withLayout({
    layout: <RouterLayout />,
    routes: getRouteObjectList(dashboardIds),
  }),
]
