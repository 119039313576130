import { appRoutePath } from '../../../util/app-route-path'
import { RouteStacker } from '../../template/RouteStacker'
import { AutRoutes } from '../../../../auth/AutRoutes'
import { SignUpRoutes } from '../../../../../route/SignUpRoutes'
import { B2bRoutes } from 'src/feature/b2b/B2bRoutes'

export function PublicRoutesStack() {
  return (
    <RouteStacker
      redirectToIfNoFound={appRoutePath.signIn}
      routes={[
        AutRoutes,
        SignUpRoutes,
        B2bRoutes,
      ]}
    />
  )
}
