import { Theme } from '@mui/material'
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx'
import { SxProps } from '@mui/system'

type SxArr = ReadonlyArray<boolean | SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>)>;

// Why? - https://mui.com/system/the-sx-prop/#passing-sx-prop
export function handlePassedSx(sx: SxProps<Theme> | false | undefined): SxArr {
  return [...(Array.isArray(sx) ? sx : !sx ? [] : [sx])]
}
