import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { AppTextField } from 'src/feature/form/component/analog/AppTextField'
import { AppCheckbox } from 'src/feature/form/component/analog/AppCheckbox'
import { useB2bStore } from 'src/feature/b2b/store/b2b.store'
import { OrganisationLevel } from 'src/feature/b2b/model/OrganisationLevel'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { AppCircleLoader } from 'src/component/common/analog/molecule/loader/AppCircleLoader'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export type OrgLevelsSelectorProps = {
  label: string
  placeholder: string
  disabled?: boolean
  defaultValues: string[]
  onChange: (ids: string[]) => void
  organiationLevels: OrganisationLevel[]
  showOptionForAll?: boolean
}

export default function OrgLevelsSelector(props: OrgLevelsSelectorProps) {
  const b2bStore = useB2bStore()
  const { translation } = useLocalizationStore()
  const { orgSettings } = translation.pages.eLearning
  const orgLevelsState = b2bStore.getGenericFieldsState('handleLoadOrganisationLevels')

  const mapOrganisation = (level: OrganisationLevel, dept: number): {id: string, name: string, dept: number}[] => {
    return [{ id: level.id, name: level.name, dept }, ...b2bStore.organisationLevels!
      .filter(l => l.parentLevel && l.parentLevel === level.id).map(l => mapOrganisation(l, dept + 1))
      .flat()]
  }

  const defaultOptions = [...props.organiationLevels.filter(l => !l.parentLevel)
    .map(l => mapOrganisation(l, 1))
    .flat()]
  const options = props.showOptionForAll ? [{ id: '', name: orgSettings.selectAll(), dept: 1 }, ...defaultOptions] : defaultOptions

  if (orgLevelsState.isLoading) {
    return <AppCircleLoader isShowing={true} />
  }

  return (
    <Autocomplete
      multiple
      id="org-level-selector"
      sx={{
        '& .MuiAutocomplete-popupIndicator, & .MuiAutocomplete-clearIndicator': {
          color: 'text.primary',
        },
      }}
      disabled={props.disabled}
      options={options}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      value={options.filter(l => props.defaultValues.indexOf(l.id) > -1)}
      onChange={(_, v) => {
        if (!props.showOptionForAll) {
          props.onChange(v.map(l => l.id))
        } else {
          const pickedValue = v.length > 0 ? v[v.length - 1] : null

          if (pickedValue && pickedValue.id === '') {
            props.onChange([''])
          } else {
            props.onChange(v.length > 1 ? v.filter(l => l.id !== '').map(l => l.id) : v.map(l => l.id))
          }
        }
      }}
      getOptionLabel={option => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <AppCheckbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: option.dept * 16 }}
            checked={selected}
            size="medium"
          />
          {option.name}
        </li>
      )}
      style={{ width: '100%', flexWrap: 'wrap' }}
      renderInput={params => (
        <AppTextField {...params} label={props.label} placeholder={props.placeholder} />
      )}
    />
  )
}