import { Document } from '@contentful/rich-text-types'
import { axiosInstance } from '../../../util/axios/instance.axios'

export const delArticle = async (id: string): Promise<void> => {
  await axiosInstance.request({
    url: `/b2b/content/article/${id}`,
    method: 'put',
  })
}

export const postArticle = async (id: string, article: Document): Promise<void> => {
  await axiosInstance.request({
    url: `/b2b/content/article/${id}`,
    method: 'post',
    data: { article },
  })
}