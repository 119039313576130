import { TranslatableText, TranslatableTextVariantProps } from './TranslatableText'

export function Title2(props: TranslatableTextVariantProps) {
  return (
    <TranslatableText
      variant={'h2'}
      {...props}
      component={'h2'}
    />
  )
}
