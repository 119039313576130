import { getKeyFromObjEntry, ObjKey } from './Object'

export class GenericClass {
  get propertyNames(): (keyof this)[] {
    const entries: Array<[key: ObjKey, v: unknown]> = Object.entries(this)
    const propertyEntries = entries.filter(([_, v]) => typeof v !== 'function')

    return propertyEntries.map(getKeyFromObjEntry) as (keyof this)[]
  }
}
