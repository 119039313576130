import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { LazyRouter } from '../../../feature/router/component/organism/LazyRouter'
import { useScrollToTop } from '../../../hook/useScrollToTop'
import { useMaintenanceStore } from '../../../feature/maintenance/store/maintenance.store'
import { BaseLayout } from '../layout/BaseLayout'
import { Notifier } from '../../../feature/notification/component/Notifier'

export const App = observer(() => {
  const maintenanceStore = useMaintenanceStore()

  // useCorrectPath()
  useScrollToTop()

  useEffect(() => {
    maintenanceStore.handleGetMaintenanceState()
  }, [])

  return (
    <BaseLayout>
      <Notifier />
      <LazyRouter />
    </BaseLayout>
  )
})
