import { GoBackButtonWithArrow } from '../../../component/common/original/molecule/GoBackButtonWithArrow'
import { Platform } from '../component/organism/Platform'

export function PlatformArticlePage() {
  return (
    <>
      <GoBackButtonWithArrow />
      <Platform />
    </>
  )
}
