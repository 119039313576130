import { useBool } from './useBool'

export function useLoader(defaultValue = false) {
  const loader = useBool(defaultValue)

  return {
    isShowing: loader.isTrue,
    set: loader.update,
    start: loader.makeTrue,
    end: loader.makeFalse,
    reset: loader.reset,
  }
}
