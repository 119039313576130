import { createStore } from '../../../util/mobx/generic-store/store-creator'
import { GenericStore } from '../../../util/mobx/generic-store/generic.store'
import { CompanyPageItem } from '../model/CompanyPageItem'
import { GenericStoreAsyncMethod } from 'src/util/mobx/generic-store/decorator/async-method.decorator'
import { deleteItem, getItems, getOrCreateDraft, postItem } from '../api/company-page'
import { byPropertiesOf } from 'src/util/helper'

export class CompanyPageStore extends GenericStore {
  items: CompanyPageItem[] | undefined

  constructor() {
    super('CompanyPageStore')

    super.observe(this)
    super.persist({ encrypt: false, excludedProperties: [] })
  }

  reset() {
    this.items = undefined
  }

  get hasPromoItem() {
    return this.items?.some(i => i.isPromo) || false
  }

  get promoItem() {
    return this.items!.find(i => i.isPromo)!
  }

  get listItems() {
    if (this.hasPromoItem) {
      return this.items!.filter(i => i.id !== this.promoItem.id)
    }

    return this.items ?? []
  }

  get isEmpty() {
    return this.items?.length === 0 || false
  }

  @GenericStoreAsyncMethod()
  async updateItems() {
    this.items = (await getItems()).slice().sort(byPropertiesOf<CompanyPageItem>(['-published']))
  }

  async loadItem(itemId: string) {
    if (!this.items) {
      await this.updateItems()
    }

    return this.items?.find(i => i.id === itemId)
  }

  @GenericStoreAsyncMethod()
  async createOrLoadDraft() {
    return getOrCreateDraft()
  }

  @GenericStoreAsyncMethod()
  async saveItem(itemId: string, title: string, content: string, isPromo: boolean, orgLevels: string[], publish: boolean) {
    return postItem(itemId, title, content, isPromo, orgLevels, publish)
  }

  @GenericStoreAsyncMethod()
  async deleteItem(itemId: string) {
    return deleteItem(itemId)
  }
}

export const {
  storeInstance: companyPageStoreInstance,
  useStore: useCompanyPageStore,
  StoreProvider: CompanyPageStoreProvider,
} = createStore(new CompanyPageStore())
