import { createStore } from '../../../util/mobx/generic-store/store-creator'
import { AppThemeMode } from '../model/Mode'
import { GenericStore } from '../../../util/mobx/generic-store/generic.store'

export class ThemeStore extends GenericStore {
  mode!: AppThemeMode

  constructor() {
    super('ThemeStore')

    super.observe(this)
    this.reset()
    super.persist({ encrypt: false })
  }

  reset() {
    this.mode = 'light'
  }

  changeMode(newMode: AppThemeMode) {
    this.mode = newMode
  }
}

export const {
  storeInstance: themeStoreInstance,
  useStore: useThemeStore,
  StoreProvider: ThemeStoreProvider,
} = createStore(new ThemeStore())
