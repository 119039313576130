import { observer } from 'mobx-react-lite'
import { GrowthCard } from '../component/GrowthCard'
import { Box } from '@mui/material'
import { DepartmentList } from '../component/DepartmentList'
// import { SkillGapList } from '../component/SkillGapList'

export const AdministratorDasboardPage = observer(() => {
  return (
    <Box sx={styles.root}>
      <GrowthCard />
      <DepartmentList />
      {/* <SkillGapList /> */}
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4rem',
    padding: { xs: '1rem 1rem', md: '2rem 3rem' },
  },
}
