import TypesafeI18n from '../../util/i18n/i18n-react'
import { useEffect } from 'react'
import { ChildrenProps } from '../../../../model/React'
import { observer } from 'mobx-react-lite'
import { useLocalizationStore } from '../../store/localization.store'

export const TypesafeI18nProvider = observer(({ children }: ChildrenProps) => {
  const localizationStore = useLocalizationStore()

  useEffect(() => {
    localizationStore.resetLocale()
  }, [])

  return (
    <TypesafeI18n locale={localizationStore.locale}>
      {children}
    </TypesafeI18n>
  )
})
