import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'
import { renderNodeOrLocalTranslate } from '../../../localization/model/Translate'
import { FormLabelType } from '../../model/Form'
import { NotOverridableComponentProps } from '../../../../model/Component'
import { AppBox } from '../../../../component/common/analog/molecule/AppBox'

type BaseAppFormControlWithLabelProps = Omit<FormControlLabelProps, 'label'> & {
  label: FormLabelType
}

export type AppFormControlWithLabelProps = NotOverridableComponentProps<BaseAppFormControlWithLabelProps>

export function AppFormControlWithLabel(props: AppFormControlWithLabelProps) {
  const {
    label,
    control,
    ...rest
  } = props

  return (
    <AppBox
      {...rest}
      component={FormControlLabel}
      label={renderNodeOrLocalTranslate(label)}
      control={control}
    />
  )
}
