import { OverrideFunc } from '../../../../model/Override'
import { SxHelperStyle } from '../../../../style/sx-helper.style'

export const MuiMenu: OverrideFunc<'MuiMenu'> = _theme => ({
  defaultProps: {
    PaperProps: {
      variant: 'elevation',
      elevation: 1,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
  styleOverrides: {
    paper: {
      padding: 10,
      width: 'auto',
      overflow: 'inherit',
      borderRadius: 0,
    },
    list: {
      ...SxHelperStyle.noIndents,
    },
  },
})
