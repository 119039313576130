import { AppInternalLink, AppInternalLinkProps } from './AppInternalLink'
import { AppExternalLink, AppExternalLinkProps } from './AppExternalLink'
import { checkIsExternalLink } from '../../../../../model/Link'
import { AppBaseLinkKind } from './AppBaseLinkKind'
import { NotOverridableComponentProps } from '../../../../../model/Component'
import { EmailLink, EmailLinkProps } from './EmailLink'
import { NewTabLink, NewTabLinkProps } from './NewTabLink'

export type LinkPropsUnion = NotOverridableComponentProps<(EmailLinkProps | NewTabLinkProps)>

export type AppLinkProps = LinkPropsUnion & {
  openAs?: 'currentTab' | 'newTab' | 'email'
}

export function AppLink(props: AppLinkProps) {
  const { link, openAs = 'currentTab', ...rest } = props

  if (openAs === 'email') {
    return <EmailLink {...rest as EmailLinkProps} />
  } else if (openAs === 'newTab') {
    return <NewTabLink link={link} {...rest} />
  } if (checkIsExternalLink(link)) {
    return <AppExternalLink {...rest as AppExternalLinkProps} href={link} />
  } else if (!link) {
    return <AppBaseLinkKind kind={'buttonLikeLink'} {...rest} />
  }

  return <AppInternalLink {...rest as AppInternalLinkProps} to={link} />
}
