import { getRouteObjectList, withLayout } from '../router/util/router.helper'
import { RouterLayout } from '../router/component/layout/RouterLayout'
import { maintenanceRouteIds } from '../router/model/RouteId'

export const MaintenanceRoutes = [
  withLayout({
    layout: <RouterLayout isNarrowContainer />,
    routes: getRouteObjectList(maintenanceRouteIds),
  }),
]
