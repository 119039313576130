import { useLocation } from 'react-router-dom'
import { appRoutePath } from '../../router/util/app-route-path'
import { AskQuestionModal } from './AskQuestionModal'
import { AppButton } from '../../../component/common/analog/molecule/button/AppButton'
import { useLocalizationStore } from '../../localization/store/localization.store'
import { observer } from 'mobx-react-lite'
import { Theme } from '@mui/material'
import { useBool } from '../../../hook/bool/useBool'
import { useBreakpoints } from '../../theme/hook/useBreakpoints'

export const AskQuestionButton = observer(() => {
  const { pathname } = useLocation()
  const { translation } = useLocalizationStore()
  const modalBool = useBool()
  const { isMd } = useBreakpoints()

  const { askQuestion } = translation.components
  const showButton = (pathname.startsWith(appRoutePath.faq) || pathname.startsWith(appRoutePath.forum))

  if (!showButton) {
    return null
  }

  return (
    <>
      <AskQuestionModal {...modalBool} />
      <AppButton
        textValue={isMd ? '?' : askQuestion.button}
        onClick={modalBool.makeTrue}
        sx={styles}
        variant={'contained'}
      />
    </>
  )
})

const styles = (theme: Theme) => ({
  position: 'fixed',
  zIndex: 300,
  bottom: theme.handleCssUnit(48),
  right: theme.handleCssUnit(48),
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.default,
  borderColor: theme.palette.primary.main,
  '&:hover': {
    '&, *': {
      color: theme.palette.common.white,
    },
  },
  [theme.breakpoints.down('md')]: {
    fontSize: theme.handleCssUnit(34),
    right: theme.handleCssUnit(16),
    lineHeight: 1,
    py: '0.4em',
    px: '0.6em',
  },
})
