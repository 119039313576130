import { AppBox } from '../../common/analog/molecule/AppBox'
import { NotOverridableComponentProps } from '../../../model/Component'

export type AsideProps = NotOverridableComponentProps

export function Aside(props: AsideProps) {
  return (
    <AppBox
      {...props}
      component={'aside'}
    />
  )
}
