import { MuiTypography } from './by-type/typography'
import { MuiCssBaseline } from './by-type/cssBaseline'
import { MuiCheckbox } from './by-type/checkbox'
import { Theme } from '@mui/material'
import { deepmerge } from '@mui/utils'
import { MuiButton } from './by-type/button'
import { MuiLink } from './by-type/link'
import { MuiPaper } from './by-type/paper'
import { MuiStack } from './by-type/stack'
import { MuiDialog } from './by-type/dialog'
import { MuiMenu } from './by-type/menu'
import { MuiModal } from './by-type/modal'
import { Components } from '@mui/material/styles/components'

type GetComponentsFn = (theme: Theme) => Components

const getComponents: GetComponentsFn = theme => ({
  MuiTypography: MuiTypography(theme),
  MuiCheckbox: MuiCheckbox(theme),
  MuiCssBaseline: MuiCssBaseline(theme),
  MuiButton: MuiButton(theme),
  MuiLink: MuiLink(theme),
  MuiPaper: MuiPaper(theme),
  MuiStack: MuiStack(theme),
  MuiDialog: MuiDialog(theme),
  MuiMenu: MuiMenu(theme),
  MuiModal: MuiModal(theme),
})

export function overrideComponents(theme: Theme) {
  return deepmerge<Theme>(theme, {
    components: getComponents(theme),
  })
}
