import localstorageSlim from 'localstorage-slim'
import { safeStringify } from './json'
import { LocalStorageConfig } from 'localstorage-slim/dist/types'
import lodashMerge from 'lodash/merge'

interface Props {
  config: LocalStorageConfig
}

export class EncryptedLocalStorage {
  constructor(private config: Props['config']) {
    this.init()
  }

  private init() {
    lodashMerge(localstorageSlim.config, this.config)
  }

  has(key: string): boolean {
    return Boolean(this.getRaw(key))
  }

  getRaw(key: string, config?: LocalStorageConfig): any {
    return localstorageSlim.get(key, config)
  }

  getParsed(key: string): any {
    const rawValue = localstorageSlim.get(key) as any
    const parsedValue = safeStringify(rawValue)

    return parsedValue ? JSON.parse(parsedValue) : null
  }

  delete(key: string): void {
    localstorageSlim.remove(key)
  }

  set(key: string, value: unknown, config?: LocalStorageConfig): void {
    localstorageSlim.set(key, safeStringify(value), config)
  }
}
