import { observer } from 'mobx-react-lite'
import Confetti from 'react-confetti'
import { useEffect, useState } from 'react'

export type AppConfettiProps = {
  show: boolean
}

export const AppConfetti = observer((props: AppConfettiProps) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    xOffset: window.scrollX,
    yOffset: window.scrollY,
  })

  useEffect(() => {
    const updateDimensions = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
        xOffset: window.scrollX,
        yOffset: window.scrollY,
      })
    }

    window.addEventListener('resize', updateDimensions)
    window.addEventListener('scroll', updateDimensions)
    
    return () => {
      window.removeEventListener('resize', updateDimensions)
      window.removeEventListener('scroll', updateDimensions)
    }
  }, [])

  return (
    <Confetti
      width={dimensions.width}
      height={dimensions.height}
      numberOfPieces={500}
      gravity={0.1}
      friction={0.99}
      recycle={false}
      run={props.show}
      style={{
        position: 'fixed',
        top: dimensions.yOffset,
        left: dimensions.xOffset,
      }}
    />
  )
})
