import { WithSidebarLayout, WithSidebarLayoutProps } from '../../../../component/unique/layout/WithSidebarLayout'
import { SimpleLayout, SimpleLayoutProps } from '../../../../component/unique/layout/SimpleLayout'
import { Outlet } from 'react-router-dom'
import { useMemo } from 'react'
import { WithoutChildren } from '../../../../model/React'

type Props = WithoutChildren<SimpleLayoutProps & WithSidebarLayoutProps>

export function RouterLayout(props: Props) {
  const CurrentLayout = useMemo(() => {
    switch ('sidebar' in props) {
    case true:
      return WithSidebarLayout
    default:
      return SimpleLayout
    }
  }, [props.sidebar])

  return (
    <CurrentLayout {...props}>
      <Outlet />
    </CurrentLayout>
  )
}
