import { DeepReadonly } from './Object'
import { isLength } from '../util/helper'

export type UniqueArray<A extends readonly unknown[]> =
  A extends readonly [infer X, ...infer Rest]
    ? X extends Rest[number]
      ? [never, 'Encountered value with duplicates:', X] // false
      : UniqueArray<Rest>
    : true;

export type IsUniqueArray<A extends readonly unknown[], X> = X extends A[number] ? true : false;

export type Tuple<T0, T1> = [T0, T1]

export type EmptyArray = []

export type AnyArray = any[]

export type DeepReadonlyArray<T> = ReadonlyArray<DeepReadonly<T>>

export function isArrContainsAnotherArr(arr1: unknown[], arr2: unknown[]): boolean {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false
  }

  return arr1.every(c => arr2.includes(c))
}

export function getArrMiddleElement<T>(arr: T[]): T {
  return arr[Math.floor(arr.length / 2)]
}

export function removeElementsFromArrByAnotherArr<T>(arr1: T[] = [], arr2: T[] = []): T[] {
  return arr1.filter(c => !arr2.includes(c))
}

export function hasDifferences(target: any[], source: any[]): boolean {
  if (![target, source].every(Array.isArray)) {
    return false
  }

  const differences = target.filter(c => !source.includes(c))

  return isLength(differences)
}

export function typesafe_Includes<T>(arr: T[], value: any): value is T {
  return arr.includes(value)
}

export function getUnique<T extends Record<string, any>>(
  arr: T[],
  uidKey = 'id',
): T[] {
  return [...new Map(arr.map(e => [e[uidKey], e])).values()]
}
