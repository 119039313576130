import { PaymentHistoryItem } from '../model/Payment'
import { axiosInstance } from '../../../util/axios/instance.axios'

export const getPaymentHistory = async (email: string | undefined): Promise<PaymentHistoryItem[]> => {
  const response = await axiosInstance.request<PaymentHistoryItem[]>({
    url: `/user-payment/history`,
    params: {
      email,
    },
  })

  return response.data
}
