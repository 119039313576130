import { axiosInstance } from 'src/util/axios/instance.axios'
import { ProfessionType, SkillCategory } from '../model/skill-assessment-model'

export async function getSkillSets(userId?: string): Promise<SkillCategory> {
  const url = userId ? `/skill-assessment/skill-sets/${userId}` : '/skill-assessment/skill-sets'
  const { data } = await axiosInstance.request<SkillCategory>({
    url,
  })

  return data
}

export async function addSkillAssessment(set_id: string, selected_set_level: number): Promise<any> {
  const { data } = await axiosInstance.request({
    url: '/skill-assessment/skill-rating',
    method: 'post',
    data: {
      set_id,
      selected_set_level,
    },
  })

  return data
}

export async function getProfessions(): Promise<ProfessionType[]> {
  const { data } = await axiosInstance.request({
    url: '/skill-assessment/skill-data',
  })

  return data
}

export async function getSkillCategory(levelId: string): Promise<SkillCategory> {
  const { data } = await axiosInstance.request<SkillCategory>({
    url: `/skill-assessment/skill-category/${levelId}`,
  })

  return data
}

export async function addSkillCategory(profession: string, levelId: string, locale: string): Promise<any> {
  const { data } = await axiosInstance.request({
    url: `/skill-assessment/skill-category/`,
    method: 'post',
    data: {
      profession,
      levelId,
      locale,
    },
  })

  return data
}

export async function addExpectedLevel(set_id: string, set_name: string, expected_set_level: number): Promise<any> {
  const { data } = await axiosInstance.request({
    url: '/skill-assessment/expected-level',
    method: 'post',
    data: {
      set_id,
      set_name,
      expected_set_level,
    },
  })

  return data
}

export async function deleteExpectedRole(levelId: string): Promise<void> {
  await axiosInstance.request({
    url: `/skill-assessment/expected-role/${levelId}`,
    method: 'delete',
  })
}

export async function deleteSkillSet(setId: string, categoryId: string): Promise<void> {
  await axiosInstance.request({
    url: `/skill-assessment/skill-set/${setId}/${categoryId}`,
    method: 'delete',
  })
}

export async function addSkillSet(skillSetTitle: string, categoryId: string, locale: string): Promise<void> {
  await axiosInstance.request({
    url: '/skill-assessment/skill-set',
    method: 'post',
    data: {
      skillSetTitle,
      categoryId,
      locale,
    },
  })
}
