import { getRouteObjectList, withLayout } from '../router/util/router.helper'
import { RouterLayout } from '../router/component/layout/RouterLayout'
import { controlPanelds } from '../router/model/RouteId'
import { ControlPanelSidebar } from './component/ControlPanelSidebar'

export const ControlPanelRoutes = [
  withLayout(
    {
      layout: <RouterLayout sidebar={<ControlPanelSidebar />} />,
      routes: getRouteObjectList(controlPanelds),
    },
  ),
]
