export enum Stage {
  local = 'local',

  /* remote stages */
  dev = 'dev', // temporary stage (needs to be removed soon)
  qa = 'qa', // testing
  staging = 'staging',
  prod = 'prod', // production
}

const stageEnvVariableName = 'REACT_APP_STAGE'

export const stage = ((): Stage | never => {
  const env = process.env[stageEnvVariableName]

  if (!env) {
    return Stage.local
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (Object.values(Stage).includes(env)) {
    return env as Stage
  }

  throw new Error(`Invalid ${stageEnvVariableName} variable!`)
})()

export const devLikeEnvironments = [Stage.local, Stage.dev, Stage.qa]

export const productionLikeEnvironments = [Stage.staging, Stage.prod]

export const isDevLike = devLikeEnvironments.includes(stage)

export const isProdLike = productionLikeEnvironments.includes(stage)

export const isExactlyProd = stage === Stage.staging
