import { GoogleSheetsResponse } from '../model/GoogleSheets'
import { axiosInstance } from '../../../util/axios/instance.axios'
import { Id } from '../../../model/Id'
import { NO_AUTHORIZATION_HEADER } from '../../../util/axios/constant.axios'

export interface FetchGoogleSheetsRequest {
  range: string
  spreadSheetId: Id
  apiKey: string
}

export const GOOGLE_SHEETS_BASE_URL = 'https://sheets.googleapis.com/v4/spreadsheets'

export async function fetchGoogleSheets(request: FetchGoogleSheetsRequest): Promise<GoogleSheetsResponse> {
  const { data } = await axiosInstance.request<GoogleSheetsResponse>({
    baseURL: GOOGLE_SHEETS_BASE_URL,
    url: `/${request.spreadSheetId}/values/${request.range}`,
    headers: {
      Authorization: NO_AUTHORIZATION_HEADER,
    },
    params: {
      key: request.apiKey,
    },
  })

  return data
}
