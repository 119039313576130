import lodashIsNumber from 'lodash/isNumber'

export const Num = {
  toFixed: (value: number, digits = 0) => {
    if (!lodashIsNumber(value)) {
      throw new Error('Value should be a number')
    }

    return parseFloat(value.toFixed(digits))
  },
}

export function increase(value: number): number {
  return ++value
}

export function decrease(value: number): number {
  return --value
}

export type Percentage = number

export function findPercentageOfNumber(value: number, percentage: Percentage) {
  const valueOfOnePercent = value / 100

  return valueOfOnePercent * percentage
}
