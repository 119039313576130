/* eslint-disable no-useless-catch */
import { PostModel } from '../model/Post'
import { toJS } from 'mobx'
import { isIndexNotFound, isLength } from '../../../util/helper'

import lodashMerge from 'lodash/merge'
import lodashRemove from 'lodash/remove'
import { createPost, deletePost, getPosts, GetPostsRequest } from '../api/post'
import { likePost, unlikePost } from '../api/like'
import { createComment, deleteComment, getComments, GetCommentsRequest } from '../api/comment'
import { Author } from '../model/Author'
import { CommentModel } from '../model/Comment'
import { PAGINATION_START_PAGE } from '../model/Pagination'
import { createStore } from '../../../util/mobx/generic-store/store-creator'
import { GenericStoreAsyncMethod } from '../../../util/mobx/generic-store/decorator/async-method.decorator'
import { GenericStore } from '../../../util/mobx/generic-store/generic.store'
import { decrease, increase } from '../../../model/Number'
import { getUnique } from '../../../model/Array'
import { API_BASE_URL } from 'src/util/constant'

type Data = (CommentModel | PostModel)[]

class ForumStore extends GenericStore {
  allPostsPaginationPage!: number | undefined
  searchedPostsPaginationPage!: number | undefined
  allPosts!: PostModel[]
  searchedPosts!: PostModel[]
  hasMorePosts!: boolean
  hasMoreComments!: boolean
  searchQuery!: string | undefined
  hashtag!: string | undefined

  constructor() {
    super('ForumStore')

    super.observe(this)
    super.persist({ encrypt: false })

    this.reset()
  }

  get areAllPosts() {
    return isLength(this.allPosts)
  }

  get areSearchedPosts() {
    return isLength(this.searchedPosts)
  }

  get isSearchQuery() {
    return isLength(this.searchQuery)
  }

  get isHashtag() {
    return isLength(this.hashtag)
  }

  get arePosts() {
    return this.isSearchQuery || this.isHashtag ? this.areSearchedPosts : this.areAllPosts
  }

  get posts() {
    return this.isSearchQuery || this.isHashtag ? this.searchedPosts : this.allPosts
  }

  set posts(value) {
    if (this.isSearchQuery || this.isHashtag) {
      this.searchedPosts = value
    } else {
      this.allPosts = value
    }
  }

  // FIXME: types
  sort<T extends Data>(data: T): T | [] {
    if (!isLength(data)) {
      return []
    }

    return toJS(data).sort((a, b) => {
      const $b = new Date(b.updatedAt)
      const $a = new Date(a.updatedAt)

      return Number($b) - Number($a)
    }) as T
  }

  getSortedPosts() {
    return this.sort(this.posts)
  }

  getSortedComments(postId: string) {
    const { item } = this.getPostById(postId)

    return this.sort(item.comments)
  }

  reset() {
    this.allPostsPaginationPage = PAGINATION_START_PAGE
    this.searchedPostsPaginationPage = PAGINATION_START_PAGE
    this.allPosts = []
    this.searchedPosts = []
    this.hasMorePosts = false
    this.hasMoreComments = false
    this.searchQuery = undefined
    this.hashtag = undefined
  }

  removeDeprecatedData<T extends Data>(data: T, ids: string[]) {
    lodashRemove(data, c => !ids.includes(c.id))
  }

  @GenericStoreAsyncMethod({ withAllLoading: false })
  async handleCreatePost(message: string) {
    const response = await createPost(message)
    this.posts.push(response)
  }

  @GenericStoreAsyncMethod({ withAllLoading: false })
  async handleDeletePost(postId: string) {
    await deletePost(postId)
    this.posts = this.posts.filter(post => post.id !== postId)
  }

  handleClearSearchedPosts() {
    this.searchedPosts = []
  }

  @GenericStoreAsyncMethod()
  async handleLoadPosts() {
    this.handleClearSearchedPosts()
    const { paginatedPostsEntities, hasMore, allPostsIds } = await getPosts({ page: this.allPostsPaginationPage })
    this.hasMorePosts = hasMore
    this.handleSetPosts(paginatedPostsEntities, allPostsIds)
    if (this.hasMorePosts) {
      this.allPostsPaginationPage = increase(this.allPostsPaginationPage!)
    }
  }

  @GenericStoreAsyncMethod()
  async handleSearchPosts(searchPostsRequest: GetPostsRequest) {
    this.searchQuery = searchPostsRequest?.searchQuery

    if (this.isSearchQuery) {
      this.handleClearSearchedPosts()
      const { paginatedPostsEntities, hasMore, allPostsIds } = await getPosts({
        ...searchPostsRequest,
        page: this.searchedPostsPaginationPage,
      })
      this.hasMorePosts = hasMore
      this.handleSetPosts(paginatedPostsEntities, allPostsIds)
      if (this.hasMorePosts) {
        this.searchedPostsPaginationPage = increase(this.searchedPostsPaginationPage!)
      }
    } else {
      await this.handleLoadPosts()
    }
  }

  @GenericStoreAsyncMethod()
  async handleFilterPostByTag(hashtag: GetPostsRequest) {
    this.hashtag = hashtag?.searchQuery
    if (this.isHashtag) {
      this.handleClearSearchedPosts()
      const { paginatedPostsEntities, hasMore, allPostsIds } = await getPosts({
        ...hashtag,
        page: this.searchedPostsPaginationPage,
      })
      this.hasMorePosts = hasMore
      this.handleSetPosts(paginatedPostsEntities, allPostsIds)
      if (this.hasMorePosts) {
        this.searchedPostsPaginationPage = increase(this.searchedPostsPaginationPage!)
      }
    } else {
      await this.handleLoadPosts()
    }
  }

  getDisplayName(author: Author) {
    if (author.alias) {
      return author.alias
    }

    if (author.lastName) {
      return `${author.firstName} ${author.lastName}`
    }

    return author.firstName
  }

  async handleExtendPost(post: PostModel) {
    const { item } = this.getPostById(post.id)

    if (!isLength(item?.comments)) {
      item.comments = []
    }

    this.changePost(post.id, {
      author: await this.getExtendedAuthor(post.author),
    })
  }

  async handleExtendComment(comment: CommentModel) {
    this.changeComment(comment.post, comment.id, {
      author: await this.getExtendedAuthor(comment.author),
    })
  }

  async getExtendedAuthor(author: Author): Promise<Author> {
    const fullName = this.getDisplayName(author)

    return {
      ...author,
      fullName,
      avatar: this.getAuthorAvatar(author),
    }
  }

  getPostById(postId: string): { item: PostModel, index: number } {
    const index = this.posts.findIndex(c => c.id === postId)
    const item = this.posts[index]

    if (!item) {
      throw new Error('Post not found')
    }

    return { item, index }
  }

  getCommentByIds(postId: string, commentId: string): { item: CommentModel, index: number } {
    const { index: postIndex } = this.getPostById(postId)

    const index = this.posts[postIndex].comments.findIndex(c => c.id === commentId)
    const item = this.posts[postIndex].comments[index]

    if (isIndexNotFound(postIndex)) {
      throw new Error('Comment not found')
    }

    return { item, index }
  }

  changePost(postId: string, newData: Partial<PostModel>) {
    const { item } = this.getPostById(postId)

    lodashMerge(item, newData)
  }

  changeComment(postId: string, commentId: string, newData: Partial<PostModel>) {
    const { item } = this.getCommentByIds(postId, commentId)

    lodashMerge(item, newData)
  }

  @GenericStoreAsyncMethod({ withAllLoading: false })
  async handleToggleLike(postId: string) {
    const { item } = this.getPostById(postId)

    if (item.isLikedByCurrentUser) {
      await this.handleUnlike(postId)
    } else {
      await this.handleLike(postId)
    }
  }

  async handleLike(postId: string) {
    const { item } = this.getPostById(postId)

    this.changePost(postId, {
      isLikedByCurrentUser: true,
      likes: increase(item.likes),
    })

    await likePost(postId)
  }

  async handleUnlike(postId: string) {
    const { item } = this.getPostById(postId)

    this.changePost(postId, {
      isLikedByCurrentUser: false,
      likes: decrease(item.likes),
    })

    await unlikePost(postId)
  }

  @GenericStoreAsyncMethod({ withAllLoading: false })
  async handleCreateComment(postId: string, content: string) {
    const response = await createComment(postId, content)
    const { index } = this.getPostById(postId)
    const comments = this.posts[index]?.comments

    const push = () => this.posts[index].comments.push(response)

    if (isLength(comments)) {
      push()
    } else {
      this.posts[index].comments = []
      push()
    }
  }

  @GenericStoreAsyncMethod({ withAllLoading: false })
  async handleGetComments(getCommentsRequest: GetCommentsRequest) {
    const { paginatedCommentsEntities, hasMore, allCommentsIds } = await getComments(getCommentsRequest)

    this.hasMoreComments = hasMore
    this.handleSetComments(getCommentsRequest.postId, paginatedCommentsEntities, allCommentsIds)
  }

  handleSetComments(postId: string, newComments: CommentModel[], allCommentsIds: string[]) {
    const { item: { comments: existedComments } } = this.getPostById(postId)

    this.removeDeprecatedData(existedComments, allCommentsIds)
    // this.addNewData(existedComments, newComments)
    this.changePost(postId, {
      comments: getUnique([...(existedComments || []), ...newComments]),
    })
  }

  handleSetPosts(newPosts: PostModel[], allPostsIds: string[]) {
    this.removeDeprecatedData(this.posts, allPostsIds)

    this.posts = getUnique([...this.posts, ...newPosts])
  }

  @GenericStoreAsyncMethod({ withAllLoading: false })
  async handleDeleteComment(postId: string, commentId: string) {
    await deleteComment(postId, commentId)
    const { item } = this.getPostById(postId)

    lodashRemove(item.comments, (c) => {
      return c.id === commentId
    })
  }

  getAuthorAvatar(author: Author): string {
    return API_BASE_URL + author.avatarUrl
  }
}

export const {
  storeInstance: forumStoreInstance,
  useStore: useForumStore,
  StoreProvider: ForumStoreProvider,
} = createStore(new ForumStore())
