/* eslint-disable no-useless-catch,@typescript-eslint/no-non-null-assertion,@typescript-eslint/no-non-null-asserted-optional-chain */
import { createStore } from '../../../util/mobx/generic-store/store-creator'
import { GenericStoreAsyncMethod } from '../../../util/mobx/generic-store/decorator/async-method.decorator'
import { GenericStore } from '../../../util/mobx/generic-store/generic.store'
import { deleteOrganisationUser, deleteUserInvite, getInvites, getSystemSettings, getUsers, postAddUser, postOrganisationUser, postSystemSettings, postUserInvite, saveSiteMap } from '../api/admin'
import { SiteMap } from 'src/feature/user/model/SiteMap'
import { OrganisationUser } from '../model/OrganisationUser'
import { SystemSettings } from '../model/SystemSettings'
import { OrganisationInvite } from '../model/OrganisationInvite'
import { OrganisationRole } from '../model/OrganisationRole'

export class ControlPanelStore extends GenericStore {
  users!: OrganisationUser[]
  userInvites!: OrganisationInvite[]
  systemSettings?: SystemSettings

  constructor() {
    super('ControlPanelStore')

    super.observe(this)
    this.reset()
    super.persist({ encrypt: false, excludedProperties: ['users', 'userInvites'] })
  }

  reset() {
    this.users = []
    this.userInvites = []
    this.systemSettings = undefined
  }

  @GenericStoreAsyncMethod()
  async saveSiteMap(sitemap: SiteMap) {
    await saveSiteMap(sitemap)
  }

  @GenericStoreAsyncMethod()
  async updateOrganisationUsers() {
    this.users = await getUsers()
    this.userInvites = await getInvites()
  }

  @GenericStoreAsyncMethod()
  async updateOrgUser(userId: string, groups: string[], role: OrganisationRole, subscriptionPlan: number) {
    await postOrganisationUser(userId, groups, role, subscriptionPlan)
  }

  @GenericStoreAsyncMethod()
  async removeOrgUser(userId: string) {
    await deleteOrganisationUser(userId)
  }

  @GenericStoreAsyncMethod()
  async inviteUser(email: string, groups: string[], role: OrganisationRole) {
    await postUserInvite(email, groups, role)
  }

  @GenericStoreAsyncMethod()
  async addUser(email: string, groups: string[], role: OrganisationRole) {
    await postAddUser(email, groups, role)
  }

  async deleteInvite(inviteId: string) {
    await deleteUserInvite(inviteId)
  }

  @GenericStoreAsyncMethod()
  async loadSystemSettings() {
    this.systemSettings = await getSystemSettings()
  }

  async saveSystemSettings(systemSettings: SystemSettings) {
    await postSystemSettings(systemSettings)
    this.systemSettings = systemSettings
  }
}

export const {
  storeInstance: controlPanelStoreInstance,
  useStore: useControlPanelStore,
  StoreProvider: ControlPanelStoreProvider,
} = createStore(new ControlPanelStore())
