import { observer } from 'mobx-react-lite'
import { VillkorSelector } from '../components/VillkorSelector'
import { useState } from 'react'
import { PageTitle } from '../../localization/component/atom/PageTitle'
import { AppBox } from '../../../component/common/analog/molecule/AppBox'
import { DownloadButton } from '../components/DownloadButton'

const links = {
  användarvillkor: {
    label: 'Användarvillkor',
    value: 'https://yohrs-prod.s3.eu-north-1.amazonaws.com/anv%C3%A4ndarvillkor-sv.pdf',
  },
  betalningsvillkor: {
    value: 'https://yohrs-prod.s3.eu-north-1.amazonaws.com/betalningsvillkor-sv.pdf',
    label: 'Betalningsvillkor',
  },
  spelregler: {
    value: 'https://yohrs-prod.s3.eu-north-1.amazonaws.com/spelregler-sv.pdf',
    label: 'Yohrs spelregler',
  },
}
export const VillkorPage = observer(() => {
  const [link, setLink] = useState(links.användarvillkor.value)

  const onChange = ((value: string) => {
    setLink(value)
  })

  return (
    <>
      <AppBox sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

      }}>
        <PageTitle textValue={'Villkor'} />
        <AppBox sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
          marginBottom: 20,
        }}>
          <VillkorSelector value={link} onChange={onChange} links={links} />
          <DownloadButton />
        </AppBox >

        <AppBox sx={{
          position: 'relative',
          width: '100vw',
          minHeight: '100vh',
        }}>
          <iframe
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
            }}
            src={`${link}?#zoom=85&scrollbar=0&view=fit&embedded=true`}>
          </iframe>
        </AppBox >
      </AppBox>

    </>
  )
})
