import { observer } from 'mobx-react-lite'
import { Box, CircularProgress, CircularProgressProps, circularProgressClasses } from '@mui/material'
import { Caption } from 'src/feature/localization/component/atom/Caption'

export type CircularProgressWithLabelProp = CircularProgressProps & {
  value: number
}

export const CircularProgressWithLabel = observer((props: CircularProgressWithLabelProp) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', width: '45px', height: '45px' }}>
      <CircularProgress variant="determinate" value={100} sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        color: 'text.secondary',
      }} size={45} thickness={4} />
      <CircularProgress variant="determinate" sx={{
        animationDuration: '600ms',
        position: 'absolute',
        left: 0,
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'round',
        },
      }} size={45} thickness={4} {...props} color='secondary' />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Caption
          variant="caption"
          component="div"
          color="text.primary"
          sx={{ fontWeight: 'bold', fontSize: '0.75em' }}
        >{`${Math.round(props.value)}%`}</Caption>
      </Box>
    </Box>
  )
})