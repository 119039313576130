import { BaseSidebar } from './BaseSidebar'
import { AppStack } from '../../../component/common/analog/molecule/list/AppStack'
import { LINK_LABEL_CLASS_NAME, NavListItem } from '../../../component/common/original/molecule/NavListItem'
import { SidebarProps } from '../model/Sidebar'
import { ChildrenProps } from '../../../model/React'
import { Word } from '../../localization/component/atom/Word'
import { AppDivider } from '../../../component/common/analog/molecule/AppDivider'

export type DesktopSidebarProps = SidebarProps & ChildrenProps

export function DesktopSidebar(props: DesktopSidebarProps) {
  return (
    <BaseSidebar {...props.baseSidebarProps}>

      {props.nav && (
        <AppStack direction={'column'} spacing={32}>
          {Object.entries(props.nav!).filter(([_, v]) => !v.props.hidden)
            .map(([k, v]) => {
              if (v.isDivider) {
                return <AppDivider key={k} {...v.props} />
              }

              return (
                <NavListItem key={k} {...v.props} >
                  <Word className={LINK_LABEL_CLASS_NAME} textValue={v.label} />
                </NavListItem>
              )
            })}
        </AppStack>
      )}

      {props.children}
    </BaseSidebar>
  )
}
