import { observer } from 'mobx-react-lite'
import { AppButton } from '../../../component/common/analog/molecule/button/AppButton'

export const DownloadButton = observer(() => {
  const onClick = () => {
    window.open('https://yohrs-prod.s3.eu-north-1.amazonaws.com/table-villkor.pdf', '_blank')
  }

  return (
    <AppButton onClick={onClick} style={{
      maxWidth: 200,
      maxHeight: 60,
      textDecoration: 'none',
      border: '1px solid grey',
      padding: '18px 10px',
      verticalAlign: 'center',
      borderRadius: 8,
    }} target="_blank" download>Ångerblankett</AppButton>
  )
})
