import { AppThemeMode } from '../model/Mode'
import { deepmerge } from '@mui/utils'
import { responsiveFontSizes as addResponsiveFonts, Theme } from '@mui/material'
import lodashFpFlow from 'lodash/fp/flow'
import { baseThemeOptions } from './mui-option/base-theme-options'
import { lightModePalette } from './mui-option/palette/light-mode'
import { overrideComponents } from './mui-option/component/override-components'
import { overrideTypography } from './mui-option/typography/override-typography'
import { overrideDivider } from './mui-option/divider'
import { CssUnit, handleCssUnit as sourceHandleCssUnit } from '../model/CssUnit'
import { darkModePalette } from './mui-option/palette/dark-mode'

function getBaseThemeOptionsByMode(mode: AppThemeMode) {
  let palette
  switch (mode) {
  default:
  case 'light':
    palette = lightModePalette
    break
  case 'dark':
    palette = darkModePalette
    break
  }

  return deepmerge(baseThemeOptions, { palette })
}

function passCssUnitHelper(theme: Theme) {
  return deepmerge(theme, {
    handleCssUnit: sourceHandleCssUnit,
  })
}

function upgradeTheme(theme: Theme) {
  const { handleCssUnit, palette } = theme

  return deepmerge(theme, {
    border: `0.115em solid ${palette.grey[0]}`,
    getSquareSize: (size: CssUnit) => ({
      // width
      width: handleCssUnit(size),
      // height
      height: handleCssUnit(size),
    }),
  })
}

export function getThemeOptionsByMode(mode: AppThemeMode): Theme {
  return (
    lodashFpFlow(
      getBaseThemeOptionsByMode,
      passCssUnitHelper,
      upgradeTheme,
      overrideDivider,
      overrideComponents,
      overrideTypography,
      addResponsiveFonts,
    )(mode)
  )
}
