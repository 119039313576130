import { observer } from 'mobx-react-lite'
import { useDashboardStore } from '../store/dashboard.store'
import { useEffect } from 'react'
import { Box } from '@mui/material'
import { Title2 } from 'src/feature/localization/component/atom/Title2'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { CoursePlanItem } from 'src/feature/e-learning/component/organism/CoursePlanItem'
import { AppCarousel } from 'src/component/common/analog/molecule/carousel/AppCarousel'
import { Theme } from '@mui/material/styles'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { useNavigate } from 'react-router-dom'
import { ELearningCoursePlanItem } from 'src/feature/e-learning/model/ELearningCoursePlanItem'

export type UserCoursesProps = {
  userId: string
}

export const UserCourses = observer(({ userId }: UserCoursesProps) => {
  const { translation } = useLocalizationStore()
  const navigate = useNavigate()
  const translations = translation.pages.myTeam
  const dashboardStore = useDashboardStore()
  const { isLoading } = dashboardStore.getGenericFieldsState('getUserCourses')

  useEffect(() => {
    dashboardStore.userCourses = undefined
    dashboardStore.getUserCourses(userId)
  }, [])

  const transform = (item: ELearningCoursePlanItem, index: number) => <CoursePlanItem item={item} index={index} key={item.id} managerView={true} />
  const sort = (a: ELearningCoursePlanItem, b: ELearningCoursePlanItem) => {
    if (a.dealine && !b.dealine) {
      return -1
    }

    if (b.dealine && !a.dealine) {
      return 1
    }

    if (a.dealine && b.dealine) {
      return new Date(a.dealine).getTime() - new Date(b.dealine).getTime()
    }

    if (a.progress && !b.progress) {
      return -1
    }

    if (b.progress && !a.progress) {
      return 1
    }

    if (a.progress && b.progress) {
      return b.progress.percentage - a.progress.percentage
    }

    return new Date(a.created).getTime() - new Date(b.created).getTime()
  }

  return (
    <Box sx={styles.root}>
      <AppBox sx={styles.courseContainer}>
        <AppBox sx={{ alignItems: 'center' }}>
          <Title2 m={0}>
            {`${translations.assignedToDepartment()} ${dashboardStore.userOverview?.[userId]?.userName} ${dashboardStore.userCourses?.completedAssignedCourses || '0'} of ${dashboardStore.userCourses?.assignedCourses?.length || '0'} ${translations.rolesButton.finishButton().toLowerCase()}`}</Title2>
        </AppBox>
        {!isLoading && dashboardStore.userCourses && dashboardStore.userCourses?.assignedCourses?.length !== 0 && <AppCarousel showDots={true} showProgressBar={false}>
          {dashboardStore.userCourses?.assignedCourses?.slice().sort(sort)
            .map(transform)
          }
        </AppCarousel>}
        {dashboardStore.userCourses && dashboardStore.userCourses?.assignedCourses?.length === 0 && <AppBox sx={{ flexDirection: 'column', alignItems: 'center', color: 'text.secondary', gap: 5 }}>
          <Caption sx={{ color: 'text.secondary' }}>{translations.errorMsg.noAssignedCourses()}</Caption>
          <Caption sx={{ fontWeight: 500, cursor: 'pointer', color: 'secondary.main' }} onClick={() => navigate(appRoutePath.learning)}>{translation.pages.myTeam.redirectionMessage()}</Caption>
        </AppBox>}
      </AppBox>
      <AppBox sx={styles.courseContainer}>
        <Title2 m={0}>{`${dashboardStore.userCourses?.completedUserCourses || '0'} of ${dashboardStore.userCourses?.completedCourses?.length || '0'} ${translation.pages.eLearning.courses().toLowerCase()} ${translations.rolesButton.finishButton().toLowerCase()}`}</Title2>
        {!isLoading && dashboardStore.userCourses && dashboardStore.userCourses?.completedCourses?.length !== 0 && <AppCarousel showDots={true} showProgressBar={false}>
          {dashboardStore.userCourses?.completedCourses?.slice().sort(sort)
            .map(transform)
          }
        </AppCarousel>}
        {dashboardStore.userCourses && dashboardStore.userCourses?.completedCourses?.length === 0 && <AppBox sx={{ flexDirection: 'column', alignItems: 'center', color: 'text.secondary', gap: 5 }}>
          <Caption sx={{ color: 'text.secondary' }}>{translations.errorMsg.noAssignedCourses()}</Caption>
          <Caption sx={{ fontWeight: 500, cursor: 'pointer', color: 'secondary.main' }} onClick={() => navigate(appRoutePath.learning)}>{translation.pages.myTeam.redirectionMessage()}</Caption>
        </AppBox>}
      </AppBox>
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4rem',
  },
  courseContainer: (theme: Theme) => ({
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: '0.75rem',
    border: `1px solid ${theme.palette.alternate.background}40`,
    padding: '0.75rem 0.75rem 0.4rem 0.75rem',
    gap: 5,
    maxWidth: '100%',
  }),
}
