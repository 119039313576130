import { AppBox, ContainerProps } from './AppBox'
import { NotOverridableComponentProps } from '../../../../model/Component'

export type AppContainerProps = NotOverridableComponentProps<ContainerProps>

export function AppContainer(props: AppContainerProps) {
  return (
    <AppBox
      isContainer
      {...props}
    />
  )
}
