import { AppBox, AppBoxProps } from '../AppBox'
import { GridProps } from '@mui/material/Grid'

export type AppFlexItemProps = Omit<GridProps & AppBoxProps, 'item' | 'container' | 'looksLike'>

export function AppFlexItem(props: AppFlexItemProps) {
  return (
    <AppBox
      component={'li'}
      {...props}
      isFlexible
      item
    />
  )
}
