import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import { Maybe } from '../../../../../model/Util'
import { handlePassedSx } from '../../../../../feature/theme/model/Sx'
import { Theme } from '@mui/material'
import { NotOverridableComponentProps } from '../../../../../model/Component'
import { AppBox } from '../AppBox'

interface BaseProps extends LinearProgressProps {
  isShowing: Maybe<boolean>
}

export type AppProgressLineProps = NotOverridableComponentProps<BaseProps>

export function AppProgressLine(props: AppProgressLineProps) {
  const { sx = [], isShowing, ...rest } = props

  if (!isShowing) {
    return null
  }

  return (
    <AppBox
      component={LinearProgress}
      sx={[
        styles,
        ...handlePassedSx(sx),
      ]}
      {...rest}
    />
  )
}

const styles = ({ palette }: Theme) => ({
  height: '0.35em',
  maxWidth: '100%',
  width: '100%',
  mt: '1em',
  borderRadius: 0,
  '&.root': {
    backgroundColor: palette.primary.dark,
  },
  '&.bar1Indeterminate': {
    backgroundColor: palette.primary.light,
  },
  '&.bar2Indeterminate': {
    backgroundColor: palette.grey[8],
  },
})
