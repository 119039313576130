import { EntryFields } from 'contentful'

import { AssetData } from '../../../../feature/contentful/model/Contentful'
import { AppInternalLink } from '../../analog/molecule/link/AppInternalLink'
import { Image } from '../../../../feature/image/component/Image'
import { AppBox } from '../../analog/molecule/AppBox'
import { Box, Theme } from '@mui/material'
import {
  subtitle1FontSize,
} from '../../../../feature/theme/util/mui-option/typography/override-typography'
import { LinkPath } from '../../../../model/Link'
import { SxHelperStyle } from '../../../../feature/theme/style/sx-helper.style'
import { Paragraph } from '../../../../feature/localization/component/atom/Paragraph'
import { Title2 } from 'src/feature/localization/component/atom/Title2'

interface Props {
  title: string
  picture: AssetData
  path: LinkPath
  description?: EntryFields.RichText
  shortDescription?: string
  paymentRequired?: boolean
  hasSubscription?: boolean
}

export function PlatformCard({ title, picture, path, shortDescription, paymentRequired, hasSubscription }: Props) {
  return (
    <AppInternalLink
      isPaper
      to={paymentRequired && !hasSubscription ? '/profile/subscription' : path}
      sx={styles.root}
      underline={'none'}
    >
      <Box style={{ position: 'relative', overflow: 'hidden' }}>
        <Image src={picture.url} alt={picture.alt} outerProps={{ sx: paymentRequired ? hasSubscription ? styles.unlockedImageRoot : styles.lockedImageRoot : styles.imageRoot }} />
        {paymentRequired && <Box sx={{ ...styles.paymentRequired, ...(hasSubscription ? {} : styles.paymentAnimation) }}>{hasSubscription ? '💎' : '🔑'}</Box>}
      </Box>
      <AppBox sx={styles.textWrapper}>
        <Title2 textValue={title} />
        <Paragraph textValue={shortDescription} />
      </AppBox>
    </AppInternalLink>
  )
}

const styles = {
  root: {
  },
  textWrapper: ({ handleCssUnit }: Theme) => ({
    ...SxHelperStyle.flexColumn,
    fontSize: handleCssUnit(subtitle1FontSize),
    padding: 12,
    '& > h2::first-letter': {
      color: '#36BBAF',
    },
  }),
  imageRoot: ({ handleCssUnit }: Theme) => ({
    height: handleCssUnit(300),
    width: 1,
    transition: '.3s ease-in-out',
    position: 'relative',
    '&:hover': {
      transform: 'scale(0.9)',
    },
    '&:after': {
      position: 'absolute',
      content: '""',
      left: 0,
      top: 0,
      transition: '.3s ease-in-out',
      width: '100%',
      height: '100%',
    },
  }),
  lockedImageRoot: ({ handleCssUnit }: Theme) => ({
    height: handleCssUnit(300),
    width: 1,
    transition: '.3s ease-in-out',
    position: 'relative',
    '&:after': {
      position: 'absolute',
      content: '""',
      left: { sm: '-60%', xs: '-65%' },
      bottom: { sm: '-60%', xs: '-65%' },
      width: '100%',
      height: '100%',
      transform: 'rotate(45deg)',
      backgroundColor: '#C7B8F8',
    },
  }),
  unlockedImageRoot: ({ handleCssUnit }: Theme) => ({
    height: handleCssUnit(300),
    width: 1,
    transition: '.3s ease-in-out',
    position: 'relative',
    '&:after': {
      position: 'absolute',
      content: '""',
      left: { sm: '-60%', xs: '-65%' },
      bottom: { sm: '-60%', xs: '-65%' },
      width: '100%',
      height: '100%',
      transform: 'rotate(45deg)',
      backgroundColor: '#C7B8F8',
      transition: '.3s ease-in-out',
    },
    '&:hover': {
      transform: 'scale(0.9)',
    },
  }),

  paymentRequired: {
    position: 'absolute',
    fontSize: '2em',
    bottom: 0,
    left: 0,
    display: 'inline-block',
    marginBottom: 10,
    marginLeft: 10,
  },

  paymentAnimation: {
    animationName: 'rotateHand',
    animationDuration: '2.5s',
    animationIterationCount: 'infinite',
    '@keyframes rotateHand': {
      '0%': { transform: 'rotateZ( 0.0deg)' },
      '10%': { transform: 'rotateZ(14.0deg)' },
      '20%': { transform: 'rotateZ(-8.0deg)' },
      '30%': { transform: 'rotateZ(14.0deg)' },
      '40%': { transform: 'rotateZ(-4.0deg)' },
      '50%': { transform: 'rotateZ(10.0deg)' },
      '60%': { transform: 'rotateZ( 0.0deg)' },
      '100%': { transform: 'rotateZ( 0.0deg)' },
    },
  },

}
