import { TranslatableText, TranslatableTextVariantProps } from './TranslatableText'

export function Word(props: TranslatableTextVariantProps) {
  return (
    <TranslatableText
      looksLike={TranslatableText}
      variant={'body1'}
      component={'span'}
      {...props}
    />
  )
}
