import { AdditionalService } from '../model/AdditionalService'
import {
  getLocalizedEntry,
  getLocalizedEntryList,
  GetLocalizedEntryListRequest,
  GetLocalizedEntryRequest,
} from '../../contentful/model/Api'

export async function getAdditionalServices(request: GetLocalizedEntryListRequest) {
  return getLocalizedEntryList<AdditionalService>({
    ...request.params,
    content_type: 'additionalServices',
  })
}

export async function getAdditionalService(request: GetLocalizedEntryRequest) {
  return getLocalizedEntry<AdditionalService>(request)
}
