import { getRouteObjectList, withLayout } from '../feature/router/util/router.helper'
import { RouterLayout } from '../feature/router/component/layout/RouterLayout'

function SignUpProvider() {
  return (
    <RouterLayout />
  )
}

export const SignUpRoutes = withLayout({
  layout: <SignUpProvider />,
  routes: getRouteObjectList(['signUp']),
})
