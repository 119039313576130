import { getRouteObjectList, withLayout } from '../router/util/router.helper'
import { RouterLayout } from '../router/component/layout/RouterLayout'
import { KnowledgeSidebar } from './component/organism/KnowledgeSidebar'
import { knowledgeRouteIds } from '../router/model/RouteId'

export const KnowledgeRoutes = [
  withLayout(
    {
      layout: <RouterLayout sidebar={<KnowledgeSidebar />} />,
      routes: getRouteObjectList(knowledgeRouteIds),
    },
  ),
]
