import { LocalizedStringFn, Translatable, TranslatedText } from './Text'
import { ReactElement } from '../../../model/React'
import * as ReactIs from 'react-is'
import { Id } from '../../../model/Id'
import { TranslatableBoxProps } from '../component/atom/TranslatableBox'

export const UNKNOWN_TEXT = '--------'

export function localTranslate(value: Translatable, returnUnknownTextOnError = true): TranslatedText | undefined {
  try {
    switch (typeof value) {
    case 'function':
      return value()
    case 'string':
      return value
    case 'number':
      return value.toString()
    default:
      // AppLogger.error('Unknown value:', value)

      if (returnUnknownTextOnError) {
        return UNKNOWN_TEXT
      }
    }
  } catch (e) {
    // AppLogger.error('localTranslate value:', value)

    if (returnUnknownTextOnError) {
      return UNKNOWN_TEXT
    }
  }
}

export interface TranslationListItemObj extends TranslatableBoxProps {
  id: Id
  textValue: LocalizedStringFn
}

export type TranslationListType = Array<TranslationListItemObj | LocalizedStringFn>

export function renderNodeOrLocalTranslate(value: unknown): ReactElement |ReturnType<typeof localTranslate> {
  return (
    ReactIs.isElement(value)
      ? (value as unknown as ReactElement)
      : localTranslate(value as Translatable)
  )
}
