/* eslint-disable no-console */
import { isExactlyProd } from '../stage'

type LoggerFn = (...args: any[]) => void

export interface LoggerModel {
  info: LoggerFn
  error: LoggerFn
}

export const AppLogger: LoggerModel = {
  info: (...args) => {
    if (isExactlyProd) {
      return
    }

    console.info(...args)
  },
  error: (...args) => {
    if (isExactlyProd) {
      return
    }

    console.error(...args)
  },
}
