import { logAxios } from '../log.axios'
import { AxiosRequestConfig } from 'axios'
import { authStore } from '../../../feature/auth/store/auth.store'
import { authApiUrl } from '../../../feature/auth/api/auth'

export function handleFulfilledAxiosRequest(request: AxiosRequestConfig) {
  logAxios(request)

  handleUrl(request)
  handleAuth(request)

  return request
}

function handleAuth(request: AxiosRequestConfig) {
  if (request?.url?.startsWith('http')) {
    request.withCredentials = false
    request.baseURL = undefined
  } else if (request?.headers) {
    const resultToken = request?.url?.includes(authApiUrl.updateAccessToken) ? authStore.refreshToken : authStore.accessToken

    const isNoCustomToken = request?.headers?.Authorization === undefined

    if (isNoCustomToken) {
      request.headers['Authorization'] = `Bearer ${resultToken}`
    }
  }
}

function handleUrl(request: AxiosRequestConfig) {
  if (!request?.url) {
    throw new Error('Invalid Link')
  }
}
