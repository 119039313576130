import {
  ArticleModel,

} from '../../contentful/model/Contentful'
import {
  getLocalizedEntry,
  getLocalizedEntryList,
  GetLocalizedEntryListRequest,
  GetLocalizedEntryRequest,
} from '../../contentful/model/Api'
// import { AdditionalService } from 'src/feature/knowledge/model/AdditionalService'

export async function getBlogArticleList(request: GetLocalizedEntryListRequest) {
  return getLocalizedEntryList<ArticleModel>({
    locale: request.locale,
    content_type: 'article',
    tags: request.tags,
  })
}

export async function getBlogMainVideo(request: GetLocalizedEntryListRequest) {
  return getLocalizedEntryList<ArticleModel>({
    ...request.params,
    content_type: 'blogVideo',
  })
  // return getLocalizedEntry<ArticleModel>(request)
}

export async function getBlogArticle(request: GetLocalizedEntryRequest) {
  return getLocalizedEntry<ArticleModel>(request)
}
