import * as mobx from 'mobx'
import { createContext, useContext, useEffect } from 'react'
import { ChildrenProps } from '../../../model/React'
import { GenericStore } from './generic.store'
import { IS_PRODUCTION_LIKE } from 'src/util/constant'

mobx.configure({
  enforceActions: 'never',
  disableErrorBoundaries: !IS_PRODUCTION_LIKE,
})

export function createStore<T0 extends InstanceType<typeof GenericStore>>(storeInstance: T0) {
  // const frozenStore = freeze(storeInstance)
  const StoreContext = createContext(storeInstance)

  function StoreProvider({ children }: ChildrenProps) {
    return (
      <StoreContext.Provider value={storeInstance}>
        {children}
      </StoreContext.Provider>
    )
  }

  function useStore(): T0 {
    const context = useContext(StoreContext) as T0
    const storeName = context.constructor.name

    if (!context) {
      throw new Error(
        `React hook of ${storeName} class must be used within its provider`,
      )
    }

    useEffect(() => {
      context.resetGenericFields()

      // return () => context.stopPersisting()
    }, [])

    return context
  }

  return { StoreProvider, storeInstance, useStore }
}
