import { observer } from 'mobx-react-lite'
import { ELearningCourseCategory } from '../../model/ELearningCourseCategory'
import { Chip, Fade, Icon } from '@mui/material'
import { startCase } from 'lodash'
import { iconMapping } from 'src/util/icon-mapping'

export type CategoryItemProp = {
  item: ELearningCourseCategory
  index: number
  selectedSubCategory: string
  clickHandler?: (item: ELearningCourseCategory) => void
}

export const CourseItem = observer(({ item, index, selectedSubCategory, clickHandler }: CategoryItemProp) => {
  return (
    <Fade in={true} timeout={index * 100} mountOnEnter unmountOnExit>
      <Chip
        key={index}
        variant='outlined'
        icon={<Icon sx={{ color: 'secondary.main', height: '18px', width: '18px' }} color='inherit' component={iconMapping['FolderCopyRoundedIcon']} />}
        sx={{ ...styles.chip, ...(selectedSubCategory !== item.id ? '' : { borderColor: 'secondary.main' }) }}
        label={startCase(item.titleLocale)}
        onClick={() => clickHandler && clickHandler(item)}
      />
    </Fade>
  )
})

const styles = {
  chip: {
    fontSize: '14px',
    padding: '0.5rem 0.75rem',
    transition: 'backgroundColor 0.3s ease, borderColor 0.3s ease',
    '&:hover': {
      backgroundColor: 'unset !important',
      borderColor: 'secondary.main',
    },
    ':active': {
      boxShadow: 'unset !important',
    },
  },
}
