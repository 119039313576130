import { useState } from 'react'
import { BoolProps, IsTrue, toggle as toggleValue } from '../../model/Boolean'

export function useBool(defaultValue: IsTrue = false): BoolProps {
  const [isTrue, update] = useState<IsTrue>(defaultValue)

  const toggle = () => update(toggleValue)
  const reset = () => update(null)
  const makeFalse = () => update(false)
  const makeTrue = () => update(true)

  return {
    isTrue,
    update,
    reset,
    toggle,
    makeFalse,
    makeTrue,
  }
}
