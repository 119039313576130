import { AppButton, AppButtonProps } from '../button/AppButton'
import { AppBaseMuiLink, AppBaseMuiLinkProps } from './AppBaseMuiLink'
import { AppBox, AppBoxProps } from '../AppBox'

type Kind = 'buttonLikeLink' | 'linkLikeButton' | 'linkLikeLink'

export type AppBaseLinkProps = (AppButtonProps | AppBaseMuiLinkProps) & {
  kind?: Kind
}

export type BaseLinkViewComponentModel = { [K in Kind]: any }

const RenderAsComponent: BaseLinkViewComponentModel = {
  buttonLikeLink: (props: AppBaseMuiLinkProps) => (
    <AppBaseMuiLink
      type={'button'} // Don't remove this, because if you use this component in form, without this, the button will be recognized as submit button
      {...props}
      component={'button'} // Don't change the placement of this prop (for avoiding overriding it). It should be after {...props}
    />
  ),
  linkLikeButton: AppButton,
  linkLikeLink: AppBaseMuiLink,
}

export function AppBaseLinkKind(props: AppBaseLinkProps) {
  const { kind = 'linkLikeLink', component, display = 'inline', ...rest } = props

  const KindComponent = RenderAsComponent[kind]

  return (
    <AppBox
      component={(componentProps: AppBoxProps) => <KindComponent {...componentProps} component={component} />}
      display={display}
      {...rest}
    />
  )
}
