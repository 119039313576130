import { Autocomplete, Box, createFilterOptions, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { AppTextField } from 'src/feature/form/component/analog/AppTextField'
import { Title1 } from 'src/feature/localization/component/atom/Title1'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { useNotificationStore } from 'src/feature/notification/store/notification.store'
import { ProfessionType } from 'src/feature/skill-assessment/model/skill-assessment-model'
import { useSkillAssessmentStore } from 'src/feature/skill-assessment/store/skill-assessment.store'

export type ExpectedRatingProps = {
  levelId: string
  professionsData: ProfessionType[]
  onChange: () => void
}

export const AddExpectedRole = observer(({ levelId, professionsData, onChange }: ExpectedRatingProps) => {
  const { translation, locale } = useLocalizationStore()
  const translations = translation.pages.myTeam
  const skillAssessmentStore = useSkillAssessmentStore()
  const notificationStore = useNotificationStore()
  const { isError: isAdddedSkillCategory } = skillAssessmentStore.getGenericFieldsState('addSkillCategory')
  const [profession, setProfession] = useState<ProfessionType | null>(null)
  const filter = createFilterOptions<ProfessionType>()

  const handleProfessionChange = (_: any, newValue: ProfessionType | null) => {
    if (newValue && newValue.inputValue) {
      setProfession({
        id: newValue.id,
        title: newValue.inputValue,
      })
    } else {
      setProfession(newValue || null)
    }
  }

  const handleProfessionFilterChange = (options: Array<ProfessionType>, params: any) => {
    const filtered = filter(options, params)
    const { inputValue } = params
    const isExisting = options.some(option => inputValue === option.title)
    
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        inputValue,
        id: `default-id-'${inputValue.toLowerCase()}'`,
        title: `Add '${inputValue}'`,
      })
    }

    return filtered
  }

  const handleProfessionSave = async () => {
    const titleRegex = /^.{1,50}$/
    
    if (profession && titleRegex.test(profession.title)) {
      await skillAssessmentStore.addSkillCategory(profession.title, levelId, locale).then(() => {
        if (!isAdddedSkillCategory) {
          notificationStore.enqueueInfo(translations.errorMsg.roleAdded())
          onChange()
        } else {
          notificationStore.enqueueError(translation.util.unknownErrorOccurred())
        }
      })
    } else {
      notificationStore.enqueueError(translations.errorMsg.roleError())
    }
  }

  return (
    <Box sx={styles.noSkillWrapper}>
      <AppBox sx={{ flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
        <Title1>{translations.setDemandHeader()}</Title1>
        <Typography
          sx={{ width: { xs: '100%', md: '85%', lg: '70%' }, textAlign: 'center', color: 'text.secondary', hyphens: 'auto', overflowWrap: 'break-word' }}
          lang={locale}
        >{translations.setDemandDesc()}</Typography>
      </AppBox>
      <AppBox sx={styles.professionSelect}>
        <Autocomplete
          sx={{
            width: { xs: '100%', md: '40%', lg: '30%' },
            '& .MuiAutocomplete-popupIndicator, & .MuiAutocomplete-clearIndicator': {
              color: 'text.primary',
            },
          }}
          value={profession}
          onChange={handleProfessionChange}
          filterOptions={handleProfessionFilterChange}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id='profession'
          size='small'
          options={professionsData || []}
          getOptionLabel={option => option.title || ''}
          renderOption={(props, option) => (
            <li {...props}>
              {option.title}
            </li>
          )}
          renderInput={params => (
            <AppTextField {...params} placeholder={translations.setDemandPlaceholder()} />
          )}
        />
        <AppButton size='small' fontSize={16.5} color='primary' variant='contained' onClick={handleProfessionSave} disabled={!profession}>{translations.setDemandButton()}</AppButton>
      </AppBox>
    </Box>
  )
})

const styles = {
  noSkillWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
    padding: { xs: '0 1rem', md: '0 3rem' },
  },
  professionSelect: {
    flexDirection: { xs: 'column', md: 'row' },
    gap: 20,
    alignItems: { xs: 'center', md: 'flex-start' },
    justifyContent: 'center',
    width: '100%',
  },
}
