import { AnyObject } from '../model/Object'
import { forwardRef, useMemo } from 'react'
import { ComponentModel } from '../model/React'

interface UseMemoForwardedOptions {
  Component: ComponentModel
  additionalProps?: AnyObject
  deps?: any[]
  manageProps?: (props: AnyObject) => AnyObject
}

export function useMemoForwarded(options: UseMemoForwardedOptions) {
  const { Component, additionalProps = {}, deps = [], manageProps = passedProps => passedProps } = options

  const forwarded = forwardRef((props, ref) => (
    <Component
      {...manageProps(props)}
      {...additionalProps}
      ref={ref}
    />
  ))

  forwarded.displayName = Component.displayName

  return useMemo(() => forwarded, deps)
}
