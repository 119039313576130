import { ConditionalWrapper } from '../atom/ConditionalWrapper'
import { useLocalizationStore } from '../../../../feature/localization/store/localization.store'
import { observer } from 'mobx-react-lite'
import { Image } from '../../../../feature/image/component/Image'
import WhiteLogo from 'src/feature/image/asset/logo/white-logo.svg'
import PurpleLogo from 'src/feature/image/asset/logo/purple-logo.svg'
import BlackLogo from 'src/feature/image/asset/logo/black-logo.svg'
import { Theme } from '@mui/material'
import { AppBoxProps } from '../../analog/molecule/AppBox'
import { AppInternalLink } from '../../analog/molecule/link/AppInternalLink'
import { appHomePath } from 'src/feature/router/util/app-route-path'

export enum LogoType {
  PURPLE = 'purple',
  BLACK = 'black',
  WHITE = 'white'
}

const defaultLogo = LogoType.BLACK

interface Props extends AppBoxProps {
  blockLogo?: boolean
  type?: LogoType
}

export const Logo = observer((props: Props) => {
  const {
    type = defaultLogo,
    blockLogo = false,
  } = props
  const src = getLogo(type)
  const { translation } = useLocalizationStore()

  const isLogoClickable = !blockLogo

  return (
    <ConditionalWrapper
      condition={isLogoClickable}
      wrapper={children => <AppInternalLink to={appHomePath}>{children}</AppInternalLink>}
    >
      <Image
        src={src}
        alt={translation.components.logo.alt}
        outerProps={{
          sx: styles,
        }}
      />
    </ConditionalWrapper>
  )
})

const styles = ({ handleCssUnit }: Theme) => ({
  mr: 50,
  width: 1,
  minWidth: handleCssUnit(70),
  maxWidth: handleCssUnit(82),
})

function getLogo(logoType: LogoType) {
  switch (logoType) {
  case LogoType.PURPLE:
    return PurpleLogo
  case LogoType.BLACK:
  default:
    return BlackLogo
  case LogoType.WHITE:
    return WhiteLogo
  }
}
