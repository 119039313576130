import { TranslatableText, TranslatableTextVariantProps } from './TranslatableText'

export function Title3(props: TranslatableTextVariantProps) {
  return (
    <TranslatableText
      variant={'h3'}
      {...props}
      component={'h3'}
    />
  )
}