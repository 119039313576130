import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { useNavigate } from 'react-router-dom'
import { MetricWithValue } from './MetricWithValue'
import { useEffect } from 'react'
import { useDashboardStore } from '../store/dashboard.store'
import { Fade, Tooltip, Typography } from '@mui/material'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { Theme } from '@mui/material/styles'

export type DepartmentRoleCardProps = {
  levelId: string
}

export const DepartmentRoleCard = observer(({ levelId }: DepartmentRoleCardProps) => {
  const { translation } = useLocalizationStore()
  const translations = translation.pages.myTeam
  const navigate = useNavigate()
  const dashboardStore = useDashboardStore()

  useEffect(() => {
    dashboardStore.getTeamOverview(levelId)
  }, [levelId])

  return (
    <Fade in={true} timeout={500} mountOnEnter unmountOnExit>
      <AppPaper key={levelId} sx={styles.root} onClick={() => navigate(appRoutePath.administratorUsers(levelId))}>
        <Tooltip title={dashboardStore.teamOverview?.[levelId]?.organisationLevelName} arrow>
          <Typography style={{ fontSize: '1.3rem', fontWeight: 500, maxWidth: '15rem' }} noWrap>{dashboardStore.teamOverview?.[levelId]?.organisationLevelName}</Typography>
        </Tooltip>
        <AppBox sx={styles.metricsWrapper}>
          <MetricWithValue size='small' label={translations.metricsHeader.totalUsers()} value={dashboardStore.teamOverview?.[levelId]?.totalUsers || 0} />
          <MetricWithValue size='small' label={translations.metricsHeader.activeUsers()} value={dashboardStore.teamOverview?.[levelId]?.activeUsers || 0} />
          <MetricWithValue size='small' label={translations.metricsHeader.inactiveUsers()} value={dashboardStore.teamOverview?.[levelId]?.inactiveUsers || 0} />
          <MetricWithValue size='small' label={translations.metricsHeader.coursesGiven()} value={dashboardStore.teamOverview?.[levelId]?.assignedCourses || 0} />
          <MetricWithValue size='small' label={translations.metricsHeader.coursesCompleted()} value={dashboardStore.teamOverview?.[levelId]?.totalCoursesUsersCompleted || 0} />
          <MetricWithValue
            color={(dashboardStore.teamOverview?.[levelId]?.completionRate || 0) < 50 ? 'error.main' : 'success.main'}
            label={translations.metricsHeader.completionRate()}
            value={`${dashboardStore.teamOverview?.[levelId]?.completionRate || 0}%`}
          />
        </AppBox>
      </AppPaper>
    </Fade>
  )
})

const styles = {
  root: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 15,
    padding: '2em 0.5em 1em 0.5em',
    borderRadius: '12px',
    transition: 'transform 1s ease, border-color 0.3s ease',
    '&:hover': {
      borderColor: `${theme.palette.primary.main}60`,
      transform: 'scale(1.02)',
    },
    cursor: 'pointer',
    maxWidth: '25em',
  }),
  metricsWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(3, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(3, 1fr)' },
    gap: 1,
    position: 'relative',
  },
}
