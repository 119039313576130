import { GenericStore } from '../generic.store'
import {
  CatchFinallyCustom,
  defaultOptions,
  MethodAllProps,
  MethodOptions,
  TryBeginningCustom,
} from './decorator.helper'
import lodashDefaultsDeep from 'lodash/defaultsDeep'
import { combineDecorators } from '../../../../model/Decorator'

/*
  // The "GenericStoreMethod" decorator was created for reducing "GenericStore" boilerplate.

  // from something like:

  method() {
    try {
      this._success = null
      this.startLoading()
      // your code
    } catch (e) {
      AppLogger.error(e)
      throw e
    } finally {
      this.endLoading()
    }
  }

   // to:

  method() {
    // your code
  }

 */

export function GenericStoreMethod<Context extends GenericStore = GenericStore>(
  options: MethodOptions = defaultOptions,
) {
  const settedOptions: MethodAllProps = lodashDefaultsDeep(options, defaultOptions)

  return combineDecorators(
    TryBeginningCustom<Context>(settedOptions),
    CatchFinallyCustom<Context>(settedOptions),
  )
}
