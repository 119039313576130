import { handlePassedSx } from '../../theme/model/Sx'
import { useLocalizationStore } from '../../localization/store/localization.store'
import { localTranslate } from '../../localization/model/Translate'
import { observer } from 'mobx-react-lite'
import { NotOverridableComponentProps } from '../../../model/Component'
import { MuiSystemProp } from '../../theme/util/theme.constant'
import { Translatable } from '../../localization/model/Text'
import { AppLazyLoaded, AppLazyLoadedProps } from '../../../component/common/analog/molecule/AppLazyLoaded'
import { AppBox } from '../../../component/common/analog/molecule/AppBox'

type ImageSize = 'full' | 'unset'

// type HTMLImageProps = JSX.IntrinsicElements.img
type HTMLImageProps = {
  alt: string
  src: string
}

interface BaseImageProps {
  alt: Translatable
  src: string
}

export type ImageProps = BaseImageProps & {
  outerProps?: AppLazyLoadedProps
  innerProps?: NotOverridableComponentProps & Omit<HTMLImageProps, keyof HTMLImageProps>
  imageSize?: ImageSize
}

export const Image = observer((props: ImageProps) => {
  const { translation } = useLocalizationStore()
  const {
    src = '',
    alt = '',
    imageSize = 'full',
    outerProps: {
      isLazyLoaded = false,
      sx: rootSx = [],
      once = true,
      ...restRootProps
    } = {},
    innerProps: {
      sx: imageSx = [],
      ...restImageProps
    } = {},
  } = props

  const $alt = alt || translation.util.image

  const $rootProps = {
    sx: [
      styles.root,
      ...handlePassedSx(rootSx),
    ],
    ...restRootProps,
  }

  const $imageProps = {
    component: 'img',
    src,
    alt: localTranslate($alt),
    sx: [
      styles.image({ imageSize }),
      ...handlePassedSx(imageSx),
    ],
    ...restImageProps,
  }

  if (isLazyLoaded) {
    return <AppLazyLoaded once={once} {...$rootProps} innerProps={$imageProps} />
  }

  return (
    <AppBox {...$rootProps}>
      <AppBox {...$imageProps} />
    </AppBox>
  )
})

const styles = {
  root: {
    display: 'flex',
    backgroundSize: 'cover !important',
  },
  image: ({ imageSize }: Partial<ImageProps>) => ({
    lineHeight: 0,
    overflow: 'hidden',
    objectFit: 'cover',
    width: imageSize === 'unset' ? 'unset' : MuiSystemProp.fullSize,
    height: imageSize === 'unset' ? 'unset' : MuiSystemProp.fullSize,
  }),
}
