import { AppExternalLink, AppExternalLinkProps } from './AppExternalLink'
import { AppInternalLink, AppInternalLinkProps } from './AppInternalLink'
import { checkIsExternalLink, LinkType } from '../../../../../model/Link'
import { NotOverridableComponentProps } from '../../../../../model/Component'

type LinkPropsUnion = NotOverridableComponentProps<(AppInternalLinkProps | AppExternalLinkProps)>

export type NewTabLinkProps = Omit<LinkPropsUnion, 'href' | 'to'> & {
  link: LinkType
}

export function NewTabLink(props: NewTabLinkProps) {
  const { link, ...rest } = props

  const baseProps = {
    target: '_blank',
    rel: 'noopener noreferrer',
    ...rest,
  }

  if (checkIsExternalLink(link)) {
    return (
      <AppExternalLink
        {...baseProps}
        href={link}
      />
    )
  }

  return (
    <AppInternalLink
      {...baseProps as AppInternalLinkProps}
      to={link}
    />
  )
}
